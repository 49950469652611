import React, { useState } from "react";
import useStyles from "./styles";
import { Box, Select, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CeroDropdown from "../../../../components/CeroDropdown";
import RemoveIcon from "@mui/icons-material/Remove";

const LawRegulationTable = ({ lawRegulationTable, setLawRegulationTable }) => {
  const classes = useStyles();
  const handleInputChange = (categoryIndex, field, value) => {
    const updatedData = [...lawRegulationTable];
    updatedData[categoryIndex][field] = value;
    setLawRegulationTable(updatedData);
  };
  const [canAddMore, setCanAddmore] = useState(true);
  const [canRemove, setCanRemove] = useState(lawRegulationTable.length > 1);

  const handleAddMore = () => {
    setLawRegulationTable((prev) => [
      ...prev, // Spread the existing array to retain its elements
      {
        instance: "",
        reportingPeriod: "",
        instanceType: "",
        lawLocationType: "",
        frequency: "",
        severity: "",
        finePaid: "",
      }, // Add the new object to the array
    ]);
    setCanRemove(true);
  };
  const handleRemove = () => {
    setLawRegulationTable((prev) => {
      // Use slice to create a new array excluding the last element
      setCanRemove(prev.length > 2);
      return prev.slice(0, -1);
    });
  };
  return (
    <div style={{ padding: "20px" }}>
      <div
        style={{
          borderRadius: "6px",
          overflow: "hidden",
          // border: "1px solid #ddd",
        }}
      >
        <table border="2" style={{ borderCollapse: "collapse", width: "100%" }}>
          <thead>
            <tr style={{ backgroundColor: "#d0c5ca", textAlign: "center" }}>
              <th style={{ padding: "8px", width: "30px" }}>Sl. No.</th>
              <th style={{ padding: "8px" }}>Instance</th>
              <th style={{ padding: "8px" }}>
                Reporting period (Current/Previous)
              </th>
              <th style={{ padding: "8px" }}>Type of Instance</th>
              <th style={{ padding: "8px" }}>Geographic location</th>
              <th style={{ padding: "8px" }}>Frequency</th>
              <th style={{ padding: "8px" }}>Severity</th>
              <th style={{ padding: "8px" }}>Fine paid</th>
            </tr>
          </thead>
          <tbody>
            {lawRegulationTable?.map((item, categoryIndex) => (
              <React.Fragment key={categoryIndex}>
                <tr key={`${categoryIndex}`}>
                  {/* Only render the category cell for the first row of each category */}
                  <td className={classes.marketDataRow}>
                    <Typography>{categoryIndex + 1}</Typography>
                  </td>
                  <td className={classes.marketDataRow}>
                    <textarea
                      value={item.instance}
                      placeholder="Enter Instance"
                      onChange={(e) =>
                        handleInputChange(
                          categoryIndex,
                          "instance",
                          e.target.value
                        )
                      }
                      className={classes.marketDataItem}
                    />
                  </td>
                  <td className={classes.marketDataRow}>
                    <textarea
                      value={item.reportingPeriod}
                      placeholder="Enter Reporting Period"
                      onChange={(e) =>
                        handleInputChange(
                          categoryIndex,
                          "reportingPeriod",
                          e.target.value
                        )
                      }
                      className={classes.marketDataItem}
                    />
                  </td>
                  <td className={classes.marketDataRow}>
                    <textarea
                      value={item.instanceType}
                      placeholder="Enter Instance Type"
                      onChange={(e) =>
                        handleInputChange(
                          categoryIndex,
                          "instanceType",
                          e.target.value
                        )
                      }
                      className={classes.marketDataItem}
                    />
                  </td>
                  <td className={classes.marketDataRow}>
                    <textarea
                      value={item.lawLocationType}
                      placeholder="Enter Geographic location"
                      onChange={(e) =>
                        handleInputChange(
                          categoryIndex,
                          "lawLocationType",
                          e.target.value
                        )
                      }
                      className={classes.marketDataItem}
                    />
                  </td>
                  <td className={classes.marketDataRow}>
                    <CeroDropdown
                      value={item.frequency}
                      placeholder="Select Frequency"
                      selectedValue={item.frequency}
                      options={[
                        { key: "Once", value: "Once" },
                        { key: "Repeated", value: "Repeated" },
                        { key: "Recurring", value: "Recurring" },
                      ]}
                      onChange={(e) =>
                        handleInputChange(
                          categoryIndex,
                          "frequency",
                          e.target.value
                        )
                      }
                      className={classes.marketDataItem}
                    />
                  </td>
                  <td className={classes.marketDataRow}>
                    <CeroDropdown
                      value={item.severity}
                      placeholder="Select Severity"
                      selectedValue={item.severity}
                      options={[
                        { key: "High", value: "High" },
                        { key: "Medium", value: "Medium" },
                        { key: "Low", value: "Low" },
                      ]}
                      onChange={(e) =>
                        handleInputChange(
                          categoryIndex,
                          "severity",
                          e.target.value
                        )
                      }
                      className={classes.marketDataItem}
                    />
                  </td>
                  <td className={classes.marketDataRow}>
                    <textarea
                      value={item.finePaid}
                      placeholder="Enter Fine Paid"
                      onChange={(e) =>
                        handleInputChange(
                          categoryIndex,
                          "finePaid",
                          e.target.value
                        )
                      }
                      className={classes.marketDataItem}
                    />
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
        <div className={classes.addMoreDiv}>
          <Box
            display="flex"
            paddingY={2}
            paddingLeft={2}
            gap={1}
            onClick={canRemove ? handleRemove : null}
            style={{
              cursor: canRemove ? "pointer" : "not-allowed",
              color: canRemove ? "gray" : "gray",
              border: "1px solid #ddd",
              padding: "2px 7px",
              margin: "5px 5px",
              borderRadius: "5px",
            }}
          >
            <RemoveIcon />
            <Typography>Remove</Typography>
          </Box>
          <Box
            display="flex"
            paddingY={2}
            paddingLeft={2}
            gap={1}
            onClick={canAddMore ? handleAddMore : null}
            style={{
              cursor: canAddMore ? "pointer" : "not-allowed",
              color: canAddMore ? "gray" : "gray",
              border: "1px solid #ddd",
              padding: "2px 7px",
              margin: "5px 5px",
              borderRadius: "5px",
            }}
          >
            <AddIcon />
            <Typography>Add More</Typography>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default LawRegulationTable;
