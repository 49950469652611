import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Typography,
  Box,
  CircularProgress,
  Grid,
} from "@mui/material";

import {
    getMaterialityIndustries,
  getOffsetCountries,
  getProgramTypes,
  getSupplierSurveyResults,
  getSustainabilityRiskSurveyResults,
} from "../../../redux/actions";
import { STATUS } from "../../../redux/constants";

import useStyles from "./styles";
import { getCookie } from "../../../services/cookie";
import CeroAutoComplete from "../../../components/CeroAutoComplete";
import CeroSelect from "../../../components/CeroSelect";
import CarbonOffsetTable from "../../CarbonOffset/CarbonOffsetTable";
import DashboardLayout from "../../../layouts/DashboardLayout";
import { sampleYear } from "../../../constants";
import CeroTable from "../../../components/CeroTable";
import CeroCheckboxTable from "../../../components/CeroCheckboxTable";
import SurveyResultTable from "./resultTable";
import { Assessment } from "@mui/icons-material";

const SustainabilityRiskAssessmentSurveyResult = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const role = getCookie("role");
  const readOnlyMode = getCookie("read_only_mode") === "disable_edit";

  const [selectedYear, setSelectedYear] = useState(0);
  const [sortDataAscending,setSortDataAscending] = useState(false);
  const [sortDataDescending,setSortDataDescending] = useState(false);
  

  const surveyResults = useSelector((state) => state.sustainabilityRiskAssessment.getSustainabilityRiskAssessementSurveyResults.data) || [];
  const surveyResultsStatus = useSelector((state) => state.sustainabilityRiskAssessment.getSustainabilityRiskAssessementSurveyResults.status) || [];

  const surveyResultColumns = [
    {
      columnKey: "",
      columnId: "",
      columnHeader: "",
      width: "5vw",
    },
    {
      columnKey: "name",
      columnId: "name",
      columnHeader: "Name",
      width: "25vw",
    },
    {
      columnKey: "email",
      columnId: "email",
      columnHeader: "Email",
      width: "25vw",
    },
      {
        columnKey: "survey_date",
        columnId: "survey_date",
        columnHeader: "date",
        width: "25vw",
      },
      {
        columnKey: "total_score",
        columnId: "total_score",
        columnHeader: "Score",
        width: "25vw",
        classes:{column:classes.scorecolumn}
      },
      {
        columnKey: "assessment",
        columnId: "assessment",
        columnHeader: "Assessment Rating",
        width: "25vw",
      },
      
      
  ]

  const updatedData = () => {
    if (!surveyResults) return [];
  
    const tempData = surveyResults.map((item) => ({
      ...item,
      assessment: (() => {
        if (item.total_score >= 90 && item.total_score <= 100) {
          return <Typography className={classes.excellent}>Excellent</Typography>;
        }
        else if (item.total_score >= 75 && item.total_score < 90) {
          return <Typography className={classes.good}>Very Good</Typography>;
        }
        else if (item.total_score >= 60 && item.total_score < 75) {
          return <Typography className={classes.moderate}>Good</Typography>;
        }
        else if (item.total_score >= 40 && item.total_score < 60) {
          return <Typography className={classes.moderate}>Average</Typography>;
        }
        return <Typography className={classes.low}>Poor</Typography>;
      })(),
    }));
  
    // Sorting logic
    if (sortDataAscending) {
      return tempData.sort((a, b) => a.industry_name.localeCompare(b.industry_name));
    }
    if (sortDataDescending) {
      return tempData.sort((a, b) => b.industry_name.localeCompare(a.industry_name));
    }
  
    return tempData; // Return the unsorted data if no sorting is applied
  };
  
  
  const yearList = sampleYear().map((item) => ({
    id: item.key,
    label: item.value,
  }));

    useEffect(() => {
        dispatch(getMaterialityIndustries());
      }, [dispatch]);
      
      useEffect(() => {
        dispatch(getSustainabilityRiskSurveyResults(0)); 
    }, [dispatch]);
    
    
    useEffect(() => {
        if (selectedYear) {
            dispatch(getSustainabilityRiskSurveyResults(selectedYear)); 
        }
    }, [dispatch, selectedYear]);
      
  const programTypes = useSelector(
    (state) => state.carbonOffset.getProgramTypes.data
  );
  const programTypeOptions = programTypes.map((item) => ({
    key: item.id,
    value: item.name,
  }));

  const onSelectAuditSummaryData = (row) => {
    navigate(`details/${row.id}`);
  };


  useEffect(() => {
    dispatch(getOffsetCountries())
    dispatch(getProgramTypes());
  }, [dispatch, selectedYear]);

  return (
    <DashboardLayout>
      <Container className={classes.container}>
        <div className={classes.topContainer}>
          <Grid spacing={2} className={classes.filterContainer}>
            <Grid item xs={4} style={{ width: "90%"}}>
          <CeroAutoComplete
              classes={{ container: classes.autoComplete,  root: classes.textField }}
              id="year"
              label="Year"
              fullWidth
              selectedValue={selectedYear}
              onChange={(e, value) => setSelectedYear(value ? value.id : undefined)}              
              options={yearList}
            />
            </Grid>
          </Grid>
        </div>
        {surveyResultsStatus === STATUS.SUCCESS ? (
          <Container className={classes.tableContainer}>
            {/* <CeroTable
                columns={surveyResultColumns}
                data={surveyResults}
                hasMore={false}
                loading={false}
                classes={{tableContainer : classes.tableContainer}}
                /> */}
            <SurveyResultTable
                  columns={surveyResultColumns || []}
                  data={updatedData()}
                  hasMore={false}
                  loading={false}
                  loadMore={false}
                  classes={{ tableContainer: classes.tableContainer }}
                  showSelectAllCheckbox={false}
                  isSelectable
                  showDropdown={true}
                  checkedRows={[]}
                  onCheckedRowsChange={()=> console.log("hello")
                  }
                  showIcon={
                    !false
                  }
                  showFilterIcon={true}
                  onFilterSelect={()=> console.log("hello")}
                  setSortDataAscending={setSortDataAscending}
                  setSortDataDescending={setSortDataDescending}
                  sortDataAscending={sortDataAscending}
                  sortDataDescending={sortDataDescending}
                  showSort={true}
                />
          </Container>
        ) : (
          <Box className={classes.loader}>
            <Typography variant="h7" component="span">
              {surveyResultsStatus === STATUS.RUNNING ? (
                <CircularProgress />
              ) : surveyResultsStatus === STATUS.ERROR ? (
                "We couldn't process your request. Please try again later"
              ) : (
                ""
              )}
            </Typography>
          </Box>
        )}
      </Container>
    </DashboardLayout>
  );
};

export default SustainabilityRiskAssessmentSurveyResult;
