import { Box, Container, Grid, Modal } from "@mui/material";
import React, { useState } from "react";
import useStyles from "../styles";
import CeroItemPair from "../../../components/CeroItemPair";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import { getCookie } from "../../../services/cookie";
import CeroButton from "../../../components/CeroButton";
import CeroInfoPair from "../../../components/CeroInfoPair";
import CloseIcon from "@mui/icons-material/Close";
import Download from "../../../assets/images/Download";

const LocalCommunitiesApproval = (props) => {
  const classes = useStyles();
  const { emissionData, handleCallback, handleReviewCallback } = props;
  const role = getCookie("role");
  const readOnlyMode = getCookie("read_only_mode") === "disable_edit";
  const showActionButtons =
    window.location.pathname.includes("/pending-approval-details") &&
    (role === "sustainability_manager" ||
      role === "proxy_sustainability_manager");
  const showVerifyButtons =
    window.location.pathname.includes("/companies") && role === "auditor";
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleIconClick = (itemId) => {
    handleCallback(itemId);
  };

  const handleReviewClick = (itemId) => {
    handleReviewCallback(itemId);
  };
  const handlePdfOpen = () => {
    setIsModalOpen(true);
  };

  const handlePdfClose = () => {
    setIsModalOpen(false);
  };
  const handlePdfPopup = () => {
    return (
      <embed
        src={`${emissionData.blob_url}#toolbar=0`}
        type="application/pdf"
        width="100%"
        height="600px"
      />
    );
  };

  return (
    <Container className={classes.mainContainer}>
      {emissionData &&
        Array.isArray(emissionData) &&
        emissionData?.map((item, index) => (
          <Container key={index} className={classes.container}>
            {showVerifyButtons && (
              <Box className={classes.trainingAndProgramAction}>
                <CeroInfoPair
                  title="Status:"
                  value={
                    item.status === "pending_approval"
                      ? "Pending Approval"
                      : item.status
                      ? item.status
                      : "NA"
                  }
                  classes={{
                    container: classes.statusContainer,
                    title: classes.statusTitle,
                    value:
                      item.status === "In Progress" ||
                      item.status === "pending_approval"
                        ? classes.Inprogress
                        : classes[item?.status],
                  }}
                />
                {showVerifyButtons &&
                  emissionData.length > 1 &&
                  !readOnlyMode && (
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        {item.status === "approved" && (
                          <div
                            className={classes.actionButtons}
                            onClick={() => handleIconClick(item.id)}
                          >
                            <CheckCircleOutlineOutlinedIcon
                              className={classes.icon}
                            />
                            <CeroButton
                              variant="contained"
                              buttonText="Validate"
                              className={classes.approveButton}
                            />
                          </div>
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        {item.status === "approved" && (
                          <div
                            className={classes.actionButtons}
                            onClick={() => handleReviewClick(item.id)}
                          >
                            <ArrowCircleLeftOutlinedIcon
                              className={classes.icon}
                            />
                            <CeroButton
                              variant="contained"
                              buttonText="Review"
                              className={classes.approveButton}
                            />
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  )}
              </Box>
            )}
            <CeroItemPair
              title="Name of the Local Community:"
              value={item.local_community ? item.local_community : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.supplierHumanTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Total Contributions:"
              value={item.amount ? item.amount : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.supplierHumanTitle,
                value: classes.value,
              }}
            />
            {emissionData.blob_url && (
              <div
                style={{
                  transform: "scale(0.6)",
                  transformOrigin: "center",
                  position: "absolute",
                  top: 100,
                  right: 100,
                }}
                onClick={handlePdfOpen}
              >
                <Download />
              </div>
            )}
            <CeroItemPair
              title="Projects Undertaken:"
              value={item.project_undertaken ? item.project_undertaken : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.supplierHumanTitle,
                value:
                  item.status === "approved"
                    ? classes.valueMaxWidth
                    : classes.value,
              }}
            />
            <CeroItemPair
              title="Objective of the Project:"
              value={item.objective ? item.objective : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.supplierHumanTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Activities Executed:"
              value={item.activities_executed ? item.activities_executed : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.supplierHumanTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Results of the Projects:"
              value={item.result_achieved ? item.result_achieved : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.supplierHumanTitle,
                value: classes.value,
              }}
            />
          </Container>
        ))}
      <Modal open={isModalOpen} onClose={handlePdfClose}>
        <Box className={classes.modalContent}>
          <CloseIcon onClick={handlePdfClose} className={classes.closeButton} />
          {handlePdfPopup()}
        </Box>
      </Modal>
    </Container>
  );
};

export default LocalCommunitiesApproval;
