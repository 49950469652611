import React, { useState } from "react";
import useStyles from "./styles";
import { Box, Select, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CeroDropdown from "../../../../components/CeroDropdown";
import RemoveIcon from "@mui/icons-material/Remove";

const DownstreamTable = ({ downstreamTable, setDownstreamTable }) => {
  const classes = useStyles();
  const handleInputChange = (categoryIndex, field, value) => {
    const updatedData = [...downstreamTable];
    updatedData[categoryIndex][field] = value;
    setDownstreamTable(updatedData);
  };
  const [canAddMore, setCanAddmore] = useState(true);
  const [canRemove, setCanRemove] = useState(downstreamTable.length > 1);

  const handleAddMore = () => {
    setDownstreamTable((prev) => [
      ...prev, // Spread the existing array to retain its elements
      {
        downstreamName: "",
        downstreamType: "",
        downstreamActivityType: "",
        downstreamBusinessType: "",
        downstreamLocationType: "",
      }, // Add the new object to the array
    ]);
    setCanRemove(true);
  };
  const handleRemove = () => {
    setDownstreamTable((prev) => {
      // Use slice to create a new array excluding the last element
      setCanRemove(prev.length > 2);
      return prev.slice(0, -1);
    });
  };
  return (
    <div style={{ padding: "20px" }}>
      <div
        style={{
          borderRadius: "6px",
          overflow: "hidden",
          // border: "1px solid #ddd",
        }}
      >
        <table border="2" style={{ borderCollapse: "collapse", width: "100%" }}>
          <thead>
            <tr style={{ backgroundColor: "#d0c5ca", textAlign: "center" }}>
              <th style={{ padding: "8px" }}>Name of the downstream entity </th>
              <th style={{ padding: "8px" }}>Type of the downstream entity</th>
              <th style={{ padding: "8px" }}>Type of activities</th>
              <th style={{ padding: "8px" }}>
                Nature of business relationship
              </th>
              <th style={{ padding: "8px" }}>
                Geographic location (Region, Country)
              </th>
            </tr>
          </thead>
          <tbody>
            {downstreamTable?.map((item, categoryIndex) => (
              <React.Fragment key={categoryIndex}>
                <tr key={`${categoryIndex}`}>
                  {/* Only render the category cell for the first row of each category */}
                  <td className={classes.marketDataRow}>
                    <textarea
                      value={item.downstreamName}
                      placeholder="Enter Entity Name"
                      onChange={(e) =>
                        handleInputChange(
                          categoryIndex,
                          "downstreamName",
                          e.target.value
                        )
                      }
                      className={classes.marketDataItem}
                    />
                  </td>
                  <td className={classes.marketDataRow}>
                    <CeroDropdown
                      value={item.downstreamType}
                      selectedValue={item.downstreamType}
                      placeholder="Slect Entity Type"
                      options={[
                        { key: "Customers", value: "Customers" },
                        { key: "Beneficiaries", value: "Beneficiaries" },
                        { key: "Other", value: "Other" },
                      ]}
                      onChange={(e) =>
                        handleInputChange(
                          categoryIndex,
                          "downstreamType",
                          e.target.value
                        )
                      }
                      className={classes.marketDataItem}
                    />
                  </td>
                  <td className={classes.marketDataRow}>
                    <CeroDropdown
                      value={item.downstreamActivityType}
                      placeholder="Slect Activity Type"
                      selectedValue={item.downstreamActivityType}
                      options={[
                        { key: "Manufacturing", value: "Manufacturing" },
                        { key: "Wholesale", value: "Wholesale" },
                        { key: "Retail", value: "Retail" },
                        { key: "Other", value: "Other" },
                      ]}
                      onChange={(e) =>
                        handleInputChange(
                          categoryIndex,
                          "downstreamActivityType",
                          e.target.value
                        )
                      }
                      className={classes.marketDataItem}
                    />
                  </td>
                  <td className={classes.marketDataRow}>
                    <CeroDropdown
                      value={item.downstreamBusinessType}
                      placeholder="Slect Nature of business relationship"
                      selectedValue={item.downstreamBusinessType}
                      options={[
                        { key: "Long-term ", value: "Long-term " },
                        { key: "Short-term", value: "Short-term" },
                        { key: "Contractual", value: "Contractual" },
                        { key: "Non-contractual", value: "Non-contractual" },
                        { key: "Project-based", value: "Project-based" },
                        { key: "Event-based", value: "Event-based" },
                      ]}
                      onChange={(e) =>
                        handleInputChange(
                          categoryIndex,
                          "downstreamBusinessType",
                          e.target.value
                        )
                      }
                      className={classes.marketDataItem}
                    />
                  </td>
                  <td className={classes.marketDataRow}>
                    <textarea
                      value={item.downstreamLocationType}
                      placeholder="Enter Geographic location"
                      onChange={(e) =>
                        handleInputChange(
                          categoryIndex,
                          "downstreamLocationType",
                          e.target.value
                        )
                      }
                      className={classes.marketDataItem}
                    />
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
        <div className={classes.addMoreDiv}>
          <Box
            display="flex"
            paddingY={2}
            paddingLeft={2}
            gap={1}
            onClick={canRemove ? handleRemove : null}
            style={{
              cursor: canRemove ? "pointer" : "not-allowed",
              color: canRemove ? "gray" : "gray",
              border: "1px solid #ddd",
              padding: "2px 7px",
              margin: "5px 5px",
              borderRadius: "5px",
            }}
          >
            <RemoveIcon />
            <Typography>Remove</Typography>
          </Box>
          <Box
            display="flex"
            paddingY={2}
            paddingLeft={2}
            gap={1}
            onClick={canAddMore ? handleAddMore : null}
            style={{
              cursor: canAddMore ? "pointer" : "not-allowed",
              color: canAddMore ? "gray" : "gray",
              border: "1px solid #ddd",
              padding: "2px 7px",
              margin: "5px 5px",
              borderRadius: "5px",
            }}
          >
            <AddIcon />
            <Typography>Add More</Typography>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default DownstreamTable;
