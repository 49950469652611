import { Typography, Box, Container, Grid, Modal } from "@mui/material";
import React, { useState } from "react";
import useStyles from "../styles";
import CeroItemPair from "../../../components/CeroItemPair";
import { getCookie } from "../../../services/cookie";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import CeroInfoPair from "../../../components/CeroInfoPair";
import CeroButton from "../../../components/CeroButton";
import Download from "../../../assets/images/Download";
import CloseIcon from "@mui/icons-material/Close";

const EmployeeBenefitsApproval = (props) => {
  const classes = useStyles();
  const { emissionData, handleCallback, handleReviewCallback } = props;
  const dataArray = emissionData ? Object.values(emissionData) : [];
  const readOnlyMode = getCookie("read_only_mode") === "disable_edit";

  const role = getCookie("role");
  const showActionButtons =
    window.location.pathname.includes("/pending-approval-details") &&
    (role === "sustainability_manager" ||
      role === "proxy_sustainability_manager");
  const showVerifyButtons =
    window.location.pathname.includes("/companies") && role === "auditor";
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleIconClick = (itemId) => {
    handleCallback(itemId);
  };

  const handleReviewClick = (itemId) => {
    handleReviewCallback(itemId);
  };

  const handlePdfOpen = () => {
    setIsModalOpen(true);
  };

  const handlePdfClose = () => {
    setIsModalOpen(false);
  };
  const handlePdfPopup = () => {
    return (
      <embed
        src={`${emissionData.blob_url}#toolbar=0`}
        type="application/pdf"
        width="100%"
        height="600px"
      />
    );
  };

  return (
    <Container className={classes.mainContainer}>
      {dataArray?.map((item, index) => (
        <Container key={index} className={classes.container}>
          <Box className={classes.actionBox}>
            <CeroInfoPair
              title="Status:"
              value={
                item.status === "pending_approval"
                  ? "Pending Approval"
                  : item.status
                  ? item.status
                  : "NA"
              }
              classes={{
                container: classes.statusContainer,
                title: classes.statusTitle,
                value:
                  item.status === "In Progress" ||
                  item.status === "pending_approval"
                    ? classes.Inprogress
                    : classes[item?.status],
              }}
            />
            {showActionButtons && !readOnlyMode && dataArray.length > 1 && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  {(item.status === "pending_approval" ||
                    item.status === "submitted") && (
                    <div
                      className={classes.actionButtons}
                      onClick={() => handleIconClick(item.id)}
                    >
                      <CheckCircleOutlineOutlinedIcon
                        className={classes.icon}
                      />
                      <CeroButton
                        variant="contained"
                        buttonText="Approve"
                        className={classes.approveButton}
                      />
                    </div>
                  )}
                </Grid>
                <Grid item xs={6}>
                  {item.is_added_by_sustainability_manager === false &&
                    item.status === "pending_approval" && (
                      <div
                        className={classes.actionButtons}
                        onClick={() => handleReviewClick(item.id)}
                      >
                        <ArrowCircleLeftOutlinedIcon className={classes.icon} />
                        <CeroButton
                          variant="contained"
                          buttonText="Review"
                          className={classes.approveButton}
                        />
                      </div>
                    )}
                </Grid>
              </Grid>
            )}
            {showVerifyButtons && emissionData.length > 1 && !readOnlyMode && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  {item.status === "approved" && (
                    <div
                      className={classes.actionButtons}
                      onClick={() => handleIconClick(item.id)}
                    >
                      <CheckCircleOutlineOutlinedIcon
                        className={classes.icon}
                      />
                      <CeroButton
                        variant="contained"
                        buttonText="Validate"
                        className={classes.approveButton}
                      />
                    </div>
                  )}
                </Grid>
                <Grid item xs={6}>
                  {item.status === "approved" && (
                    <div
                      className={classes.actionButtons}
                      onClick={() => handleReviewClick(item.id)}
                    >
                      <ArrowCircleLeftOutlinedIcon className={classes.icon} />
                      <CeroButton
                        variant="contained"
                        buttonText="Review"
                        className={classes.approveButton}
                      />
                    </div>
                  )}
                </Grid>
              </Grid>
            )}
          </Box>
          {item?.employees_classification === "Permanent" ? (
            <Typography variant="h6" className={classes.heading}>
              Employee Benefits for Permanent Employees
            </Typography>
          ) : (
            <Typography variant="h6" className={classes.heading}>
              Employee Benefits for Temporary Employees
            </Typography>
          )}
          <CeroItemPair
            title="Number of Employees:"
            value={item.number_of_employees ? item.number_of_employees : "NA"}
            classes={{
              container: classes.infoContainer,
              title: classes.title,
              value: classes.value,
            }}
          />
          {emissionData.blob_url && (
            <div
              style={{
                transform: "scale(0.6)",
                transformOrigin: "center",
                position: "absolute",
                top: 100,
                right: 100,
              }}
              onClick={handlePdfOpen}
            >
              <Download />
            </div>
          )}
          <CeroItemPair
            title="Benefits Provided:"
            value={
              item.benefit_provided_list
                ? item.benefit_provided_list?.map((benefit, index) => (
                    <li className={classes.list} key={index}>
                      {index + 1}. {benefit}
                    </li>
                  ))
                : "NA"
            }
            classes={{
              container: classes.infoContainer,
              title: classes.title,
              value: classes.value,
            }}
          />
        </Container>
      ))}
      <Modal open={isModalOpen} onClose={handlePdfClose}>
        <Box className={classes.modalContent}>
          <CloseIcon onClick={handlePdfClose} className={classes.closeButton} />
          {handlePdfPopup()}
        </Box>
      </Modal>
    </Container>
  );
};

export default EmployeeBenefitsApproval;
