import { Box, Container, Grid, Modal } from "@mui/material";
import React, { useState } from "react";
import useStyles from "../styles";
import CeroItemPair from "../../../components/CeroItemPair";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import { getCookie } from "../../../services/cookie";
import CeroButton from "../../../components/CeroButton";
import CeroInfoPair from "../../../components/CeroInfoPair";
import Download from "../../../assets/images/Download";
import CloseIcon from "@mui/icons-material/Close";

const SupplierScreeningApproval = (props) => {
  const classes = useStyles();
  const { emissionData, handleCallback, handleReviewCallback } = props;
  const role = getCookie("role");
  const readOnlyMode = getCookie("read_only_mode") === "disable_edit";
  const showActionButtons =
    window.location.pathname.includes("/pending-approval-details") &&
    (role === "sustainability_manager" ||
      role === "proxy_sustainability_manager");
  const showVerifyButtons =
    window.location.pathname.includes("/companies") && role === "auditor";
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleIconClick = (itemId) => {
    handleCallback(itemId);
  };

  const handleReviewClick = (itemId) => {
    handleReviewCallback(itemId);
  };

  const handlePdfOpen = () => {
    setIsModalOpen(true);
  };

  const handlePdfClose = () => {
    setIsModalOpen(false);
  };
  const handlePdfPopup = () => {
    return (
      <embed
        src={`${emissionData.blob_url}#toolbar=0`}
        type="application/pdf"
        width="100%"
        height="600px"
      />
    );
  };

  return (
    <Container className={classes.mainContainer}>
      {emissionData &&
        Array.isArray(emissionData) &&
        emissionData?.map((item, index) => (
          <Container key={index} className={classes.container}>
            {showVerifyButtons && (
              <Box className={classes.trainingAndProgramAction}>
                <CeroInfoPair
                  title="Status:"
                  value={
                    item.status === "pending_approval"
                      ? "Pending Approval"
                      : item.status
                      ? item.status
                      : "NA"
                  }
                  classes={{
                    container: classes.statusContainer,
                    title: classes.statusTitle,
                    value:
                      item.status === "In Progress" ||
                      item.status === "pending_approval"
                        ? classes.Inprogress
                        : classes[item?.status],
                  }}
                />
                {showVerifyButtons &&
                  emissionData.length > 1 &&
                  !readOnlyMode && (
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        {item.status === "approved" && (
                          <div
                            className={classes.actionButtons}
                            onClick={() => handleIconClick(item.id)}
                          >
                            <CheckCircleOutlineOutlinedIcon
                              className={classes.icon}
                            />
                            <CeroButton
                              variant="contained"
                              buttonText="Validate"
                              className={classes.approveButton}
                            />
                          </div>
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        {item.status === "approved" && (
                          <div
                            className={classes.actionButtons}
                            onClick={() => handleReviewClick(item.id)}
                          >
                            <ArrowCircleLeftOutlinedIcon
                              className={classes.icon}
                            />
                            <CeroButton
                              variant="contained"
                              buttonText="Review"
                              className={classes.approveButton}
                            />
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  )}
              </Box>
            )}
            <CeroItemPair
              title="Number of suppliers screened using Environmental Criteria:"
              value={
                item.suppliers_screened_using_env_criteria
                  ? item.suppliers_screened_using_env_criteria
                  : "NA"
              }
              classes={{
                container: classes.infoContainer,
                title: classes.supplierScreenTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Environmental Criteria:"
              value={
                item.environment_criteria_list
                  ? item.environment_criteria_list?.map((criteria, index) => (
                      <li className={classes.list} key={index}>
                        {index + 1}. {criteria}
                      </li>
                    ))
                  : "NA"
              }
              classes={{
                container: classes.infoContainer,
                title: classes.supplierScreenTitle,
                value: classes.value,
              }}
            />
            {item.environment_criteria_list &&
              item.environment_criteria_list.includes("Others") && (
                <CeroItemPair
                  title="Environmental Criteria Details:"
                  value={
                    item.enviormental_criteria_details
                      ? item.enviormental_criteria_details
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.supplierScreenTitle,
                    value: classes.value,
                  }}
                />
              )}
            {emissionData.blob_url && (
              <div
                style={{
                  transform: "scale(0.6)",
                  transformOrigin: "center",
                  position: "absolute",
                  top: 100,
                  right: 100,
                }}
                onClick={handlePdfOpen}
              >
                <Download />
              </div>
            )}
            <CeroItemPair
              title="Describe Negative Environmental Impacts:"
              value={item.negative_env_impact ? item.negative_env_impact : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.supplierScreenTitle,
                value:
                  item.status === "approved"
                    ? classes.valueMaxWidth
                    : classes.value,
              }}
            />
            <CeroItemPair
              title="Actions taken to Address Negative Impacts:"
              value={
                item.action_taken_address_env_criteria
                  ? item.action_taken_address_env_criteria
                  : "NA"
              }
              classes={{
                container: classes.infoContainer,
                title: classes.supplierScreenTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Number of suppliers screened using Social Criteria:"
              value={
                item.suppliers_screened_using_social_criteria
                  ? item.suppliers_screened_using_social_criteria
                  : "NA"
              }
              classes={{
                container: classes.infoContainer,
                title: classes.supplierScreenTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Social Criteria:"
              value={
                item.social_criteria_list
                  ? item.social_criteria_list?.map((criteria, index) => (
                      <li className={classes.list} key={index}>
                        {index + 1}. {criteria}
                      </li>
                    ))
                  : "NA"
              }
              classes={{
                container: classes.infoContainer,
                title: classes.supplierScreenTitle,
                value: classes.value,
              }}
            />
            {item.social_criteria_list &&
              item.social_criteria_list.includes("Others") && (
                <CeroItemPair
                  title="Social Criteria Details:"
                  value={
                    item.social_criteria_details
                      ? item.social_criteria_details
                      : "NA"
                  }
                  classes={{
                    container: classes.infoContainer,
                    title: classes.supplierScreenTitle,
                    value: classes.value,
                  }}
                />
              )}
            <CeroItemPair
              title="Describe Negative Social Impacts:"
              value={
                item.negative_social_impact ? item.negative_social_impact : "NA"
              }
              classes={{
                container: classes.infoContainer,
                title: classes.supplierScreenTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Actions taken to Address Negative Impacts:"
              value={
                item.action_taken_address_social_criteria
                  ? item.action_taken_address_social_criteria
                  : "NA"
              }
              classes={{
                container: classes.infoContainer,
                title: classes.supplierScreenTitle,
                value: classes.value,
              }}
            />
          </Container>
        ))}
      <Modal open={isModalOpen} onClose={handlePdfClose}>
        <Box className={classes.modalContent}>
          <CloseIcon onClick={handlePdfClose} className={classes.closeButton} />
          {handlePdfPopup()}
        </Box>
      </Modal>
    </Container>
  );
};

export default SupplierScreeningApproval;
