// @ts-nocheck
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  assetQuestions: {
    width: "40%",
    padding: "5px",
  },
  assetValues: {
    width: "100%",
    height: "100%",
    borderRadius: "0px",
    border: "0px solid grey",
    resize: "none",
    outline: "none",
    alignItems: "center",
    padding: "12px 0 12px 12px",
    verticalAlign: "middle",
  },
  marketDataRow: {
    padding: "8px",
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
    // border: "none",
    textAlign: "center",
  },
  marketDataItem: {
    width: "60%",
    height: "40px",
    borderRadius: "5px",
    border: "1px solid grey",
    resize: "none",
  },
  addMoreDiv: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default useStyles;
