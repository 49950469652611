import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout";
import { Box, CircularProgress, Grid, Tooltip, Typography } from "@mui/material";
import CeroButton from "../../../components/CeroButton";
import useStyles from "./styles";
import Questions from "./Questions";
import CeroPopUp from "../../../components/CeroPopUp";
import SendQuestions from "./SendQuestions";
import { useDispatch, useSelector } from "react-redux";
import {
  getMaterialityIndustries,
  getMaterialityQuestions,
  getSurvey,
  resetCreateQuestionsStatus,
  setIndustryType,
} from "../../../redux/actions";
import { STATUS } from "../../../redux/constants";
import { useSnackbar } from "notistack";
import InfoIcon from '@mui/icons-material/Info';
import CeroAutoComplete from "../../../components/CeroAutoComplete";

const Survey = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [openQuestionsForm, setOpenQuestionsForm] = useState(false);
  const [industry, setIndustry] = useState("");

  const industryData =
    useSelector((state) => state.materiality.getMaterialityIndustries.data) ||
    [];

  const createQuestionStatus = useSelector(
    (state) => state.materiality.createMaterialityQuestions.status
  );
  const createQuestionMessage = useSelector(
    (state) => state.materiality.createMaterialityQuestions.message
  );
  const surveyData = useSelector(
    (state) => state.materiality.getSurvey.data
  );
  const sendQuestionnairesStatus = useSelector(
    (state) => state.materiality.sendQuestionnaires.status
  );
  const sendQuestionnairesMessage = useSelector(
    (state) => state.materiality.sendQuestionnaires.message
  );
  const loadSurveyDetails = useSelector(
    (state) => state.materiality.getSurvey.status
  );
  const industryTypeSelected = useSelector(
    (state) => state.materiality.setIndustryType.status
  );

  const industryList = Array.isArray(industryData)
    ? industryData.map((item) => ({
        id: item.id,
        label: item.industry_type,
      }))
    : [];

  const handleConfirm = () => {
    setConfirmPopup(false)
    dispatch(setIndustryType(industry));
  };

  useEffect(() => {
    dispatch(getMaterialityIndustries());
    dispatch(getSurvey());
  }, [dispatch]);

  useEffect(() => {
    if (industryTypeSelected === STATUS.SUCCESS) {
      dispatch(getMaterialityQuestions());
      dispatch(getSurvey());
    } 
  }, [dispatch, industryTypeSelected]);


  useEffect(() => {
    if (createQuestionStatus === STATUS.SUCCESS) {
      enqueueSnackbar("Question created successfully", { variant: "success", autoHideDuration: 3000 });
      dispatch(resetCreateQuestionsStatus());
      dispatch(getSurvey());
      dispatch(getMaterialityQuestions());
      setOpenQuestionsForm(false);
    } else if (createQuestionStatus === STATUS.ERROR) {
      enqueueSnackbar(
        createQuestionMessage ||
          "We couldn't process your request. Please try again later.",
        { variant: "error", autoHideDuration: 3000  }
      );
      dispatch(resetCreateQuestionsStatus());
    }
  }, [
    createQuestionStatus,
    enqueueSnackbar,
    openQuestionsForm,
    dispatch,
    createQuestionMessage
  ]);

  useEffect(() => {
    if (sendQuestionnairesStatus === STATUS.SUCCESS) {
      enqueueSnackbar("Email sent successfully", { variant: "success",autoHideDuration: 3000  });
      dispatch(resetCreateQuestionsStatus());
      setOpen(false);
    } else if (sendQuestionnairesStatus === STATUS.ERROR) {
      enqueueSnackbar(
        sendQuestionnairesMessage ||
          "We couldn't process your request. Please try again later.",
        { variant: "error" , autoHideDuration: 3000 }
      );
      dispatch(resetCreateQuestionsStatus());
    }
  }, [
    sendQuestionnairesStatus,
    enqueueSnackbar,
    open,
    dispatch,
    sendQuestionnairesMessage,
  ]);

  const getInfoContent = () => {
    return(
      <>
      <div style={{ display: "inline-flex"}}>
      <Typography className={classes.toolTipText}><a href="https://sasb.ifrs.org/standards/materiality-finder/find/" target="_blank" 
      rel="noopener noreferrer" className={classes.externalLink}>Click here</a></Typography>
      <Typography className={classes.toolTipText}>to find your industry type.</Typography></div><br></br>
      <Typography className={classes.toolTipText}>Once chosen, the industry type cannot be changed</Typography>
      </>
    )
  }

  const getConfirmation = () => {
    return(
      <>
      <Typography className={classes.confirmText}>Are you sure you want to confirm the industry?</Typography>
      <Box className={classes.buttonContainer}>
          <CeroButton
            buttonText="Yes"
            className={classes.buttonPrimary}
            onClick={handleConfirm}
          />
          <CeroButton
            buttonText="No"
            variant="outlined"
            className={classes.buttonSecondary}
            onClick={()=>setConfirmPopup(false)}
          />
          </Box>
      </>
    )
  }
  let industryName = Array.isArray(industryData) 
  ? industryData.filter((item) => item.id === industry) 
  : '';

  return (
    <DashboardLayout>
      <Box className={classes.container}>
      {loadSurveyDetails === STATUS.SUCCESS ?
      <>
        <Box mb={4} display="flex" justifyContent="space-between">
          <Typography fontSize={30} fontWeight={550} variant="h3" style={{ textTransform: "capitalize"}}>
            {surveyData?.company_name}
          </Typography>
          {surveyData?.industry_type !== "None" && <CeroButton
            buttonText="Send Questionnaire to Stakeholders"
            className={classes.buttonPrimary}
            onClick={() => setOpen(true)}
          />}
        </Box>
        <Grid
          className={classes.filterContainer}
          container
          columnSpacing={2}
          alignItems="center"
          wrap="nowrap"
        >
          <Grid item xs={3} mr={3} className={classes.yearFilter}>
            <CeroAutoComplete
              classes={{ container: classes.autoComplete,  root: classes.textField }}
              id="industry"
              label="Type of Industry"
              fullWidth
              value={surveyData?.industry_type === "None" ? industryName[0]?.industry_type : surveyData?.industry_name }
              onChange={(e, newValue) => setIndustry(newValue?.id)}              
              options={industryList || []}
              
              itemMarginLeft="10px" 
              disabled={surveyData?.industry_type !== "None"}
            />
          </Grid>
          {surveyData?.industry_type === "None" && (!industry ?
          <Tooltip
            title={getInfoContent()} 
            classes={{ popper: classes.tooltip }}
            placement="bottom-start"
          >
            <InfoIcon className={classes.icon} />
          </Tooltip> :
          <CeroButton
            buttonText="Confirm"
            className={classes.confirmButton}
            onClick={()=>setConfirmPopup(true)}
          />)}
        </Grid>
        {surveyData?.industry_type !== "None" && <Questions
          setOpenQuestionsForm={setOpenQuestionsForm}
          openQuestionsForm={openQuestionsForm}
          createQuestionStatus={createQuestionStatus}
          companyName = {surveyData?.company_name}
        />}</>
        : (loadSurveyDetails === STATUS.RUNNING ?
        <Box className={classes.loader}>
        <CircularProgress />
        </Box> :
        ""
      )}
        <CeroPopUp
          primaryPopUp={{
            open: open,
            onClose: () => setOpen(false),
            content: SendQuestions({ setOpen,sendQuestionnairesStatus }),
            header: { title: "" },
          }}
          classes={{ dialog: classes.popUpdialog }}
          showCloseButton={true}
        />
        <CeroPopUp
          primaryPopUp={{
            open: confirmPopup,
            onClose: () => setConfirmPopup(false),
            content: getConfirmation(),
            header: { title: "" },
          }}
          classes={{ dialog: classes.dialog }}
          showCloseButton={true}
        />
      </Box>
    </DashboardLayout>
  );
};

export default Survey;
