import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";

const StackedColumnChart = ({ apiResponse }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const { total, ...yearlyData } = apiResponse;

    const years = Object.keys(yearlyData).sort();

    const allCategories = new Set();
    years.forEach(year => {
      Object.keys(yearlyData[year]).forEach(category => allCategories.add(category));
    });

    const categoryInfo = {
      "Solar Energy Program": { legend: "Solar Energy", color: "#6fbfba" },
      "Reforestation": { legend: "Reforestation", color: "#4CAF50" },
      "Wind Energy Program": { legend: "Wind Energy", color: "#846bb5" },
      "Hydro Energy Program": { legend: "Hydro Energy", color: "#3F51B5" },
      "Wave Energy Converter": { legend: "Wave Energy", color: "#FF9800" },
      "Afforestation": { legend: "Afforestation", color: "#8BC34A" }
    };

    const datasets = Array.from(allCategories).map(category => {
      return {
        label: categoryInfo[category]?.legend || category,
        data: years.map(year => yearlyData[year][category] || 0),
        backgroundColor: categoryInfo[category]?.color || getRandomColor(),
        barThickness: years.length > 10 ? 12 : 30,
      };
    });

    function getRandomColor() {
      const letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    }

    const data = {
      labels: years,
      datasets,
    };

    const maxDataValue = Math.max(...datasets.flatMap(dataset => dataset.data));

    const options = {
      maintainAspectRatio: false,
      scales: {
        x: {
          title: {
            display: true,
            text: "Year",
          },
          grid: { display: false },
          ticks: { color: "black" },
        },
        y: {
          title: {
            display: true,
            text: "Offset (tonnes)",
            color: "#4F4F4F",
            font: { weight: "500" },
          },
          grid: { display: false },
          ticks: {
            color: "black",
            callback: function (value) {
              if (maxDataValue > 1000) {
                const suffixes = ["", "k", "M"];
                const suffixIndex = Math.floor(String(value).length / 3);
                const shortValue = parseFloat(
                  (suffixIndex !== 0 ? value / Math.pow(1000, suffixIndex) : value).toPrecision(3)
                );
                return shortValue + suffixes[suffixIndex];
              }
              return value;
            },
          },
        },
      },
      plugins: {
        legend: {
          position: "bottom",
          labels: { boxWidth: 15, color: "black", padding: 30 },
        },
        tooltip: {
          callbacks: {
            label: (context) => context.raw,
          },
        },
        datalabels: {
          color: "#4F4F4F",
          formatter: (value) => (value !== 0 ? value.toFixed(0) : ""),
          anchor: "end",
          align: "top",
          rotation: "270",
          font: { size: years.length > 10 ? 12 : 14 },
        },
      },
      layout: {
        padding: { bottom: 10, top: 50 },
      },
    };

    const ctx = chartRef.current.getContext("2d");

    if (chartRef.current.chart) {
      chartRef.current.chart.destroy();
    }

    chartRef.current.chart = new Chart(ctx, {
      type: "bar",
      data: data,
      options: options,
      plugins: [ChartDataLabels],
    });
  }, [apiResponse]);

  return <canvas ref={chartRef} height={385}/>;
};

export default StackedColumnChart;
