import { Autocomplete, Box, Card, TextField, Typography, InputAdornment, IconButton, Popover, Button, Snackbar } from "@mui/material";
import useStyles from "./styles";
import DashboardLayout from "../../layouts/DashboardLayout";
import { ReactComponent as AddConfig } from "../../assets/icons/addConfig.svg"
import { ReactComponent as AddLayout } from "../../assets/icons/addLayout.svg"
import { ReactComponent as AddSave } from "../../assets/icons/addSave.svg"
import { ReactComponent as AddConfigActive } from "../../assets/icons/addConfigActive.svg"
import { ReactComponent as AddLayoutActive } from "../../assets/icons/addLayoutActive.svg"
import { ReactComponent as AddSaveActive } from "../../assets/icons/addSaveActive.svg"
import { ReactComponent as UnderLine } from "../../assets/icons/UnderLine.svg"
import totalUsers from "../../assets/icons/totalUsersConfig.png";
import CeroAutoComplete from "../../components/CeroAutoComplete";
import facilityConfig from  "../../assets/icons/FacilityConfig.png"
import totalTickets from "../../assets/icons/TicketsConfig.png"
import assessementConfig from "../../assets/icons/AssessmentConfig.png"
import auditConfig from "../../assets/icons/AuditConfig.png"
import recordConfig from "../../assets/icons/totalRecordsIcon.png"
import ticketsConfig from "../../assets/icons/totalTicketsIcon.png"
import filterConfig from "../../assets/icons/FilterConfig.png"
import roleConfig from "../../assets/icons/RoleConfig.png"
import ticketStatusConfig from "../../assets/icons/TicketStatusConfig.png"
import facilityDisConfig from "../../assets/icons/FacilityDisConfig.png"
import auditTrendConfig from "../../assets/icons/AuditTrendsConfig.png"
import barChartFilledConfig from "../../assets/icons/barChartConfig.png"
import donutChartConfig from "../../assets/icons/DonutChartConfig.png"
import type1Config from "../../assets/icons/Type1Config.png"
import type2Config from "../../assets/icons/Type2Config.png"
import type3Config from "../../assets/icons/Type3Config.png"
import type4Config from "../../assets/icons/Type4Config.png"
import type5Config from "../../assets/icons/Type5Config.png"
import type6Config from "../../assets/icons/Type6Config.png"

import type1ConfigSelected from "../../assets/icons/type1ConfigSelected.png"
import type2ConfigSelected from "../../assets/icons/type2ConfigSelected.png"
import type3ConfigSelected from "../../assets/icons/type3ConfigSelected.png"
import type4ConfigSelected from "../../assets/icons/type4ConfigSelected.png"
import type5ConfigSelected from "../../assets/icons/type5ConfigSelected.png"
import type6ConfigSelected from "../../assets/icons/type6ConfigSelected.png"

import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from "@mui/icons-material/Close";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useEffect, useState } from "react";
import { styled, width } from '@mui/system';
import { ArrowDropDown } from '@mui/icons-material';
import { ChromePicker } from 'react-color';
import StatisticsCard from "../Dashboard/Statistics/StatisticsCard";
import DashboardFilter from "../Dashboard/DashboardFilter";
import RoleDistributionGraph from "../Dashboard/RoleDistributionGraph";
import EmissionChart from "../Dashboard/EmissionChart";
import FilterImg from "../../assets/dashboardConfig/Filter.png"
import EmissionByScopeImg from "../../assets/dashboardConfig/EmissionByScope.png"
import FuelSourceEmissionImg from "../../assets/dashboardConfig/fuelSourceEmission.png"
import EmissionByTypeImg from "../../assets/dashboardConfig/emissionByType.png"
import TotalCo2EmissionImg from "../../assets/dashboardConfig/totalCo2Emission.png"

import TotalAssessmentsImg from "../../assets/dashboardConfig/totalAssessments.png"
import TotalAuditsImg from "../../assets/dashboardConfig/totalAudits.png"
import TotalEmissionsImg from "../../assets/dashboardConfig/totalEmissions.png"
import TotalFacilitiesImg from "../../assets/dashboardConfig/totalFacilities.png"
import TotalRecordsImg from "../../assets/dashboardConfig/totalRecords.png"
import TotalTicketsImg from "../../assets/dashboardConfig/totalTickets.png"
import TotalUsersImg from "../../assets/dashboardConfig/totalUsers.png"

import AntiCorruptionDisclosureImg from "../../assets/dashboardConfig/anti-corruptionDisclosure.png"
import AntiCorruptionTrainingImg from "../../assets/dashboardConfig/anti-corruptionTraining.png"
import boardDiversityImg from "../../assets/dashboardConfig/boardDiversity.png"
import developmentAndTrainingImg from "../../assets/dashboardConfig/development&Training.png"
import diversityImg from "../../assets/dashboardConfig/diversity.png"
import economicAndFinancialDisclosureImg from "../../assets/dashboardConfig/economicAndFinancialDisclosure.png"
import empHireAndTurnoverImg from "../../assets/dashboardConfig/empHire&Turnover.png"
import employeeTurnoverByGenderImg from "../../assets/dashboardConfig/employeeTurnoverByGender&AgeGroup.png"
import ethicalBehaviourImg from "../../assets/dashboardConfig/ethicalBehaviour.png"
import managementDiversityImg from "../../assets/dashboardConfig/managementDiversity.png"
import socialEngagementImg from "../../assets/dashboardConfig/socialEngagement.png"

import FacilityDistributionImg from "../../assets/dashboardConfig/facilityDistribution.png"
import RoleDistributionImg from "../../assets/dashboardConfig/roleDistribution.png"
import TicketStatusImg from "../../assets/dashboardConfig/ticketStatus.png"
import AuditTrendsImg from "../../assets/dashboardConfig/auditTrends.png"

import recordStatusImg from "../../assets/dashboardConfig/recordStatus.png"
import esgOverviewImg from "../../assets/dashboardConfig/esgOverview.png"

import EmissionByEnergySourceImg from "../../assets/dashboardConfig/emissionByEnergySource.png"
import InitiativeTrackingImg from "../../assets/dashboardConfig/initiativeTracking.png"



import { ExampleComponent, GridLayout } from "./layoutArrangement";

import EmissionsCard from "../Dashboard/EmissionsCard";
import CarbonOffsetCard from "../Dashboard/CarbonOffsetCard";
import FuelSourceGraph from "../Dashboard/FuelSourceGraph";
import OffsetOverYears from "../Dashboard/OffsetOverYears";
import OffsetProgramType from "../Dashboard/OffsetProgramType";
import FuelSourceTargetGraph from "../Dashboard/FuelSourcesSankeyGraph";
import TotalEmissionChart from "../Dashboard/TotalEmissionChart";
import EmissionvsOffset from "../Dashboard/EmissionvsOffset";
import InitiativeTrackingGraph from "../Dashboard/InitiativeTrackingGraph";
import EnergyMaterialConsumption from "../Dashboard/EnergyMaterialConsumption";
import DiversityGraph from "../Dashboard/DiversityGraph";
import EmployeeHireResignGraph from "../Dashboard/EmployeeHireResignGraph";
import SocialEngagementGraph from "../Dashboard/SocialEngagementGraph";
import DevelopmentTrainingGraph from "../Dashboard/DevelopmentTrainingGraph";
import EmployeeTurnoverGraph from "../Dashboard/EmployeeTurnoverGraph";
import AntiCorruptionDisclosureGraph from "../Dashboard/AntiCorruptionDisclosureGraph";
import ManagementDiversityGraph from "../Dashboard/ManagementDiversityGraph";
import BoardDiversityGraph from "../Dashboard/BoardDiversityGraph";
import EthicalBehaviourGraph from "../Dashboard/EthicalBehaviourGraph";
import EconomicGraph from "../Dashboard/EconomicGraph";
import AntiCorruptionTrainingGraph from "../Dashboard/AntiCorruptionTrainingGraph";
import AuditTrends from "../AuditorDashboard/AuditTrends";
import GeographicalChart from "../Dashboard/GeographicalChart";
import { useDispatch, useSelector } from "react-redux";
import { getUserGraphList, saveUserGraphConfig } from "../../redux/actions";
import { STATUS } from "../../redux/constants";
import { useSnackbar } from "notistack";
import { getUserGraphConfig } from "../../redux/actions/dashboard";
import { useNavigate } from "react-router-dom";
import { resetDashboardConfigStatus } from "../../redux/actions/dashboard";
// import { saveUserGraphConfig } from "../../redux/sagas/dashboard";

const CustomTextField = styled(TextField)({
    '& .MuiInputBase-root': {
      height: '35px',
      fontSize: '16px',
      fontFamily: 'Inter',
      width:'320px'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#ccc', // You can customize the border color
    },
  });

  const ColorPickerInput = ({color, setColor}) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
  
    const handleColorChange = (newColor) => {
      setColor(newColor.hex);
    };
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
    
  
    const open = Boolean(anchorEl);
    const id = open ? 'color-popover' : undefined;
  
    return (
      <div className={classes.colorPicker}>
        <TextField
          value={color}
          variant="outlined"
          style={{
              flex: 1,
              paddingLeft: '10px',
              height: '35px', // Set the height of the TextField
              border: 'none', // Remove the border
              outline: 'none', // Remove the outline
              padding:'5px !important'
            }}
            inputProps={{ readOnly: true }}
            InputProps={{
                disableUnderline: true, // Disable underline for the TextField
            }}
        />
        <div
            style={{
                backgroundColor: color,
                width: '20px',
                height: '20px',
                border: '1px solid #ccc',
                marginLeft: '10px',
                marginRight: '10px',
                alignSelf: 'center', // Align the color swatch vertically
            }}
        ></div>
        <IconButton onClick={handleClick} style={{ height: '35px' }}>
          <ArrowDropDown />
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <ChromePicker color={color} onChange={handleColorChange} />
        </Popover>
      </div>
    );
  };
  
  export const getGraphs = (onApplyFilter, filter, filterValue, setValue, isShowConfigButton) => {
    return {
        'Total Users' : {"name": 'Total Users', "icon": totalUsers, "graphComponent":StatisticsCard, "data":{index:0, title:'Total Users',value:12}, img:TotalUsersImg, size:'s', tabIndex:0},
        'Total Facilities' : {"name": 'Total Facilities', "icon": facilityConfig, "graphComponent":StatisticsCard, "data":{index:1, title:'Total Facilities',value:2}, img:TotalFacilitiesImg, size:'s', tabIndex:0},
        'Total Assessment Cycles' : {"name": 'Total Assessment Cycles', "icon": assessementConfig, "graphComponent":StatisticsCard, "data":{index:1, title:'Total Assessment Cycles',value:4}, img:TotalAssessmentsImg, size:'s', tabIndex:0},
        'Total Audits' : {"name": 'Total Audits', "icon": auditConfig, "graphComponent":StatisticsCard, "data":{index:1, title:'Total Audits', value:3}, img:TotalAuditsImg, size:'s', tabIndex:0},
        'Total Tickets' : {"name": 'Total Tickets', "icon": recordConfig, "graphComponent":StatisticsCard, "data":{index:1, title:'Total Tickets', value:3}, img:TotalTicketsImg, size:'s', tabIndex:0},
        'Total Records' : {"name": 'Total Records', "icon": ticketsConfig, "graphComponent":StatisticsCard, "data":{index:1, title:'Total Records', value:3}, img:TotalRecordsImg, size:'s', tabIndex:0},
        'Filters' : {"name": 'Filters', "icon": filterConfig, "graphComponent":DashboardFilter, "data": {onApplyFilter:onApplyFilter, value:filterValue , setValue:setValue, isShowConfigButton:isShowConfigButton}, img:FilterImg, size:'f', tabIndex:null},
        'Total Emissions' : {"name": 'Total Emissions', "icon": roleConfig, "graphComponent":EmissionsCard, data:{filter:filter}, img:TotalEmissionsImg, size:'m', tabIndex:0},
        'Total Carbon Offset' : {"name": 'Total Carbon Offset', "icon": roleConfig, "graphComponent":CarbonOffsetCard, data:{filter:filter}, img:TotalEmissionsImg, size:'m', tabIndex:0},
        'Emission By Scope' : {"name": 'Emission By Scope', "icon": barChartFilledConfig, "graphComponent":FuelSourceGraph, data:{filter:filter}, img:EmissionByScopeImg, size:'m', tabIndex:0},
        'Offset Over Years' : {"name": 'Offset Over Years', "icon": barChartFilledConfig, "graphComponent":OffsetOverYears, data:{filter:filter}, img:EmissionByScopeImg, size:'m', tabIndex:0},
        'Fuel Source Emission' : {"name": 'Fuel Source Emission', "icon": roleConfig, "graphComponent":FuelSourceTargetGraph, data:{filter:filter}, img:FuelSourceEmissionImg, size:'l', tabIndex:0},
        'Emission Types(tonnes)' : {"name": 'Emission Types(tonnes)', "icon": donutChartConfig, "graphComponent":EmissionChart, data:{filter:filter}, img:EmissionByTypeImg, size:'m', tabIndex:0},
        'Offset Program Type' : {"name": 'Offset Program Type', "icon": donutChartConfig, "graphComponent":OffsetProgramType, data:{filter:filter}, img:EmissionByTypeImg, size:'m', tabIndex:0},
        'Total CO2e Emissions' : {"name": 'Total CO2e Emissions', "icon": barChartFilledConfig, "graphComponent":TotalEmissionChart, data:{filter:filter}, img:TotalCo2EmissionImg, size:'m', tabIndex:0},
        'Emission Vs Offset' : {"name": 'Emission Vs Offset', "icon": barChartFilledConfig, "graphComponent":EmissionvsOffset, data:{filter:filter}, img:TotalCo2EmissionImg, size:'m', tabIndex:0},
        'Initiative Tracking' : {"name": 'Initiative Tracking', "icon": auditTrendConfig, "graphComponent":InitiativeTrackingGraph, data:{filter:filter}, img:InitiativeTrackingImg, size:'m', tabIndex:0},
        'Emission By Energy Source' : {"name": 'Emission By Energy Source', "icon": barChartFilledConfig, "graphComponent":EnergyMaterialConsumption, data:{filter:filter}, img:EmissionByEnergySourceImg, size:'m', tabIndex:0},
        
        'Diversity' : {"name": 'Diversity', "icon": donutChartConfig, "graphComponent":DiversityGraph, data:{filter:filter}, img:diversityImg, size:'m', tabIndex:1},
        'Employee Hire & Resign Trend' : {"name": 'Employee Hire & Resign Trend', "icon": auditTrendConfig, "graphComponent":EmployeeHireResignGraph, data:{filter:filter}, img:empHireAndTurnoverImg, size:'m', tabIndex:1},
        'Development & Training' : {"name": 'Development & Training', "icon": barChartFilledConfig, "graphComponent":DevelopmentTrainingGraph, data:{filter:filter}, img:developmentAndTrainingImg, size:'m', tabIndex:1},
        'Social Engagement' : {"name": 'Social Engagement', "icon": auditTrendConfig, "graphComponent":SocialEngagementGraph, data:{filter:filter}, img:socialEngagementImg, size:'m', tabIndex:1},
        'Employee Turnover' : {"name": 'Employee Turnover', "icon": barChartFilledConfig, "graphComponent":EmployeeTurnoverGraph, data:{filter:filter}, img:employeeTurnoverByGenderImg, size:'l', tabIndex:1},
        'Anti-Corruption Training' : {"name": 'Anti-Corruption Training', "icon": auditTrendConfig, "graphComponent":AntiCorruptionTrainingGraph, data:{filter:filter}, img:AntiCorruptionTrainingImg, size:'m', tabIndex:1},
        'Anti-Corruption Disclosure' : {"name": 'Anti-Corruption Disclosure', "icon": auditTrendConfig, "graphComponent":AntiCorruptionDisclosureGraph, data:{filter:filter}, img:AntiCorruptionDisclosureImg, size:'m', tabIndex:1},
    
        'Management Diversity' : {"name": 'Management Diversity', "icon": donutChartConfig, "graphComponent":ManagementDiversityGraph, data:{filter:filter}, img:managementDiversityImg, size:'m', tabIndex:1},
        'Board Diversity' : {"name": 'Board Diversity', "icon": donutChartConfig, "graphComponent":BoardDiversityGraph, data:{filter:filter}, img:boardDiversityImg, size:'m', tabIndex:1},
        'Ethical Behaviour' : {"name": 'Ethical Behaviour', "icon": auditTrendConfig, "graphComponent":EthicalBehaviourGraph, data:{filter:filter}, img:ethicalBehaviourImg, size:'m', tabIndex:1},
        'Economic & Financial Disclosure' : {"name": 'Economic & Financial Disclosure', "icon": barChartFilledConfig, "graphComponent":EconomicGraph, data:{filter:filter}, img:economicAndFinancialDisclosureImg, size:'m', tabIndex:1},
    
        'Role Distribution' : {"name": 'Role Distribution', "icon": roleConfig, "graphComponent":RoleDistributionGraph, data:{filter:filter}, img:RoleDistributionImg, size:'m', tabIndex:0},
        'Ticket Status' : {"name": 'Ticket Status', "icon": ticketStatusConfig, "graphComponent":FuelSourceGraph, img:TicketStatusImg, data:{filter:filter},size:'m', tabIndex:0},
        'Facility Distribution' : {"name": 'Facility Distribution', "icon": facilityDisConfig, "graphComponent":GeographicalChart, img:FacilityDistributionImg, data:{filter:filter},size:'m', tabIndex:0},
        'Audit Trend Graphs' : {"name": 'Audit Trend Graphs', "icon": barChartFilledConfig, "graphComponent":AuditTrends, img:AuditTrendsImg, data:{filter:filter},size:'m', tabIndex:0},
    
        'Record Status' : {"name": 'Record Status', "icon": donutChartConfig, "graphComponent":EmissionChart, data:{filter:filter}, img:recordStatusImg, size:'m', tabIndex:0},
        'ESG Overview' : {"name": 'ESG Overview', "icon": donutChartConfig, "graphComponent":GeographicalChart, data:{filter:filter}, img:esgOverviewImg, size:'m', tabIndex:0},
    
     }
  }
 
const DashboardConfiguration = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [searchValue, setSearchValue] = useState('');
    const [filter, setFilter] = useState({ duration: "last_five_years" });
    const [filterValue, setValue] = useState(0);
    const [tab, setTab] = useState(0);
    const [chosenType, setChosenType] = useState(1);
    const options = ['Option 1', 'Option 2', 'Option 3'];
    const [selectedCharts, setSelectedChart] = useState([]);
    const [chosenChart, setChosenChart] = useState(null);
    const [color, setColor] = useState('#F7F8F9');
    const widgetList = useSelector((state) => state.dashboard.getGraphList.data);
    const saveConfgStatus = useSelector((state) => state.dashboard.saveUserGraphConfig.status);
    const userConfig = useSelector((state) => state.dashboard.getUserGraphConfig.data);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    
    const switchTab = (value) => {
        setTab(value)
    }
    const [isHovered, setIsHovered] = useState({});

  const handleMouseEnter = (name) => {
    setIsHovered({...isHovered, [name]:true});
  };
  const handleLineItemClick = (name) => {
    setChosenChart(GRAPHS[name])
    setTab(4);
  };

  const handleMouseLeave = (name) => {
    setIsHovered({...isHovered, [name]:false});
  };

  const removeItemSelected = (removeItem) => {
    const updatedArray = selectedCharts.filter(item => item.name !== removeItem.name);
    setSelectedChart(updatedArray)
  };

  const addItemSelected = (addItem) => {
    const updatedArray = selectedCharts.filter(item => item.name === addItem.name);
    if (updatedArray.length===0){
        setSelectedChart([...selectedCharts, addItem])
    }
    // setSearchValue('')
  };

  const onApplyFilter = (filter) => {
    setFilter(filter);
  };

  const disableSaveButton = () => {
    var widgetChange = true
    if (userConfig?.configuration){
        if ((selectedCharts.length === userConfig?.configuration?.widgets.length) && selectedCharts.every((element, index) => userConfig?.configuration?.widgets.includes(element.name) ))
            widgetChange = false
    }
    else{
        widgetChange = selectedCharts.length > 0 ? true : false
    }
    const colorChange = userConfig?.configuration ? userConfig?.configuration?.background_color !== color : false
    const typeChange = userConfig?.configuration ? parseInt(userConfig?.configuration?.layout.split('type_')[1]) !== chosenType : false
    // const apiCalling = saveConfgStatus === STATUS.RUNNING
    return !(widgetChange || colorChange || typeChange )
  }

  useEffect(() => {
    dispatch(getUserGraphList());
    dispatch(getUserGraphConfig());
  }, [dispatch]);

  const widgetNamesList = widgetList?.widget_list?.map(item=> item.name)
    
  const GRAPHS = getGraphs(onApplyFilter, filter, filterValue, setValue, false)
 const filterObjectByKeys = (obj, keysArray) => {
    return Object.fromEntries(
      Object.entries(obj).filter(([key]) => keysArray?.includes(key))
    );
  };

  useEffect(() => {
    if (userConfig?.configuration){
        setColor(userConfig?.configuration?.background_color)
        if (userConfig?.configuration?.layout){
            let layoutType = userConfig?.configuration?.layout.split('type_')[1]
            setChosenType(parseInt(layoutType))
        }
        if (userConfig?.configuration?.widgets)
        {
            let selectNames = userConfig?.configuration?.widgets.map(item=> GRAPHS[item]);
            setSelectedChart(selectNames)
        }
            
    }
  }, [userConfig]);

  const saveUserData = () => {
        const selectedChartNames = selectedCharts.map(item=> item.name)
        const selectedIds = widgetList?.widget_list?.filter(item => selectedChartNames.includes(item.name))
        .map(item => item.id);
        const payload = {
            "widgets": selectedIds,
            "layout": `type_${chosenType}`,
            "background_color": color
        }
        dispatch(saveUserGraphConfig(payload))
  }

  useEffect(() => {
    if (saveConfgStatus === STATUS.SUCCESS) {
        enqueueSnackbar("Configuration save successfully", {variant: "success",});
        // dispatch(getUserGraphConfig());
        dispatch(resetDashboardConfigStatus());
        navigate(-1);
        // dispatch(resetAddCombustionStatus());
        // onCancel();
        setTab(0)
    }
  }, [saveConfgStatus]);


 const userGraphList = filterObjectByKeys(GRAPHS, widgetNamesList);
    
    return (
        <DashboardLayout>
            <Box className={classes.container}>
                <div className={classes.ActionHeader}>
                    <div className={classes.iconOuter}>{tab==1?<AddConfigActive className={classes.normalIcon} onClick={()=>switchTab(1)}/> : <AddConfig className={classes.normalIcon} onClick={()=>switchTab(1)}/>}</div>
                    <div className={classes.iconOuter}>{tab==2?<AddLayoutActive className={classes.normalIcon} onClick={()=>switchTab(1)}/> : <AddLayout className={classes.normalIcon} onClick={()=>switchTab(2)}/>}</div>
                    <div className={classes.iconOuter}>{tab==3?<AddSaveActive className={classes.normalIcon} onClick={()=>switchTab(1)}/> : <AddSave className={classes.normalIcon} onClick={()=>switchTab(3)}/>}</div>
                    {/* <UnderLine/> */}
                </div>
                <div>
                    
                    <Box className={classes.displayDiv}  style={{background:color}}>
                        {tab==1?
                        <div className={classes.widgetSelector}>
                            <div className={classes.lineDivHeader}>
                                <Typography className={classes.addwidgetTypo}>Add Widgets</Typography>
                                <CloseIcon
                                    onClick={() => setTab(0)}
                                    className={classes.closeIcon}
                                    />
                            </div>
                            <div className={classes.lineDiv}>
                                <Typography className={classes.widgetcatTypo}>Widget Category</Typography>
                                {/* <Autocomplete
                                    freeSolo
                                    value={searchValue}
                                    onChange={(event, newValue) => setSearchValue(newValue)}
                                    options={options}
                                    renderInput={(params) => ( */}
                                        <CustomTextField
                                        // {...params}
                                        variant="outlined"
                                        value={searchValue}
                                        placeholder="Search widgets"
                                        onChange={(e) => setSearchValue(e.target.value)}
                                        InputProps={{
                                            // ...params.InputProps,
                                            startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                            ),
                                            style: {
                                                padding: '0 12px', // Padding around the input text
                                                height: '40px',    // Sets the height of the input
                                            },
                                        }}
                                        />
                                    {/* )}
                                    /> */}
                            </div>
                            {Object.keys(userGraphList)
                            .filter(item => 
                                (!searchValue || item.toLowerCase().includes(searchValue.toLowerCase())) &&
                                !selectedCharts.some(selected => selected.name === item) // Filter out already selected items
                              )
                            .map((item, index)=> (
                                <div key={index}  className={classes.lineDiv} onClick={()=>handleLineItemClick(item)}>
                                <span className={classes.lineItem}>
                                <img src={GRAPHS[item].icon} height="40" width="40" alt="Logo"/>
                                <Typography className={classes.lineItemText}>{GRAPHS[item].name}</Typography>
                                </span>
                            </div>
                            ))}
                            
                        </div> : tab==2? 
                        <div className={classes.widgetSelector}>
                            <div className={classes.lineDivHeader}>
                                <Typography className={classes.addwidgetTypo}>Configuration</Typography>
                                <CloseIcon
                                    onClick={() => setTab(0)}
                                    className={classes.closeIcon}
                                    />
                            </div>
                            <div className={classes.lineDiv}>
                                <Typography className={classes.widgetcatTypo}>Dashboard Background</Typography>
                                <ColorPickerInput color={color} setColor={setColor}/>
                            </div>
                            <div className={classes.lineDiv}>
                                <Typography className={classes.widgetcatTypo}>Apply quick layout</Typography>
                                <div>
                                    <div className={classes.typeSelection}>
                                        <img src={chosenType && chosenType == 1 ? type1ConfigSelected : type1Config} height="80" width="80" alt="Logo" onClick={()=>setChosenType(1)}/>
                                        <img src={chosenType && chosenType == 2 ? type2ConfigSelected : type2Config} height="80" width="80" alt="Logo" onClick={()=>setChosenType(2)}/>
                                        <img src={chosenType && chosenType == 3 ? type3ConfigSelected : type3Config} height="80" width="80" alt="Logo" onClick={()=>setChosenType(3)}/>
                                    </div>
                                    <div className={classes.typeSelection}>
                                        <img src={chosenType && chosenType == 4 ? type4ConfigSelected : type4Config} height="80" width="80" alt="Logo" onClick={()=>setChosenType(4)}/>
                                        <img src={chosenType && chosenType == 5 ? type5ConfigSelected : type5Config} height="80" width="80" alt="Logo" onClick={()=>setChosenType(5)}/>
                                        <img src={chosenType && chosenType == 6 ? type6ConfigSelected : type6Config} height="80" width="80" alt="Logo"onClick={()=>setChosenType(6)}/>
                                    </div>
                                </div>
                            </div>
                        </div> : tab==3 ?
                        <div className={classes.widgetSelector}>
                            <div className={classes.lineDivHeader}>
                                <Typography className={classes.addwidgetTypo}>Save Dashboard</Typography>
                                <CloseIcon
                                    onClick={() => setTab(0)}
                                    className={classes.closeIcon}
                                    />
                            </div>
                            <div className={classes.lineDiv}>
                                <Typography className={classes.widgetcatTypo}>Are you sure you want to save the changes to your dashboard? This will overwrite the previous configuration.</Typography>
                                <div className={classes.buttonContainer}>
                                    <Button
                                        className={classes.buttonPrimary}
                                        variant="contained"
                                        onClick={() => saveUserData()}
                                        disabled={disableSaveButton() || saveConfgStatus === STATUS.RUNNING}
                                    >
                                        {saveConfgStatus === STATUS.RUNNING ? 'Saving...' : 'Save' }
                                    </Button>
                                    <Button
                                        className={classes.buttonSecondary}
                                        variant="outlined"
                                        onClick={() => setTab(0)}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                            
                        </div> : tab==4 ?
                        <div className={classes.widgetSelector}>
                            <div className={classes.lineDivHeader}>
                                <div className={classes.iconText}>
                                <ChevronLeftIcon
                                    onClick={() => setTab(1)}
                                    className={classes.closeIcon}
                                    style = {{width:'30px', height:'30px'}}
                                    />
                                <Typography className={classes.addwidgetTypo}>Widgets</Typography>
                                </div>
                                <CloseIcon
                                    onClick={() => setTab(0)}
                                    className={classes.closeIcon}
                                    />
                            </div>
                            <div className={classes.prevDiv}>
                                <div className={classes.previewChart}>
                                <img src={chosenChart.img} style={{width:"100%", maxHeight:"400px"}} alt="Logo"/>
                                </div>
                                <div className={classes.buttonContainer}>
                                    <Button
                                        className={classes.buttonPrimary}
                                        variant="contained"
                                        onClick={()=>addItemSelected(chosenChart)}
                                        disabled={selectedCharts.some(item=>item.name == chosenChart.name)}
                                    >
                                        Add
                                    </Button>
                                    
                                </div>
                            </div>
                            
                        </div>:<></>}
                        <div className={ selectedCharts.length>0 ? tab!==0 ? classes.noDataDivShort:classes.noDataDiv : classes.noDataDivText}>
                            {tab==2 || tab==3 && chosenType > 0 ? selectedCharts.length > 0 ? 
                            <GridLayout type={chosenType} components={selectedCharts} tab={tab} /> : 
                            <Typography className={classes.noDatatext}>Add Widgets using the widget picker</Typography> :
                            selectedCharts.length>0 ? (selectedCharts.map((item, index)=>(
                                <div key={index} className={item?.size === 's' ? classes.eachGraphS : item?.size === 'm' ? classes.eachGraphM : item?.size === 'f' ? classes.eachGraphF : classes.eachGraphL} onMouseEnter={()=>handleMouseEnter(item?.name)} onMouseLeave={()=>handleMouseLeave(item?.name)}>
                                    {item && <item.graphComponent {...item?.data}/>}
                                    {item && isHovered[item?.name] && ( <div className={classes.hoverEachGraph}>
                                        <CloseIcon
                                        onClick={() => removeItemSelected(item)}
                                        className={classes.removeIcon}
                                        />
                                        </div>
                                    )}

                                </div>
                                ) 
                            )):<Typography className={classes.noDatatext}>Add Widgets using the widget picker</Typography>} 
                        </div>
                            
                    </Box>
                </div>
            </Box>
        </DashboardLayout>
    )
}

export default DashboardConfiguration;