import { Typography, Box, Container, Grid, Modal } from "@mui/material";
import React, { useState } from "react";
import useStyles from "../styles";
import CeroItemPair from "../../../components/CeroItemPair";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import { getCookie } from "../../../services/cookie";
import CeroButton from "../../../components/CeroButton";
import CeroInfoPair from '../../../components/CeroInfoPair';
import Download from "../../../assets/images/Download";
import CloseIcon from "@mui/icons-material/Close";

const SubsidiesAndFinancialAssistanceApproval = (props) => {
  const classes = useStyles();
  const { emissionData, handleCallback, handleReviewCallback } = props;
  const role = getCookie('role');
  const readOnlyMode = (getCookie("read_only_mode") === "disable_edit")
  const showActionButtons = window.location.pathname.includes('/pending-approval-details') && (role === "sustainability_manager" || role === "proxy_sustainability_manager" )
  const showVerifyButtons = window.location.pathname.includes('/companies') && role === "auditor"
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleIconClick = (itemId) => {
    handleCallback(itemId)
  };
  
  const handleReviewClick = (itemId) => {
    handleReviewCallback(itemId)
  }

  const handlePdfOpen = () => {
    setIsModalOpen(true);
  };
  
  const handlePdfClose = () => {
    setIsModalOpen(false);
  };

  const handlePdfPopup = () => {
    return (
      <embed
        src={`${emissionData.blob_url}#toolbar=0`}
        type="application/pdf"
        width="100%"
        height="600px"
      />
    );
  };


  return (
    <Container className={classes.mainContainer}>
      {emissionData && Array.isArray(emissionData) && emissionData?.map((item, index) => (
          <Container key={index} className={classes.container}>
            {showVerifyButtons && 
            <Box className={classes.trainingAndProgramAction}>
                <CeroInfoPair 
                title="Status:"
                value={item.status === "pending_approval" ? "Pending Approval" : item.status ? item.status : "NA"}
                classes={{
                  container: classes.statusContainer,
                  title: classes.statusTitle,
                  value: item.status === "In Progress" || item.status === "pending_approval" ? classes.Inprogress : classes[item?.status]
                }}
              />
                {showVerifyButtons && (emissionData.length > 1) && !readOnlyMode &&<Grid container spacing={2}>
                  <Grid item xs={6}>
                  {(item.status === "approved") && 
                 <div className={classes.actionButtons} onClick={() => handleIconClick(item.id)}>
                 <CheckCircleOutlineOutlinedIcon className={classes.icon} />
                 <CeroButton
                 variant="contained"
                 buttonText="Validate"
                 className={classes.approveButton}/></div>}
                </Grid>
                <Grid item xs={6}>
                {(item.status === "approved") && 
                <div className={classes.actionButtons} onClick={() => handleReviewClick(item.id)}>
                <ArrowCircleLeftOutlinedIcon className={classes.icon} />
                <CeroButton
                 variant="contained"
                 buttonText="Review"
                 className={classes.approveButton}/></div>}
                </Grid>
                </Grid>}
                </Box>
              }
            <CeroItemPair
              title="Total Financial Assistance Received:"
              value={`$${item.financial_assistant_received}` || "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.subsidiesTitle,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Type of Financial Assistance:"
              value={item.type_financial_assistant || "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.subsidiesTitle,
                value: classes.value,
              }}
            />
            {emissionData.blob_url && (
              <div
              style={{
                transform: "scale(0.6)",
                transformOrigin: "center",
                position: "absolute",
                top: 100,
                right: 100,
              }}
              onClick={handlePdfOpen}
            >
              <Download />
            </div>

            )}
            <Typography variant='subtitle2' className={classes.subsidiesHeading}>Total monetary value of financial assistance received from each Facility:</Typography>
            <Box className={classes.amountDistribution}>
              {item.financial_assistant_by_facilities?.map(elem=>(
                <CeroItemPair
                title={elem.facility_name}
                value={`$${elem.amount}` || "NA"}
                classes={{
                  container: classes.infoContainer,
                  title: classes.subsidiesTitle,
                  value: classes.value,
                }}
              />
              ))}
            </Box>
            <CeroItemPair
              title="Indicate whether any government is present in the shareholding structure of the organization:"
              value={item.any_government_present_in_shareholder || "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.subsidiesTitle,
                value: classes.value,
              }}
            />
            {item.any_government_present_in_shareholder === "Yes" && <CeroItemPair
              title="Details:"
              value={item.details || "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.subsidiesTitle,
                value: classes.value,
              }}
            />}
              
          </Container>
))}
<Modal open={isModalOpen} onClose={handlePdfClose}>
      <Box className={classes.modalContent}>
        <CloseIcon onClick={handlePdfClose} className={classes.closeButton}/>
        {handlePdfPopup()}
      </Box>
    </Modal>
    </Container>
  );
};

export default SubsidiesAndFinancialAssistanceApproval;
