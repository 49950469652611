import React, { useEffect, useMemo, useState } from "react";
import DashboardLayout from "../../../../layouts/DashboardLayout";
import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  getStandardsQuestionnaire,
  listAssessmentCycle,
  viewStandardsQuestionnaireAnswer,
} from "../../../../redux/actions";
import GriView from "./GriView";

function ViewQuestionnaire() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [year, setYear] = useState(null);
  const [country, setCountry] = useState(null);

  const data = useSelector(
    (state) => state.assessment.viewStandardsQuestionnaireAnswer.data
  );
  const assessmentList = useSelector(
    (state) => state.assessment.assessmentList.data
  );

  // const filteredData = data?.answer?.filter(
  //   (item) => item?.answer !== "" && item?.answer !== null
  // );

  const isTableEmpty = (answer) => {
    if (Array.isArray(answer) && answer.length === 0) return true;
    else if (typeof answer === "object") return answer;
    return answer.every((row) => {
      if (!row) return true;
      return Object.values(row).every(
        (value) => String(value || "").trim() === ""
      );
    });
  };

  const filteredData = data?.answer?.filter((item) => {
    if (item.question_type === "table") {
      return isTableEmpty(item.answer);
    }
    return item.answer !== "" && item.answer !== null;
  });
  const questionsData = useSelector((state) => {
    const data = state.emission.getStandardsQuestionnaire.data.questions_list;
    return Array.isArray(data) ? data : [];
  });

  const questionsMap = questionsData.reduce((map, question) => {
    map[question.id] = question.question;
    return map;
  }, {});

  const requestData = useMemo(
    () => ({
      framework_id: location.state.framework_id,
      questionnaire_id: location.state.questionnaire_id,
    }),
    []
  );

  const GRI_FRAMEWORK_ID = "628b502e3b30401f0be76655";

  const frameworkId = location.state?.framework_id || GRI_FRAMEWORK_ID;

  useEffect(() => {
    dispatch(viewStandardsQuestionnaireAnswer(requestData));
  }, [requestData]);

  useEffect(() => {
    if (data.country_code) {
      setCountry(data.country_code);
    }

    if (data.assessment_year) {
      setYear(data.assessment_year);
    }
    const filteredList =
      assessmentList?.filter(
        (item) =>
          item.assessment_year === data.assessment_year &&
          item.country === data.country_code
      ) || [];
    const assessment_id = filteredList[0]?.id;

    dispatch(
      getStandardsQuestionnaire(
        location.state.framework_id || "67580e50d845c48ffba61868",
        assessment_id
      )
    );
  }, [dispatch, data]);
  const navigateBack = () => {
    navigate(-1);
  };
  const renderTCFDTable = (answer) => (
    <TableContainer
      sx={{ marginBottom: 3, borderRadius: 1, overflow: "hidden" }}
    >
      <Table sx={{ borderRadius: 1 }}>
        <TableHead>
          {/* Check if category exists and conditionally render headers */}
          {answer.some((item) => item.category) ? (
            // Table Header when category exists
            <TableRow sx={{ backgroundColor: "#d0c5ca" }}>
              <TableCell sx={{ border: 1, fontWeight: 520 }}>
                Category
              </TableCell>
              <TableCell sx={{ border: 1, fontWeight: 520 }}>Risks</TableCell>
              <TableCell sx={{ border: 1, fontWeight: 520 }}>
                Potential Impacts (Pre-defined)
              </TableCell>
              <TableCell sx={{ border: 1, fontWeight: 520 }}>
                Time period (Short/Medium/Long)
              </TableCell>
              <TableCell sx={{ border: 1, fontWeight: 520 }}>
                Applicable to Your Organisation? (Yes/ NO)
              </TableCell>
              <TableCell sx={{ border: 1, fontWeight: 520 }}>
                Comments/Additional Details
              </TableCell>
            </TableRow>
          ) : (
            // Table Header when category is missing
            <TableRow sx={{ backgroundColor: "#d0c5ca" }}>
              <TableCell sx={{ border: 1, fontWeight: 520 }}>
                Scenario
              </TableCell>
              <TableCell sx={{ border: 1, fontWeight: 520 }}>
                Description of Scenario
              </TableCell>
              <TableCell sx={{ border: 1, fontWeight: 520 }}>
                Key Risks Identified
              </TableCell>
              <TableCell sx={{ border: 1, fontWeight: 520 }}>
                Key Opportunities Identified
              </TableCell>
              <TableCell sx={{ border: 1, fontWeight: 520 }}>
                Resilience Strategies
              </TableCell>
              <TableCell sx={{ border: 1, fontWeight: 520 }}>
                Gaps or Challenges Identified
              </TableCell>
            </TableRow>
          )}
        </TableHead>
        <TableBody>
          {answer.map((categoryData, idx) =>
            // If `category` is present
            categoryData.category
              ? categoryData.risks.map((riskData, riskIdx) => (
                  <TableRow key={`${idx}-${riskIdx}`}>
                    {riskIdx === 0 && (
                      <TableCell
                        rowSpan={categoryData.risks.length}
                        sx={{ border: 1 }}
                      >
                        {categoryData.category}
                      </TableCell>
                    )}
                    <TableCell sx={{ border: 1 }}>{riskData.risk}</TableCell>
                    <TableCell sx={{ border: 1 }}>{riskData.impacts}</TableCell>
                    <TableCell sx={{ border: 1 }}>
                      {riskData.timePeriod}
                    </TableCell>
                    <TableCell sx={{ border: 1 }}>
                      {riskData.applicable}
                    </TableCell>
                    <TableCell sx={{ border: 1 }}>
                      {riskData.comments}
                    </TableCell>
                  </TableRow>
                ))
              : // If `category` is missing
                categoryData.risks.map((riskData, riskIdx) => (
                  <TableRow key={`${idx}-${riskIdx}`}>
                    <TableCell sx={{ border: 1 }}>{riskData.risk}</TableCell>
                    <TableCell sx={{ border: 1 }}>{riskData.impacts}</TableCell>
                    <TableCell sx={{ border: 1 }}>{riskData.keyRisk}</TableCell>
                    <TableCell sx={{ border: 1 }}>
                      {riskData.keyOpportunity}
                    </TableCell>
                    <TableCell sx={{ border: 1 }}>
                      {riskData.resilience}
                    </TableCell>
                    <TableCell sx={{ border: 1 }}>
                      {riskData.gapsChallenges}
                    </TableCell>
                  </TableRow>
                ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
  const renderAssetValueTable = (answer) => (
    <TableContainer
      sx={{ marginBottom: 3, borderRadius: 1, overflow: "hidden" }}
    >
      <Table sx={{ borderRadius: 1 }}>
        <TableHead>
          <TableRow sx={{ backgroundColor: "#d0c5ca" }}>
            <TableCell sx={{ border: 1, fontWeight: 520 }}>
              Total Number Of Operations
            </TableCell>
            <TableCell sx={{ border: 1, fontWeight: 520 }}>Net Sales</TableCell>
            <TableCell sx={{ border: 1, fontWeight: 520 }}>
              Total Number Of Product Or Services Provided
            </TableCell>
            <TableCell sx={{ border: 1, fontWeight: 520 }}>
              Total Assets
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key={`${1}`}>
            <TableCell sx={{ border: 1 }}>
              {answer?.totalNumberOfOperations}
            </TableCell>
            <TableCell sx={{ border: 1 }}>{answer?.netSales}</TableCell>
            <TableCell sx={{ border: 1 }}>
              {answer?.totalNumberOfProductOrServicesProvided}
            </TableCell>
            <TableCell sx={{ border: 1 }}>{answer?.totalAssets}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
  const renderMarketReservedTable = (answer) => (
    <TableContainer
      sx={{ marginBottom: 3, borderRadius: 1, overflow: "hidden" }}
    >
      <Table sx={{ borderRadius: 1 }}>
        <TableHead>
          {/* Check if category exists and conditionally render headers */}
          <TableRow sx={{ backgroundColor: "#d0c5ca" }}>
            <TableCell sx={{ border: 1, fontWeight: 520 }}>
              Market Served
            </TableCell>
            <TableCell sx={{ border: 1, fontWeight: 520 }}>
              Graphic BreakDown
            </TableCell>
            <TableCell sx={{ border: 1, fontWeight: 520 }}>
              Sectors Served
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {answer.map((ans, riskIdx) => (
            <TableRow key={`${riskIdx}`}>
              <TableCell sx={{ border: 1 }}>{ans?.marketServed}</TableCell>
              <TableCell sx={{ border: 1 }}>{ans?.graphicBreakDown}</TableCell>
              <TableCell sx={{ border: 1 }}>{ans?.sectorsServed}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
  const renderSupplierTable = (answer) => (
    <TableContainer
      sx={{ marginBottom: 3, borderRadius: 1, overflow: "hidden" }}
    >
      <Table sx={{ borderRadius: 1 }}>
        <TableHead>
          {/* Check if category exists and conditionally render headers */}
          <TableRow sx={{ backgroundColor: "#d0c5ca" }}>
            <TableCell sx={{ border: 1, fontWeight: 520 }}>
              Supplier Name
            </TableCell>
            <TableCell sx={{ border: 1, fontWeight: 520 }}>
              Supplier Type
            </TableCell>
            <TableCell sx={{ border: 1, fontWeight: 520 }}>
              Activities Type
            </TableCell>
            <TableCell sx={{ border: 1, fontWeight: 520 }}>
              Business Type
            </TableCell>
            <TableCell sx={{ border: 1, fontWeight: 520 }}>
              Payment Type
            </TableCell>
            <TableCell sx={{ border: 1, fontWeight: 520 }}>
              Location Type
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {answer.map((ans, riskIdx) => (
            <TableRow key={`${riskIdx}`}>
              <TableCell sx={{ border: 1 }}>{ans?.supplierName}</TableCell>
              <TableCell sx={{ border: 1 }}>{ans?.supplierType}</TableCell>
              <TableCell sx={{ border: 1 }}>{ans?.activitiesType}</TableCell>
              <TableCell sx={{ border: 1 }}>{ans?.businessType}</TableCell>
              <TableCell sx={{ border: 1 }}>{ans?.paymentType}</TableCell>
              <TableCell sx={{ border: 1 }}>{ans?.locationType}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
  const renderDownStreamTable = (answer) => (
    <TableContainer
      sx={{ marginBottom: 3, borderRadius: 1, overflow: "hidden" }}
    >
      <Table sx={{ borderRadius: 1 }}>
        <TableHead>
          {/* Check if category exists and conditionally render headers */}
          <TableRow sx={{ backgroundColor: "#d0c5ca" }}>
            <TableCell sx={{ border: 1, fontWeight: 520 }}>
              DownStream Name
            </TableCell>
            <TableCell sx={{ border: 1, fontWeight: 520 }}>
              DownStream Type
            </TableCell>
            <TableCell sx={{ border: 1, fontWeight: 520 }}>
              Activities Type
            </TableCell>
            <TableCell sx={{ border: 1, fontWeight: 520 }}>
              Business Type
            </TableCell>
            <TableCell sx={{ border: 1, fontWeight: 520 }}>
              Location Type
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {answer.map((ans, riskIdx) => (
            <TableRow key={`${riskIdx}`}>
              <TableCell sx={{ border: 1 }}>{ans?.downstreamName}</TableCell>
              <TableCell sx={{ border: 1 }}>{ans?.downstreamType}</TableCell>
              <TableCell sx={{ border: 1 }}>
                {ans?.downstreamActivityType}
              </TableCell>
              <TableCell sx={{ border: 1 }}>
                {ans?.downstreamBusinessType}
              </TableCell>
              <TableCell sx={{ border: 1 }}>
                {ans?.downstreamLocationType}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
  const renderLawRegulationsTable = (answer) => (
    <TableContainer
      sx={{ marginBottom: 3, borderRadius: 1, overflow: "hidden" }}
    >
      <Table sx={{ borderRadius: 1 }}>
        <TableHead>
          <TableRow sx={{ backgroundColor: "#d0c5ca" }}>
            <TableCell sx={{ border: 1, fontWeight: 520 }}>Sl. No.</TableCell>
            <TableCell sx={{ border: 1, fontWeight: 520 }}>Instance</TableCell>
            <TableCell sx={{ border: 1, fontWeight: 520 }}>
              Reporting period
            </TableCell>
            <TableCell sx={{ border: 1, fontWeight: 520 }}>
              Type of Instance
            </TableCell>
            <TableCell sx={{ border: 1, fontWeight: 520 }}>
              Geographic location
            </TableCell>
            <TableCell sx={{ border: 1, fontWeight: 520 }}>Frequency</TableCell>
            <TableCell sx={{ border: 1, fontWeight: 520 }}>Severity</TableCell>
            <TableCell sx={{ border: 1, fontWeight: 520 }}>Fine paid</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {answer.map((ans, riskIdx) => (
            <TableRow key={`${riskIdx}`}>
              <TableCell sx={{ border: 1 }}>{riskIdx + 1}</TableCell>
              <TableCell sx={{ border: 1 }}>{ans?.instance}</TableCell>
              <TableCell sx={{ border: 1 }}>{ans?.reportingPeriod}</TableCell>
              <TableCell sx={{ border: 1 }}>{ans?.instanceType}</TableCell>
              <TableCell sx={{ border: 1 }}>{ans?.lawLocationType}</TableCell>
              <TableCell sx={{ border: 1 }}>{ans?.frequency}</TableCell>
              <TableCell sx={{ border: 1 }}>{ans?.severity}</TableCell>
              <TableCell sx={{ border: 1 }}>{ans?.finePaid}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderMaterialTopicsTable = (answer) => (
    <TableContainer
      sx={{ marginBottom: 3, borderRadius: 1, overflow: "hidden" }}
    >
      <Table sx={{ borderRadius: 1 }}>
        <TableHead>
          <TableRow sx={{ backgroundColor: "#d0c5ca" }}>
            <TableCell sx={{ border: 1, fontWeight: 520 }}>Sl. No.</TableCell>
            <TableCell sx={{ border: 1, fontWeight: 520 }}>Topic</TableCell>
            <TableCell sx={{ border: 1, fontWeight: 520 }}>Impact</TableCell>
            <TableCell sx={{ border: 1, fontWeight: 520 }}>
              Actual Potential
            </TableCell>
            <TableCell sx={{ border: 1, fontWeight: 520 }}>
              ImpactType
            </TableCell>
            <TableCell sx={{ border: 1, fontWeight: 520 }}>
              Time Frame
            </TableCell>
            <TableCell sx={{ border: 1, fontWeight: 520 }}>
              Affected Resources
            </TableCell>
            <TableCell sx={{ border: 1, fontWeight: 520 }}>Location</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {answer.map((ans, riskIdx) => (
            <TableRow key={`${riskIdx}`}>
              <TableCell sx={{ border: 1 }}>{riskIdx + 1}</TableCell>
              <TableCell sx={{ border: 1 }}>{ans?.materialTopic}</TableCell>
              <TableCell sx={{ border: 1 }}>{ans?.materialImpact}</TableCell>
              <TableCell sx={{ border: 1 }}>{ans?.actualPotential}</TableCell>
              <TableCell sx={{ border: 1 }}>
                {ans?.materialImpactType}
              </TableCell>
              <TableCell sx={{ border: 1 }}>{ans?.materialTimeFrame}</TableCell>
              <TableCell sx={{ border: 1 }}>{ans?.affectedResources}</TableCell>
              <TableCell sx={{ border: 1 }}>{ans?.materialLocation}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderTable = (answer, condition) => {
    if (condition === "table_gri_1") {
      return renderAssetValueTable(answer);
    } else if (condition === "table_gri_2") {
      return renderMarketReservedTable(answer);
    } else if (condition === "table_gri_3") {
      return renderSupplierTable(answer);
    } else if (condition === "table_gri_4") {
      return renderDownStreamTable(answer);
    } else if (condition === "table_gri_5") {
      return renderLawRegulationsTable(answer);
    } else if (condition === "table_gri_6") {
      return renderMaterialTopicsTable(answer);
    } else if (!condition) return renderTCFDTable(answer);
  };

  return (
    <DashboardLayout>
      <Grid container>
        <Box className={classes.buttonContainer}>
          <div className={classes.backContainer} onClick={navigateBack}>
            <ArrowBackIosIcon />
            Back
          </div>
        </Box>

        {/* <Paper className={classes.container}>
          <Grid container>
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: 550, mb: 10 }}>
                {data?.framework} Standards Questionnaire
              </Typography>
            </Grid>
            {filteredData?.length > 0 ? (
              filteredData.map((item, index) => (
                <Grid item xs={12} key={index} sx={{ marginBottom: 4 }}>
                  <Typography mb={5} fontWeight={520}>
                    {index + 1}.&nbsp; {questionsMap[item.question_id]}
                  </Typography>
                  {item.question_type === "table" ? (
                    isTableEmpty(item.answer) ? null : (
                      renderTable(item.answer, item.condition)
                    )
                  ) : (
                    <>
                      <span style={{ fontWeight: 520 }}>Answer: </span>
                      <span style={{ fontWeight: 430 }}>
                        {Array.isArray(item.answer)
                          ? item.answer.join(", ")
                          : item.answer}
                      </span>
                      {item.other && (
                        <div
                          style={{
                            fontWeight: 430,
                            marginLeft: 68,
                            marginTop: 10,
                          }}
                        >
                          {item.other}
                        </div>
                      )}
                    </>
                  )}
                </Grid>
              ))
            ) : (
              <Typography>No answers available</Typography>
            )}
          </Grid>
        </Paper> */}
        <Paper className={classes.container}>
          <Grid container>
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: 550, mb: 10 }}>
                {data?.framework} Standards Questionnaire
              </Typography>
            </Grid>
            {filteredData?.length > 0 ? (
              filteredData.map((item, index) => {
                // Check if table is empty
                const isEmptyTable =
                  item.question_type === "table" && isTableEmpty(item.answer);

                // Don't render anything for empty tables
                if (isEmptyTable === true) return null;

                return (
                  <Grid item xs={12} key={index} sx={{ marginBottom: 4 }}>
                    <Typography mb={5} fontWeight={520}>
                      {index + 1}.&nbsp; {questionsMap[item.question_id]}
                    </Typography>

                    {item.question_type === "table" ? (
                      renderTable(item.answer, item.condition)
                    ) : (
                      <>
                        <span style={{ fontWeight: 520 }}>Answer: </span>
                        <span style={{ fontWeight: 430 }}>
                          {Array.isArray(item.answer)
                            ? item.answer.join(", ")
                            : item.answer}
                        </span>
                        {item.other && (
                          <div
                            style={{
                              fontWeight: 430,
                              marginLeft: 68,
                              marginTop: 10,
                            }}
                          >
                            {item.other}
                          </div>
                        )}
                      </>
                    )}
                  </Grid>
                );
              })
            ) : (
              <Typography>No answers available</Typography>
            )}
          </Grid>
        </Paper>
      </Grid>
    </DashboardLayout>
  );
}

export default ViewQuestionnaire;
