import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../../layouts/DashboardLayout";
import useStyles from "./styles";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CeroButton from "../../../../components/CeroButton";
import { useDispatch, useSelector } from "react-redux";
import {
  approveStandardsQuestionnaire,
  getStandardsQuestionnaire,
  getStandardsQuestionnaireAnswers,
  getStandardsQuestionnaireHistory,
  listAssessmentCycle,
  listFramework,
  resetApproveStandardsQuestionnaire,
  resetSaveStandardsQuestionnaire,
  resetSubmitStandardsQuestionnaire,
  saveStandardsQuestionnaire,
  submitStandardsQuestionnaire,
} from "../../../../redux/actions";
import CeroInput from "../../../../components/CeroInput";
import { getCookie } from "../../../../services/cookie";
import { useSnackbar } from "notistack";
import { STATUS } from "../../../../redux/constants";
import RiskTable from "../RiskTable/RiskTable";
import ResilienceTable from "../RiskTable/ResilienceTable";
import GriQuestionnaire from "../GRI";
import GriEdit from "./GriEdit";
import dayjs from "dayjs";

function EditQuestionnaire() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [year, setYear] = useState(null);
  const [country, setCountry] = useState(null);
  const [selectedValues, setSelectedValues] = useState({});
  const [textAnswers, setTextAnswers] = useState({});
  const [tableData, setTableData] = useState({});

  // const [riskTable, setRiskTable] = useState();
  // const [resilienceTable, setResilienceTable] = useState();
  const [editData, setEditData] = useState(null);
  const [dateAnswers, setDateAnswers] = useState({});
  const location = useLocation();
  const role = getCookie("role");
  const { id } = useParams();

  const riskTableData = [
    {
      category: "Physical Risks",
      risks: [
        {
          risk: "Increased Frequency Of Extreme Weather Events",
          impacts:
            "Supply Chain Disruptions, Facility Damage, Increased Insurance Costs",
          timePeriod: "",
          applicable: "",
          comments: "",
        },
        {
          risk: "Rising Sea Levels",
          impacts:
            "Property Damage, Need For Relocation, Increased Infrastructure Costs",
          timePeriod: "",
          applicable: "",
          comments: "",
        },
        {
          risk: "Changes In Precipitation Patterns",
          impacts: "Water Scarcity, Operational Delays, Crop Yield Impacts",
          timePeriod: "",
          applicable: "",
          comments: "",
        },
        {
          risk: "Heatwaves Or Temperature Increases",
          impacts:
            "Higher Energy Costs, Reduced Productivity, Equipment Overheating",
          timePeriod: "",
          applicable: "",
          comments: "",
        },
      ],
    },
    {
      category: "Transitional Risks",
      risks: [
        {
          risk: "Regulatory changes (e.g., carbon taxes, ESG requirements)",
          impacts:
            "Increased compliance costs, penalties, adjustments in operations",
          timePeriod: "",
          applicable: "",
          comments: "",
        },
        {
          risk: "Market shifts toward low-carbon products",
          impacts:
            "Declining demand for carbon-intensive products, need for innovation",
          timePeriod: "",
          applicable: "",
          comments: "",
        },
        {
          risk: "Technology advancements",
          impacts:
            "Write-off of current assets and systems, capital investment in newer technology",
          timePeriod: "",
          applicable: "",
          comments: "",
        },
        {
          risk: "Investor/consumer pressure for sustainability",
          impacts:
            "Brand reputation risks, reduced investor interest, loss of market share",
          timePeriod: "",
          applicable: "",
          comments: "",
        },
      ],
    },
  ];

  const resilienceTableData = [
    {
      risks: [
        {
          risk: "2°C or Lower Scenario",
          impacts:
            "Transition to a low-carbon economy, stringent regulations, and widespread adoption of sustainable technologies",
          keyRisk: "",
          keyOpportunity: "",
          resilience: "",
          gapsChallenges: "",
        },
        {
          risk: "4°C Scenario",
          impacts:
            "Limited mitigation efforts, leading to significant physical climate impacts",
          keyRisk: "",
          keyOpportunity: "",
          resilience: "",
          gapsChallenges: "",
        },
        {
          risk: "Baseline Scenario",
          impacts:
            "Current trajectory with moderate regulations and gradual climate action",
          keyRisk: "",
          keyOpportunity: "",
          resilience: "",
          gapsChallenges: "",
        },
        {
          risk: "Business-As-Usual Scenario",
          impacts:
            "No significant policy changes or climate action, continued focus on business-as-usual operations",
          keyRisk: "",
          keyOpportunity: "",
          resilience: "",
          gapsChallenges: "",
        },
        {
          risk: "Physical Impact Scenario",
          impacts:
            "Severe physical climate impacts (e.g., massive flooding, wildfires, etc.)",
          keyRisk: "",
          keyOpportunity: "",
          resilience: "",
          gapsChallenges: "",
        },
        {
          risk: "Technological Disruption Scenario",
          impacts:
            "Sudden breakthrough in clean technologies or AI-driven climate solutions",
          keyRisk: "",
          keyOpportunity: "",
          resilience: "",
          gapsChallenges: "",
        },
      ],
    },
  ];
  const assetValueData = {
    totalNumberOfOperations: "",
    netSales: "",
    totalNumberOfProductOrServicesProvided: "",
    totalAssets: "",
  };
  const marketServedTableData = [
    {
      marketServed: "",
      graphicBreakDown: "",
      sectorsServed: "",
    },
  ];

  const supplyChainTableData = [
    {
      supplierName: "",
      supplierType: "",
      activitiesType: "",
      businessType: "",
      paymentType: "",
      locationType: "",
    },
  ];

  const downstreamTableData = [
    {
      downstreamName: "",
      downstreamType: "",
      downstreamActivityType: "",
      downstreamBusinessType: "",
      downstreamLocationType: "",
    },
  ];
  const lawRegulationTableData = [
    {
      instance: "",
      reportingPeriod: "",
      instanceType: "",
      lawLocationType: "",
      frequency: "",
      severity: "",
      finePaid: "",
    },
  ];
  const materialTopicsTableData = [
    {
      materialTopic: "",
      materialImpact: "",
      actualPotential: "",
      materialImpactType: "",
      materialTimeFrame: "",
      affectedResources: "",
      materialLocation: "",
    },
  ];
  const [resilienceTable, setResilienceTable] = useState(resilienceTableData);

  const [riskTable, setRiskTable] = useState(riskTableData);

  const [assetValueTable, setAssetValueTable] = useState(assetValueData);
  const [marketReservedTable, setmarketReservedTable] = useState(
    marketServedTableData
  );
  const [supplyChainTable, setSupplyChainTable] =
    useState(supplyChainTableData);
  const [downstreamTable, setDownstreamTable] = useState(downstreamTableData);
  const [lawRegulationTable, setLawRegulationTable] = useState(
    lawRegulationTableData
  );
  const [materialTopicsTable, setMaterialTopicsTable] = useState(
    materialTopicsTableData
  );

  const materialityQuestions = useSelector((state) => {
    const data = state.emission.getStandardsQuestionnaire.data.questions_list;
    return Array.isArray(data) ? data : [];
  });

  const assessmentList = useSelector(
    (state) => state.assessment.assessmentList.data
  );

  const frameworkList = useSelector(
    (state) => state.listings.frameworkList?.data
  );

  const countryData = useSelector(
    (state) => state.listings.getOrganizationCountry.data.country_data
  );

  const saveQuestionnaire = useSelector(
    (state) => state.assessment.saveStandardsQuestionnaire
  );

  const submitQuestionnaire = useSelector(
    (state) => state.assessment.submitStandardsQuestionnaire
  );

  const approveQuestionnaire = useSelector(
    (state) => state.assessment.approveStandardsQuestionnaire
  );

  const filteredList =
    assessmentList?.filter(
      (item) => item.assessment_year === year && item.country === country
    ) || [];

  const frameworkName = filteredList[0]?.framework;
  const assessment_id = filteredList[0]?.id;
  const countryMap = {};
  let countryOptions = [];
  for (const [key, value] of Object.entries(countryData)) {
    countryMap[value] = key;
    countryOptions = countryOptions.concat({ key: value, value: key });
  }

  const frameworkId = frameworkList.find(
    (item) => item.name === frameworkName
  )?.id;

  useEffect(() => {
    dispatch(listAssessmentCycle());
    dispatch(listFramework());
    // dispatch(getStandardsQuestionnaire(frameworkId));
    dispatch(
      getStandardsQuestionnaire(
        location.state.framework_id || "67580e50d845c48ffba61868",
        assessment_id
      )
    );
    dispatch(
      getStandardsQuestionnaireAnswers({
        framework_id: location.state.framework_id,
        country: location.state.countryCode || location.state.country,
        year: location.state.assessment_year || location.state.year,
        // questionnaire_id: id,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (assessment_id) {
      dispatch(
        getStandardsQuestionnaire(
          location.state.framework_id || "67580e50d845c48ffba61868",
          assessment_id
        )
      );
    }
  }, [assessment_id]);

  const editAnswerData = useSelector(
    (item) => item.assessment.getStandardsQuestionnaireAnswers.data
  );

  useEffect(() => {
    if (saveQuestionnaire.status === STATUS.SUCCESS) {
      enqueueSnackbar("Saved Successfully !", {
        variant: "success",
      });
      dispatch(resetSaveStandardsQuestionnaire());
      navigate("/emissions/standards-questionnaire/list");
    } else if (saveQuestionnaire.status === STATUS.ERROR) {
      enqueueSnackbar(
        saveQuestionnaire.message.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
      dispatch(resetSaveStandardsQuestionnaire());
    }
  }, [saveQuestionnaire, dispatch, enqueueSnackbar]);

  useEffect(() => {
    if (submitQuestionnaire.status === STATUS.SUCCESS) {
      enqueueSnackbar("Submitted Successfully !", {
        variant: "success",
      });
      dispatch(resetSubmitStandardsQuestionnaire());
      navigate("/emissions/standards-questionnaire/list");
    } else if (submitQuestionnaire.status === STATUS.ERROR) {
      enqueueSnackbar(
        submitQuestionnaire.message.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
      dispatch(resetSubmitStandardsQuestionnaire());
    }
  }, [submitQuestionnaire, dispatch, enqueueSnackbar]);

  useEffect(() => {
    if (approveQuestionnaire.status === STATUS.SUCCESS) {
      enqueueSnackbar("Approved Successfully !", {
        variant: "success",
      });
      dispatch(resetApproveStandardsQuestionnaire());
      navigate("/emissions/standards-questionnaire/list");
    } else if (approveQuestionnaire.status === STATUS.ERROR) {
      enqueueSnackbar(
        approveQuestionnaire.message.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
      dispatch(resetApproveStandardsQuestionnaire());
    }
  }, [approveQuestionnaire, dispatch, enqueueSnackbar]);

  useEffect(() => {
    if (editAnswerData.length !== 0) {
      setEditData(editAnswerData.answer);
    }
  }, [editAnswerData]);

  useEffect(() => {
    if (editAnswerData && Object.keys(editAnswerData).length > 0) {
      const { answer, country_code, framework, assessment_year } =
        editAnswerData;

      if (country_code) {
        setCountry(country_code);
      }

      if (assessment_year) {
        setYear(assessment_year);
      }

      setSelectedValues(
        (answer || []).reduce((acc, item) => {
          const isMultiSelect = item.question_type === "multiselect_check_box";

          if (isMultiSelect) {
            // Ensure item.answer is an array, or convert it from a string
            acc[item.question_id] = Array.isArray(item.answer)
              ? item.answer
              : typeof item.answer === "string"
              ? item.answer.split(", ") // Convert string to array
              : []; // Default to an empty array if null/undefined
          } else {
            // For other question types, store the answer directly
            acc[item.question_id] = item.answer || "";
          }

          return acc;
        }, {})
      );

      setTextAnswers(
        (answer || []).reduce((acc, item) => {
          if (item.question_type === "multiselect_check_box" && item.other) {
            acc[`other-${item.question_id}`] = item.other;
          }
          if (item.question_type === "choose_from_option" && item.other) {
            acc[`other-${item.question_id}`] = item.other;
          }
          if (
            item.question_type === "text" ||
            item.question_type === "short_text"
          ) {
            acc[item.question_id] = item.answer;
          }
          return acc;
        }, {})
      );

      setDateAnswers(
        (answer || []).reduce((acc, item) => {
          if (item.other) acc[`other-${item.question_id}`] = item.other;
          if (item.question_type === "date")
            acc[item.question_id] = dayjs(item.answer, "DD/MM/YYYY");
          return acc;
        }, {})
      );

      setTableData(
        (answer || []).reduce((acc, item) => {
          if (item.question_type === "table") {
            acc[item.question_id] = item.answer || {};
          }
          return acc;
        }, {})
      );

      const tableRes = editAnswerData?.answer?.find(
        (item) => item.question_id === "677770f39e7e8a6081643369"
      )?.answer;
      const resilienceTableRes = editAnswerData?.answer?.find(
        (item) => item.question_id === "677770f39e7e8a6081643379"
      )?.answer;
      if (tableRes) setRiskTable(tableRes);
      if (resilienceTableRes) {
        setResilienceTable(resilienceTableRes);
      }
      setAssetValueTable(
        answer?.find((item) => item.condition === "table_gri_1")?.answer ||
          assetValueTable
      );
      setmarketReservedTable(
        answer?.find((item) => item.condition === "table_gri_2")?.answer ||
          marketReservedTable
      );
      setSupplyChainTable(
        answer?.find((item) => item.condition === "table_gri_3")?.answer ||
          supplyChainTable
      );
      setDownstreamTable(
        answer?.find((item) => item.condition === "table_gri_4")?.answer ||
          downstreamTable
      );
      setLawRegulationTable(
        answer?.find((item) => item.condition === "table_gri_5")?.answer ||
          lawRegulationTable
      );
      setMaterialTopicsTable(
        answer?.find((item) => item.condition === "table_gri_6")?.answer ||
          materialTopicsTable
      );
      setDateAnswers(
        (answer || []).reduce((acc, item) => {
          if (item.question_type === "date")
            acc[item.question_id] = dayjs(item.answer, "DD/MM/YYYY");
          return acc;
        }, {})
      );
    }
  }, [editAnswerData]);

  const navigateBack = () => {
    navigate(-1);
  };

  const handleCheckboxChange = (event, questionId) => {
    const { value, checked } = event.target;

    setSelectedValues((prevValues) => {
      const currentValues = Array.isArray(prevValues[questionId])
        ? prevValues[questionId]
        : [];

      const updatedValues = checked
        ? [...currentValues, value]
        : currentValues.filter((item) => item !== value);

      return {
        ...prevValues,
        [questionId]: updatedValues,
      };
    });

    if (value === "Other") {
      setTextAnswers((prevAnswers) => {
        const newAnswers = { ...prevAnswers };
        if (checked) {
          newAnswers[`other-${questionId}`] = "";
        } else {
          delete newAnswers[`other-${questionId}`];
        }
        return newAnswers;
      });
    }
  };

  const handleSingleSelect = (questionId, selectedOption) => {
    setSelectedValues((prevSelectedValues) => ({
      ...prevSelectedValues,
      [questionId]: selectedOption,
    }));

    if (selectedOption === "Other") {
      setTextAnswers((prevAnswers) => ({
        ...prevAnswers,
        [`other-${questionId}`]: "",
      }));
    }
  };

  const handleTextInputChange = (questionId, value) => {
    setTextAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: value,
    }));
  };

  const handleDateInputChange = (questionId, value) => {
    setDateAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: value,
    }));
  };

  const getAnswerList = () => {
    return materialityQuestions.map((question) => {
      const type = question?.question_type?.toLowerCase();
      const questionId = question?.id;
      const condition = question?.condition;

      // Initialize default values
      let answer = null;
      let answerValue = "";
      let otherAnswer = textAnswers?.[`other-${questionId}`] || "";

      // Handle answer based on question type
      switch (type) {
        case "text":
        case "text field":
        case "short_text":
          answer = textAnswers?.[questionId] || "";
          break;

        case "choose_from_option":
        case "choose from options":
          answer = selectedValues?.[questionId] || "";
          if (answer === "Other (please specify):") {
            answerValue = textAnswers?.[`other-${questionId}`] || "";
          }
          break;

        case "multiselect_check_box":
          const selectedOptions = Array.isArray(selectedValues?.[questionId])
            ? selectedValues[questionId]
            : [selectedValues?.[questionId]] || [];
          answer = selectedOptions?.join(", ");
          if (selectedOptions.includes("Other (please specify):")) {
            answerValue = textAnswers?.[`other-${questionId}`] || "";
          }
          break;
        case "date":
          answer = dateAnswers?.[questionId]
            ? dayjs(dateAnswers[questionId]).format("DD/MM/YYYY")
            : "";
          break;
        case "table":
          switch (question.condition) {
            case "table_gri_1":
              answer = assetValueTable;
              break;
            case "table_gri_2":
              answer = marketReservedTable;
              break;
            case "table_gri_3":
              answer = supplyChainTable;
              break;
            case "table_gri_4":
              answer = downstreamTable;
              break;
            case "table_gri_5":
              answer = lawRegulationTable;
              break;
            case "table_gri_6":
              answer = materialTopicsTable;
              break;
            default:
              answer =
                questionId === "677770f39e7e8a6081643369"
                  ? riskTable
                  : resilienceTable;
          }
          break;

        default:
          // Handle any unsupported question types
          answer = null;
      }

      // Construct the answer object
      return {
        question_id: questionId,
        question_name: question?.question,
        question_type: question?.question_type,
        answer,
        ...(answerValue && { answer_value: answerValue }), // Include answer_value if it exists
        ...(otherAnswer && { other: otherAnswer }), // Include "other" if it exists
        condition: condition,
      };
    });
  };

  const requestData = {
    // id: "67580e50d845c48ffba61868",
    id: frameworkId,
    payload: {
      answer_list: getAnswerList(),
      country: country,
      assessment_year: year,
    },
  };

  const answerListData = requestData.payload.answer_list;

  const onSave = () => {
    dispatch(saveStandardsQuestionnaire(requestData));
  };

  const onSubmit = () => {
    dispatch(submitStandardsQuestionnaire(requestData));
  };

  const onApprove = () => {
    dispatch(approveStandardsQuestionnaire(location.state.id));
  };

  return (
    <DashboardLayout>
      <Grid container>
        <Box className={classes.buttonContainer}>
          <div className={classes.backContainer} onClick={navigateBack}>
            <ArrowBackIosIcon />
            Back
          </div>
        </Box>
        <Paper className={classes.questions}>
          <Grid margin={5}>
            <Grid xs={12} display={"flex"} justifyContent={"space-between"}>
              {editAnswerData.framework && (
                <Grid item xs={5}>
                  <Typography
                    style={{
                      alignContent: "center",
                      fontWeight: 430,
                      marginLeft: 10,
                    }}
                  >
                    {/* {editAnswerData.framework} Standards Questionnaire */}
                    {editAnswerData.framework === "GRI"
                      ? "GLOBAL REPORTING INITIATIVE QUESTIONNAIRE"
                      : `${editAnswerData.framework} Standards Questionnaire`}
                  </Typography>
                </Grid>
              )}
              {location.state.approve && role === "sustainability_manager" && (
                <Grid
                  xs={5}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <CeroButton
                    buttonText="Approve"
                    className={classes.approvebutton}
                    onClick={() => {
                      onApprove();
                    }}
                  />
                </Grid>
              )}
            </Grid>
            {frameworkName?.toLowerCase()?.includes("tcfd") ? (
              <Grid mb={20}>
                <div className={classes.questionList}>
                  {materialityQuestions.reduce(
                    (renderedQuestions, question, currentIndex) => {
                      const isIfYesQuestion =
                        question.question.includes("If yes");
                      const previousQuestionId =
                        materialityQuestions[currentIndex - 1]?.id;
                      const isYesSelected =
                        selectedValues[previousQuestionId] === "Yes";

                      if (isIfYesQuestion && !isYesSelected) {
                        return renderedQuestions;
                      }

                      const displayIndex = renderedQuestions.length + 1;

                      renderedQuestions.push(
                        <Grid
                          key={question.id}
                          className={
                            question.question_type === "text" ||
                            question.question_type === "Text Field"
                              ? classes.inputGrid
                              : classes.gridItem
                          }
                        >
                          <Typography
                            fontSize={18}
                            fontWeight={500}
                            variant="h3"
                            paddingLeft={4}
                            paddingBottom={2}
                          >
                            {displayIndex}. {question.question}
                          </Typography>
                          <Box display="flex" paddingLeft={6} flexWrap="wrap">
                            {(question.question_type === "choose_from_option" ||
                              question.question_type ===
                                "Choose from Options") &&
                              question.options.map((option, idx) => (
                                <>
                                  <FormControlLabel
                                    key={idx}
                                    control={
                                      <Checkbox
                                        className={classes.selectCheckbox}
                                        checked={
                                          selectedValues[question.id] === option
                                        }
                                        onChange={() =>
                                          handleSingleSelect(
                                            question.id,
                                            option
                                          )
                                        }
                                        value={option}
                                        sx={{
                                          color: "#5A404E !important",
                                          borderWidth: "none",
                                          "&.Mui-checked": {
                                            color: "#8C2148 !important",
                                          },
                                        }}
                                      />
                                    }
                                    label={option}
                                  />
                                  {selectedValues[question.id] ===
                                    "Other (Please specify)" &&
                                    option === "Other (Please specify)" && (
                                      <TextField
                                        variant="outlined"
                                        placeholder="Please type your Answer"
                                        multiline
                                        rows={3}
                                        value={
                                          textAnswers[`other-${question.id}`] ||
                                          ""
                                        }
                                        onChange={(e) =>
                                          handleTextInputChange(
                                            `other-${question.id}`,
                                            e.target.value
                                          )
                                        }
                                        className={classes.input}
                                      />
                                    )}
                                </>
                              ))}
                            {question.question_type ===
                              "multiselect_check_box" &&
                              question.options.map((option, idx) => (
                                <React.Fragment key={idx}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        className={classes.selectCheckbox}
                                        checked={
                                          selectedValues[question.id]?.includes(
                                            option
                                          ) || false
                                        }
                                        onChange={(e) =>
                                          handleCheckboxChange(e, question.id)
                                        }
                                        value={option}
                                        sx={{
                                          color: "#5A404E !important",
                                          borderWidth: "none",
                                          "&.Mui-checked": {
                                            color: "#8C2148 !important",
                                          },
                                        }}
                                      />
                                    }
                                    label={option}
                                  />
                                  {selectedValues[question.id]?.includes(
                                    "Other (Please specify)"
                                  ) &&
                                    option === "Other (Please specify)" && (
                                      <Box sx={{ width: "100%", mt: 2 }}>
                                        <TextField
                                          variant="outlined"
                                          placeholder="Please type your Answer"
                                          multiline
                                          rows={3}
                                          value={
                                            textAnswers[
                                              `other-${question.id}`
                                            ] || ""
                                          }
                                          onChange={(e) =>
                                            handleTextInputChange(
                                              `other-${question.id}`,
                                              e.target.value
                                            )
                                          }
                                          className={classes.input}
                                        />
                                      </Box>
                                    )}
                                </React.Fragment>
                              ))}
                            {(question.question_type === "text" ||
                              question.question_type === "Text Field") && (
                              <Box minHeight={80} width={"100%"}>
                                <CeroInput
                                  className={classes.textAreaContainer}
                                  rows={3}
                                  placeholder={"Please type your Answer"}
                                  id={`answer-${question.id}`}
                                  name={`answer-${question.id}`}
                                  label="Please type your Answer"
                                  multiline
                                  value={textAnswers[question.id] || ""}
                                  onChange={(e) =>
                                    handleTextInputChange(
                                      question.id,
                                      e.target.value
                                    )
                                  }
                                />
                              </Box>
                            )}

                            {question.question_type === "table" && (
                              <Grid width={"100%"}>
                                {question.instructions &&
                                  question.instructions.map(
                                    (instruction, index) => (
                                      <Typography
                                        paddingLeft={6}
                                        paddingY={1}
                                        key={index}
                                      >
                                        {index + 1}. {instruction}
                                      </Typography>
                                    )
                                  )}
                                {question.id === "677770f39e7e8a6081643369" ? (
                                  <RiskTable
                                    riskTable={riskTable}
                                    setRiskTable={setRiskTable}
                                  />
                                ) : (
                                  <ResilienceTable
                                    resilienceTable={resilienceTable}
                                    setResilienceTable={setResilienceTable}
                                  />
                                )}
                              </Grid>
                            )}
                          </Box>
                        </Grid>
                      );

                      return renderedQuestions;
                    },
                    []
                  )}
                </div>
              </Grid>
            ) : (
              <GriQuestionnaire
                handleCheckboxChange={handleCheckboxChange}
                handleSingleSelect={handleSingleSelect}
                handleTextInputChange={handleTextInputChange}
                textAnswers={textAnswers}
                selectedValues={selectedValues}
                assetValueTable={assetValueTable}
                setAssetValueTable={setAssetValueTable}
                marketReservedTable={marketReservedTable}
                setmarketReservedTable={setmarketReservedTable}
                supplyChainTable={supplyChainTable}
                setSupplyChainTable={setSupplyChainTable}
                downstreamTable={downstreamTable}
                setDownstreamTable={setDownstreamTable}
                lawRegulationTable={lawRegulationTable}
                setLawRegulationTable={setLawRegulationTable}
                materialTopicsTable={materialTopicsTable}
                setMaterialTopicsTable={setMaterialTopicsTable}
                // onDataChange={handleGriDataChange}
                handleDateInputChange={handleDateInputChange}
                dateAnswers={dateAnswers}
              />
            )}
          </Grid>
          <Grid xs={12} className={classes.bottomContainer}>
            <CeroButton
              buttonText="Save"
              className={classes.savebutton}
              variant="outlined"
              onClick={() => {
                onSave();
              }}
            />
            <CeroButton
              buttonText="Submit"
              className={classes.submitbutton}
              onClick={() => {
                onSubmit();
              }}
              disabled={!answerListData || answerListData.length === 0}
            />
          </Grid>
        </Paper>
      </Grid>
    </DashboardLayout>
  );
}

export default EditQuestionnaire;
