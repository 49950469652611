import React, { useState } from "react";
import useStyles from "./styles";

const AssetValueTable = ({ assetValueTable, setAssetValueTable }) => {
  const classes = useStyles();
  // const handleInputChange = (field, value) => {
  //   setAssetValueTable({ ...assetValueTable, [field]: value });
  // };

  const tableData = assetValueTable
    ? assetValueTable
    : [
        {
          totalNumberOfOperations: "",
          netSales: "",
          totalNumberOfProductOrServicesProvided: "",
          totalAssets: "",
        },
      ];

  const handleInputChange = (field, value) => {
    // Maintain array format but only use first item
    setAssetValueTable({ ...tableData, [field]: value });
  };

  return (
    <div style={{ padding: "20px" }}>
      <div
        style={{
          borderRadius: "6px",
          overflow: "hidden",
          border: "1px solid #ddd",
        }}
      >
        <table border="2" style={{ borderCollapse: "collapse", width: "100%" }}>
          <tbody>
            <tr style={{ textAlign: "left" }}>
              <td className={classes.assetQuestions}>
                Total Number of operations
              </td>
              <td>
                <textarea
                  value={assetValueTable?.totalNumberOfOperations}
                  onChange={(e) =>
                    handleInputChange("totalNumberOfOperations", e.target.value)
                  }
                  className={classes.assetValues}
                />
              </td>
            </tr>
            <tr style={{ textAlign: "left" }}>
              <td className={classes.assetQuestions}>
                Net sales (for private sector organizations) or net revenues
                (for public sector organizations) Currency:
              </td>
              <td>
                <textarea
                  value={assetValueTable?.netSales}
                  onChange={(e) =>
                    handleInputChange("netSales", e.target.value)
                  }
                  className={classes.assetValues}
                />
              </td>
            </tr>
            <tr style={{ textAlign: "left" }}>
              <td className={classes.assetQuestions}>
                Total number of product or services provided
              </td>
              <td>
                <textarea
                  value={
                    assetValueTable?.totalNumberOfProductOrServicesProvided
                  }
                  onChange={(e) =>
                    handleInputChange(
                      "totalNumberOfProductOrServicesProvided",
                      e.target.value
                    )
                  }
                  className={classes.assetValues}
                />
              </td>
            </tr>
            <tr style={{ textAlign: "left" }}>
              <td className={classes.assetQuestions}>Total assets</td>
              <td>
                <textarea
                  value={assetValueTable?.totalAssets}
                  onChange={(e) =>
                    handleInputChange("totalAssets", e.target.value)
                  }
                  className={classes.assetValues}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AssetValueTable;
