import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { getCookie } from "../../../services/cookie";

const EmissionTypeChart = ({ data }) => {
  const role = getCookie("role");
  const includedTypes = data && data?.map(item => item.type);

  const filteredData = data && data?.filter((entry) =>
    includedTypes.includes(entry.type),
  );

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  

  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }
    const myChartRef = chartRef.current.getContext("2d");

    const getChartData = () => {
      if (role === "sustainability_manager" || role === "read_only_user" || role === "proxy_sustainability_manager") {
        return filteredData.map((entry) => entry.total_co2e);
      } else {
        return filteredData.map((entry) => entry.total);
      }
    };

    chartInstance.current = new Chart(myChartRef, {
      type: "pie",
      data: {
        labels: filteredData.map((type) =>
        capitalizeFirstLetter(type.type.replace(/_/g, " "))
        ),
        datasets: [
          {
            data: getChartData(),
            backgroundColor: ["#846BB5","#FBCE74", "#6FBFBA", "#C5B9DB", "#F1B2C4","#D291BC","#FFDAB9"],
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: true,
            position: "right",
            labels: {
              usePointStyle: true,
              color: 'black',
              padding: 20,
            },
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                let label = context.raw
                return label;
              },
            },
          },
          datalabels: {
            color: "white",
            font: {
              weight: "bold",
              size: 12,
            },
            formatter: (value, context) => {
              const dataset = context.dataset.data;
              const total = dataset.reduce((acc, data) => acc + data, 0);
              const percentage = ((value / total) * 100).toFixed(0) + "%";
              const showPercent = percentage === "0%" ? "" : percentage;
              return showPercent;
            },
          },
        },
        maintainAspectRatio: false,
        responsive: true,
        aspectRatio: 2,
        layout: {
          padding: {
            bottom: 20,
          },
        },
      },
      plugins: [ChartDataLabels],
    });

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [filteredData, includedTypes]);

  return <canvas ref={chartRef} />;
};

export default EmissionTypeChart;
