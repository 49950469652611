import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid, Typography, Box, Tooltip, Button } from "@mui/material";
import { useSnackbar } from "notistack";
import { months, sampleYear } from "../../../constants";
import { STATUS } from "../../../redux/constants";
import { addStationaryCombustionValidation } from "./schema";
import {
  addStationaryCombustion,
  getEmissionFuelList,
  resetAddCombustionStatus,
} from "../../../redux/actions";
import CeroAutoComplete from "../../../components/CeroAutoComplete";
import CeroButton from "../../../components/CeroButton";
import CeroSelect from "../../../components/CeroSelect";
import CeroInput from "../../../components/CeroInput";
import CeroInfoPair from "../../../components/CeroInfoPair";
import useStyles from "./styles";
import { useNavigate } from 'react-router-dom';
import DownloadIcon from '@mui/icons-material/Download';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import EmissionUploadDrawer from "../../EmissionList/EmissionUploadDrawer";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import UploadIcon from '@mui/icons-material/Upload';
import { uploadEmissionAttachement } from "../../../redux/actions/emission";
import CeroConfirmDrawer from "../../../components/CeroConfirmDrawer";
import { deleteEmissionAttachement } from "../../../redux/actions/emission";
import DeleteIcon from '@mui/icons-material/Delete';
import FileUpload from "../FileUpload";

const AddStationaryCombustionForm = (props) => {
  // console.log('props', props)
  const { onCancel, onDownload, onUpload, onValidate, uploadDisabled } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [selectedFile, setSelectedFile] = useState(null);
  const [filePreviewUrl, setFilePreviewUrl] = useState("");
  

  const isCalculateDone = useSelector(
    (state) => state.emission.addStationaryCombustion.isCalculateDone
  );
  const facilitiesData = useSelector(
    (state) => state.listings.listFacilities.data
  );
  const fuelData = useSelector((state) => state.emission.fuelList.data);
  const customFuelData = useSelector(
    (state) => state.emission.customFuelList.data
  );
  const fuelUnitData = useSelector((state) => state.emission.fuelUnits.data);
  const kgAvailData = useSelector((state) => state.emission.kgAvail.data);
  const ltAvailData = useSelector((state) => state.emission.ltAvail.data);
  const kiloliterAvailData = useSelector((state) => state.emission.kiloliterAvail.data);
  const addEmissionData = useSelector(
    (state) => state.emission.addStationaryCombustion
  );
  const facilitiesList = facilitiesData.map((item) => ({
    key: item.id,
    value: item.name,
  }));
  const fuelList = fuelData.map((item) => ({ id: item.id, label: item.name }));
  const customFuelList = customFuelData.map((item) => ({
    id: item.id,
    label: item.name,
  }));

  const [fuelUnits, setFuelUnits] = useState([]);
  const [dieselFuel, setDieselFuel] = useState();
  const yearList = sampleYear().map((item) => ({
    id: item.key,
    label: item.value,
  }));

  const currentYear = new Date().getFullYear().toString()

  const formik = useFormik({
    initialValues: {
      facility: "",
      year: currentYear,
      month: "",
      emissionType: "",
      fuel: "",
      fuelUnit: "",
      amountOfFuel: null,
    },
    validationSchema: addStationaryCombustionValidation,
    onSubmit: () => { },
  });

  const onConfirmDelete = () => {
    // dispatch(deleteEmissionAttachement(emissionId, selectedFile));
}

const onCancelDelete = () => {
    setSelectedFile('');
    setShowConfrim(false);
}
  
  useEffect(() => {
    dispatch(getEmissionFuelList("stationary_combustion"));
    return () => {
      dispatch(resetAddCombustionStatus());
    };
  }, [dispatch]);

  useEffect(() => {
    setFuelUnits(fuelUnitData.filter(item=>!(['l', 'gal (us)', 'kiloliter'].includes(item.name.toLowerCase()))).map((item) => ({
      key: item.name,
      value: item.name,
    })));
    
  }, [dispatch, fuelUnitData]);

  useEffect(() => {
    const dieselFuelData = fuelData.filter((item) => item.name.toLowerCase()==='diesel');
    if (dieselFuelData.length > 0)
        setDieselFuel(dieselFuelData[0])
  }, [dispatch, fuelData]);

  useEffect(() => {
    var units = fuelUnits
    
    if(dieselFuel && formik.values.fuel === dieselFuel.id ){
      if (!isDiesel){
        units = fuelUnitData.filter(item=>['l', 'gal (us)'].includes(item.name.toLowerCase())).map((item) => ({
          key: item.name,
          value: item.name,
        }))
      // setFuelUnits(fuelUnitData.filter(item=>['l', 'gal (us)'].includes(item.name.toLowerCase())).map((item) => ({
      //   key: item.name,
      //   value: item.name,
      // })));
      setIsDiesel(true);
      // setIsKiloliterRemoved(true);
    }
    }
    else if(ltAvailData.includes(formik.values.fuel)){
      if (!isLtRemoved){
        units = fuelUnitData.filter(item=>item.name.toLowerCase()!=="kg").map((item) => ({
          key: item.name,
          value: item.name,
        }))
      // setFuelUnits(fuelUnitData.filter(item=>item.name.toLowerCase()!=="kg").map((item) => ({
      //   key: item.name,
      //   value: item.name,
      // })))
      // setIsKiloliterRemoved(false);

    }
    }
    else if (formik.values.fuel && !(kgAvailData.includes(formik.values.fuel))){
      if (isDiesel){
        units = fuelUnitData.filter(item=> !(['l', 'gal (us)', 'kg'].includes(item.name.toLowerCase()))).map((item) => ({
          key: item.name,
          value: item.name,
        }))
        // setFuelUnits(fuelUnitData.filter(item=> !(['l', 'gal (us)', 'kg'].includes(item.name.toLowerCase()))).map((item) => ({
        //   key: item.name,
        //   value: item.name,
        // })));
        setIsKgRemoved(true);
        setIsDiesel(false);
        setIsLtRemoved(true);
        // setIsKiloliterRemoved(false);
      }
      else{
        units = fuelUnits.filter(item=>!(['l', 'gal (us)', 'kg'].includes(item.key.toLowerCase())))
      // setFuelUnits(fuelUnits.filter(item=>!(['l', 'gal (us)', 'kg'].includes(item.key.toLowerCase()))))
      setIsKgRemoved(true);
      setIsDiesel(false);
    }
    }
    else{
      if (isDiesel){
        units = fuelUnitData.filter(item=>!(['l', 'gal (us)', 'kiloliter'].includes(item.name.toLowerCase()))).map((item) => ({
          key: item.name,
          value: item.name,
        }))
        // setFuelUnits(fuelUnitData.filter(item=>!(['l', 'gal (us)'].includes(item.name.toLowerCase()))).map((item) => ({
        //   key: item.name,
        //   value: item.name,
        // })));
      setIsDiesel(false);
      setIsLtRemoved(true);
      setIsKiloliterRemoved(true);
      }
      if(isKgRemoved){
        units = fuelUnits.concat({key: "kg", value: "kg"})
        // setFuelUnits(fuelUnits.concat({key: "kg", value: "kg"}))
        setIsKgRemoved(false);
      }
    }
    if(formik.values.fuel && (kiloliterAvailData.includes(formik.values.fuel))){
      if (isKiloliterRemoved){
        units = fuelUnits.concat({key: "kiloliter", value: "kiloliter"})
        // setFuelUnits(fuelUnits.concat({key: "kiloliter", value: "kiloliter"}))
        // setFuelUnits(fuelUnits.filter(item=>!(item.key.toLowerCase() === 'kiloliter')))
        setIsKiloliterRemoved(false)
    }
    }
    else{
      units = units.filter(item=> item.key?.toLowerCase()!='kiloliter').map((item) => ({
        key: item.key,
        value: item.value,
      }))
      setIsKiloliterRemoved(true)
    }
    setFuelUnits(units)
  }, [dispatch, formik.values.fuel]);

  useEffect(() => {
    if (!formik.values.fuel) {
      setFuelUnits([]);
      return;
    }
  
    const selectedFuel = fuelData.find(item => item.id === formik.values.fuel);
  
    if (!selectedFuel) {
      setFuelUnits([]);
      return;
    }
  
    const availableUnits = selectedFuel.units.map(unit => ({
      key: unit, 
      value: unit
    }));
  
    setFuelUnits(availableUnits);
  }, [formik.values.fuel, fuelData]);


  useEffect(() => {
    if (addEmissionData.status === STATUS.SUCCESS) {
      enqueueSnackbar("Stationary combustion added successfully", {
        variant: "success",
      });
      dispatch(resetAddCombustionStatus());
      onCancel();
    } else if (addEmissionData.status === STATUS.ERROR) {
      enqueueSnackbar(addEmissionData.message.message || "We couldn't process your request. Please try again later.", {
        variant: "error",
      });
    }
  }, [addEmissionData, dispatch, enqueueSnackbar, onCancel]);

  useEffect(() => {
    if (!formik.values.fuel) {
      setFuelUnits([]);
      return;
    }
  
    const selectedFuel = fuelData.find(item => item.id === formik.values.fuel);
  
    if (!selectedFuel) {
      setFuelUnits([]);
      return;
    }
  
    const availableUnits = selectedFuel.units.map(unit => ({
      key: unit, 
      value: unit
    }));
  
    setFuelUnits(availableUnits);
  }, [formik.values.fuel, fuelData]);

  const onChangeCustomEmissionType = (e) => {
    formik.handleChange(e);
    formik.setFieldValue("fuel", "");
  };

  const onCalculate = () => {
    const requestData = {
      facility_id: formik.values.facility,
      custom_emission: formik.values.emissionType === "yes",
      year: formik.values.year,
      month: formik.values.month,
      fuel_id: formik.values.fuel,
      unit: formik.values.fuelUnit,
      amount: parseFloat(formik.values.amountOfFuel),
      save: false,
    };
    sessionStorage.setItem('year', requestData.year)
    dispatch(addStationaryCombustion(requestData));
  };

  const onAddStationaryData = () => {
    const requestData = {
      facility_id: formik.values.facility,
      custom_emission: formik.values.emissionType === "yes",
      year: formik.values.year,
      month: formik.values.month,
      fuel_id: formik.values.fuel,
      unit: formik.values.fuelUnit,
      amount: parseFloat(formik.values.amountOfFuel),
      save: true,
      file:selectedFile
    };
    dispatch(addStationaryCombustion(requestData));
  };

   const filterOptions = (options, { inputValue }) => {
     return options.filter((option) =>
       option.label.toLowerCase().startsWith(inputValue.toLowerCase())
     );
   };
   const findFuelOption = (fuelId, options) => {
     return options.find((option) => option.id === fuelId) || null;
   };

   const selectedFuelOption = findFuelOption(
     formik.values.fuel,
     formik.values.emissionType === "yes" ? customFuelList : fuelList
   );

  const [isOpenEmissionTypeUpload, setIsOpenEmissionTypeUpload] = useState(false);
  const [isKgRemoved, setIsKgRemoved] = useState(false);
  const [isLtRemoved, setIsLtRemoved] = useState(false);
  const [isKiloliterRemoved, setIsKiloliterRemoved] = useState(true);
  const [isDiesel, setIsDiesel] = useState(false);
  const [showConfirm, setShowConfrim] = useState(false);
  // const [selectedFile, setSelectedFile] = useState('');
  const onCloseDrawerUpload = () => {
    setIsOpenEmissionTypeUpload(false);
  };


const handleFileUpload = (file) => {
  setSelectedFile(file);
  // dispatch(uploadEmissionAttachement("emissionId", file));
};

const handleFileRemove = () => {
  setSelectedFile(null);
};
  
  return (
    <>
      <div
        className={classes.backContainer}
        
      >
        <div className={classes.backButtonDiv} onClick={() => {
          navigate(-1);
        }}>
        <ArrowBackIosIcon />
        Back
        </div>
        <div className={classes.templateButtonDiv}>
        <CeroButton
              buttonText="Bulk Data Upload"
              className={classes.bulkButton}
              startIcon={<FileUploadIcon />}
              onClick={() => setIsOpenEmissionTypeUpload(true)}
            />
            <CeroButton
              buttonText="Download Template"
              className={classes.bulkButton}
              startIcon={<DownloadIcon />}
              onClick={onDownload}
            />
            </div>
      </div>
      <Container className={classes.container}>
        <Box className={classes.innerContainer}>
          <Typography className={classes.title} variant="h6" component="div">
            Add Stationary Combustion
          </Typography>
          <Box className={classes.topContainer}>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  required
                  id="facility"
                  name="facility"
                  label="Facility"
                  fullWidth
                  options={facilitiesList}
                  selectedValue={formik.values.facility}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.facility && formik.errors.facility}
                />
                <CeroSelect
                  required
                  id="month"
                  name="month"
                  label="Month"
                  fullWidth
                  options={months}
                  selectedValue={formik.values.month}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.month && formik.errors.month}
                />
                {/* <CeroSelect
                required
                id="fuel"
                name="fuel"
                label="Fuel"
                fullWidth
                options={formik.values.emissionType === "yes" ? customFuelList : fuelList}
                selectedValue={formik.values.fuel}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.fuel && formik.errors.fuel}
              /> */}

                <CeroAutoComplete
                  id="fuel"
                  label="Fuel"
                  onChange={(e, value) =>
                    formik.setFieldValue("fuel", value?.id)
                  }
                  onBlur={formik.handleBlur}
                  // error={formik.errors.fuel}
                  options={
                    formik.values.emissionType === "yes"
                      ? customFuelList
                      : fuelList
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.id === value?.id
                  }
                  filterOptions={filterOptions}
                  value={selectedFuelOption}
                  error={formik.touched.fuel && formik.errors.fuel}
                />
                <CeroInput
                  required
                  id="amountOfFuel"
                  name="amountOfFuel"
                  label="Amount of Fuel"
                  value={formik.values.amountOfFuel}
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.amountOfFuel && formik.errors.amountOfFuel
                  }
                  type="number"
                />
              </Grid>
              <Grid item container direction={"column"} md={6} xs={12}>
                <CeroSelect
                  required
                  id="emissionType"
                  name="emissionType"
                  label="Custom Emission Filter"
                  fullWidth
                  options={[
                    { key: "yes", value: "Yes" },
                    { key: "no", value: "No" },
                  ]}
                  selectedValue={formik.values.emissionType}
                  onChange={onChangeCustomEmissionType}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.emissionType && formik.errors.emissionType
                  }
                />
                <CeroAutoComplete
                  id="year"
                  label="Assessment Year"
                  onChange={(e, value) =>
                    formik.setFieldValue("year", value.id)
                  }
                  onBlur={formik.handleBlur}
                  error={formik.errors.year}
                  options={yearList}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={formik.values.year}
                />
                <CeroSelect
                  required
                  id="fuelUnit"
                  name="fuelUnit"
                  label="Fuel Unit"
                  fullWidth
                  options={fuelUnits}
                  selectedValue={formik.values.fuelUnit}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.fuelUnit && formik.errors.fuelUnit}
                  sortOption={true}
                />
              </Grid>
              <Grid item container direction="column" md={6} xs={1}>
                <span className={classes.info}>
                  <Tooltip
                    title="The custom emission filter allow users to tailor their search criteria based on specific emission parameters, helping them
                                find carbon credits that align with their sustainability goals more precisely."
                    classes={{ popper: classes.tooltip }}
                    arrow
                    placement="right-start"
                  >
                    <InfoOutlinedIcon className={classes.infoIcon} />
                  </Tooltip>
                </span>
              </Grid>
            </Grid>
            <CeroButton
              buttonText="Calculate"
              className={classes.buttonPrimary}
              onClick={onCalculate}
              disabled={!formik.dirty || !formik.isValid}
            />
            <CeroButton
              buttonText="Clear"
              variant="outlined"
              className={classes.buttonSecondary}
              onClick={formik.resetForm}
            />
            {isCalculateDone && (
            <Box className={classes.bottomContainer}>
              <Typography
                variant="h6"
                component="h6"
                className={classes.previewTitle}
              >
                Emission Preview
              </Typography>
              <Grid
                container
                direction="row"
                wrap="nowrap"
                justifyContent="space-between"
                spacing={8}
              >
                <Grid item container direction="column" xs={12} md={6}>
                  <CeroInfoPair
                    title={
                      <>
                        CO<sub>2</sub>
                      </>
                    }
                    value={`${addEmissionData.data.co2} tonnes`}
                  />
                  <CeroInfoPair
                    title={
                      <>
                        CH<sub>4</sub>
                      </>
                    }
                    value={`${addEmissionData.data.ch4} tonnes`}
                  />
                  <CeroInfoPair
                    title={
                      <>
                        BioFuel CO<sub>2</sub>
                      </>
                    }
                    value={`${addEmissionData.data.biofuel_co2} tonnes`}
                  />
                </Grid>
                <Grid
                  className={classes.secondResultContainer}
                  item
                  container
                  direction="column"
                  xs={6}
                >
                  <CeroInfoPair
                    title={
                      <>
                        CO<sub>2</sub>e
                      </>
                    }
                    value={`${addEmissionData.data.co2e} tonnes`}
                  />
                  <CeroInfoPair
                    title={
                      <>
                        N<sub>2</sub>O
                      </>
                    }
                    value={`${addEmissionData.data.n2o} tonnes`}
                  />
                  <CeroInfoPair
                    title={"EF"}
                    value={
                      <>
                        {addEmissionData.data.ef} kgCO<sub>2</sub>e/unit
                      </>
                    }
                  />
                  <Typography className={classes.previewItem}></Typography>
                </Grid>
              </Grid>
            </Box>
          )}

          </Box>
        <FileUpload onFileUpload={handleFileUpload} onFileRemove={handleFileRemove} />
        </Box>
        <Box className={classes.buttonContainer}>
          <CeroButton
            buttonText="Add Data"
            disabled={!isCalculateDone || addEmissionData.status === STATUS.RUNNING}
            className={classes.buttonPrimary}
            onClick={() => onAddStationaryData(formik.values)}
          />
          <div>
          <Box>
        </Box>
        
          </div>
        </Box>
        
        <EmissionUploadDrawer
          isOpen={isOpenEmissionTypeUpload}
          onClose={onCloseDrawerUpload}
          onUpload={onUpload}
          onValidate={onValidate}
          uploadDisabled={uploadDisabled}
        />
         {showConfirm && <CeroConfirmDrawer
            isOpen={showConfirm}
            onClose={onCancelDelete}
            onConfirm={onConfirmDelete}
        />}
      </Container>
    </>
  );
}

export default AddStationaryCombustionForm;
