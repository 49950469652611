import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Paper } from "@mui/material";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    LineElement,
    Title,
    Tooltip,
    Legend,
    registerables as registerablesJS
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import useStyles from './styles';
import { getEmissionVsOffset } from '../../../redux/actions';

ChartJS.register(...registerablesJS);
ChartJS.register(
    CategoryScale,
    LinearScale,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const EmissionvsOffset = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    
    const emissionData = useSelector(state => state.dashboard.getEmissionVsOffset?.data);

    useEffect(() => {
        if (!props.refreshContent) {
            dispatch(getEmissionVsOffset(props.filter));
        }
    }, [dispatch, props.filter]);

    // Ensure emissionData is available and is an object
    if (!emissionData || Object.keys(emissionData).length === 0) {
        return <Paper className={classes.container}>Data loading...</Paper>;
    }

    // Prepare the data for the chart
    const years = Object.keys(emissionData).sort(); // Ensure years are in order
    const emissions = years.map(year => emissionData[year]?.emission || 0);
    const offsets = years.map(year => emissionData[year]?.offset || 0);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                align: 'end',
                position: 'bottom',
            },
            title: {
                align: 'start',
                display: true,
                text: 'Carbon Emissions vs. Carbon Offsets Over Time',
                font: { size: 18, family: 'Inter', weight: '400' },
                padding: { bottom: 20 },
            },
        },
        scales: {
            y: {
                title: {
                    display: true,
                    text: 'CO2',
                    font: { size: 12, family: 'Inter', weight: '400' },
                },
            },
            x: {
                ticks: { autoSkip: false },
                title: {
                    display: true,
                    text: 'Duration',
                    font: { size: 12, family: 'Inter', weight: '400' },
                },
            },
        },
    };

    const data = {
        labels: years, 
        datasets: [
            {
                label: "Total Carbon Emissions (tonnes)",
                data: emissions,
                borderColor: "#ec1515",
                backgroundColor: "#000",
            },
            {
                label: "Total Carbon Offset (tonnes)",
                data: offsets,
                borderColor: "#48742c",
                backgroundColor: "#000",
            },
        ],
    };

    return (
        <Paper className={classes.container}>
            <Line options={options} data={data} height={360} width={560} />
        </Paper>
    );
};

export default EmissionvsOffset;
