import { Typography, Container, Box, Grid, Modal } from "@mui/material";
import React, { useState } from "react";
import CeroInfoPair from "../../../components/CeroInfoPair";
import useStyles from "../styles";
import { getCookie } from "../../../services/cookie";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import CeroItemPair from "../../../components/CeroItemPair";
import CeroButton from "../../../components/CeroButton";
import CloseIcon from "@mui/icons-material/Close";
import Download from "../../../assets/images/Download";


const TrainingHoursApproval = (props) => {
  const classes = useStyles();
  const { emissionData, handleCallback, handleReviewCallback } = props;
  const role = getCookie('role');
  const readOnlyMode = (getCookie("read_only_mode") === "disable_edit")
  const showActionButtons = window.location.pathname.includes('/training_hours_per_employee') && (role === "sustainability_manager" || role === "proxy_sustainability_manager" )
  const showVerifyButtons = window.location.pathname.includes('/companies') && role === "auditor"
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleIconClick = (itemId) => {
    handleCallback(itemId)
  };
  
  const handleReviewClick = (itemId) => {
    handleReviewCallback(itemId)
  }

  const handlePdfOpen = () => {
    setIsModalOpen(true);
  };
  
  const handlePdfClose = () => {
    setIsModalOpen(false);
  };

  const handlePdfPopup = () => {
    return (
      <embed
        src={`${emissionData.blob_url}#toolbar=0`}
        type="application/pdf"
        width="100%"
        height="600px"
      />
    );
  };


  return (
    <Container className={classes.mainContainer}>
      {emissionData &&
        Array.isArray(emissionData) &&
        emissionData?.map((item, index) => (
          <Container key={index} className={classes.container}>
              <Box className={classes.trainingAndProgramAction}>
                <CeroInfoPair 
                title="Status:"
                value={item.status === "pending_approval" ? "Pending Approval" : item.status ? item.status : "NA"}
                classes={{
                  container: classes.statusContainer,
                  title: classes.statusTitle,
                  value: item.status === "In Progress" || item.status === "pending_approval" ? classes.Inprogress : classes[item?.status]
                }}
              />
                {showActionButtons && (emissionData.length > 1) && !readOnlyMode && <Grid container spacing={2}>
                  <Grid item xs={6}>
                  {(item.status === "pending_approval" || item.status === "submitted") && 
                 <div className={classes.actionButtons} onClick={() => handleIconClick(item.id)}>
                 <CheckCircleOutlineOutlinedIcon className={classes.icon} />
                 <CeroButton
                 variant="contained"
                 buttonText="Approve"
                 className={classes.approveButton}/></div>}
                </Grid>
                <Grid item xs={6}>
                {item.is_added_by_sustainability_manager === false && (item.status === "pending_approval") && 
                <div className={classes.actionButtons} onClick={() => handleReviewClick(item.id)}>
                <ArrowCircleLeftOutlinedIcon className={classes.icon} />
                <CeroButton
                 variant="contained"
                 buttonText="Review"
                 className={classes.approveButton}/></div>}
                </Grid>
                </Grid>}
                {showVerifyButtons && (emissionData.length > 1) && !readOnlyMode &&<Grid container spacing={2}>
                  <Grid item xs={6}>
                  {(item.status === "approved") && 
                 <div className={classes.actionButtons} onClick={() => handleIconClick(item.id)}>
                 <CheckCircleOutlineOutlinedIcon className={classes.icon} />
                 <CeroButton
                 variant="contained"
                 buttonText="Validate"
                 className={classes.approveButton}/></div>}
                </Grid>
                <Grid item xs={6}>
                {(item.status === "approved") && 
                <div className={classes.actionButtons} onClick={() => handleReviewClick(item.id)}>
                <ArrowCircleLeftOutlinedIcon className={classes.icon} />
                <CeroButton
                 variant="contained"
                 buttonText="Review"
                 className={classes.approveButton}/></div>}
                </Grid>
                </Grid>}
                </Box>
                <Typography variant="subtitle1" className={classes.heading}>
                  Training {index + 1}
                </Typography>
                <CeroItemPair
                  title="Training Title:"
                  value={item.training_title ? item.training_title : "NA"}
                  classes={{
                    container: classes.infoContainer,
                    title: classes.trainingTitle,
                    value: classes.value,
                  }}
                />
                 <CeroItemPair
                  title="Training Date:"
                  value={item.date ? item.date : "NA"}
                  classes={{
                    container: classes.infoContainer,
                    title: classes.trainingTitle,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Training Details:"
                  value={item.training_details ? item.training_details : "NA"}
                  classes={{
                    container: classes.infoContainer,
                    title: classes.trainingTitle,
                    value: classes.value,
                  }}
                />
                {emissionData.blob_url && (
                  <div
                  style={{
                    transform: "scale(0.6)",
                    transformOrigin: "center",
                    position: "absolute",
                    top: 100,
                    right: 100,
                  }}
                  onClick={handlePdfOpen}
                >
                  <Download />
                </div>
                )}
                <CeroItemPair
                   title="Training Method:"
                  value={item.training_methods ? item.training_methods : "NA"}
                  classes={{
                    container: classes.infoContainer,
                    title: classes.trainingTitle,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Total Number of training hours to employees:"
                  value={item.total_training_hours ? item.total_training_hours : "NA"}
                  classes={{
                    container: classes.infoContainer,
                    title: classes.trainingTitle,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Total Number of training hours to Female employees:"
                  value={item.female_training_hours ? item.female_training_hours : "NA"}
                  classes={{
                    container: classes.infoContainer,
                    title: classes.trainingTitle,
                    value: classes.value,
                  }}
                />
                <CeroItemPair
                  title="Total Number of training hours to Male employees:"
                  value={item.male_training_hours ? item.male_training_hours : "NA"}
                  classes={{
                    container: classes.infoContainer,
                    title: classes.trainingTitle,
                    value: classes.value,
                  }}
                />
               
          </Container>
        ))}
        <Modal open={isModalOpen} onClose={handlePdfClose}>
      <Box className={classes.modalContent}>
        <CloseIcon onClick={handlePdfClose} className={classes.closeButton}/>
        {handlePdfPopup()}
      </Box>
    </Modal>
    </Container>
  );
}

export default TrainingHoursApproval;

