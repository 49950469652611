import { Typography, Container, Grid, Box, Modal } from "@mui/material";
import React, { useState } from "react";
import useStyles from "../styles";
import CeroItemPair from "../../../components/CeroItemPair";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import { getCookie } from "../../../services/cookie";
import CeroButton from "../../../components/CeroButton";
import CeroInfoPair from "../../../components/CeroInfoPair";
import Download from "../../../assets/images/Download";
import CloseIcon from "@mui/icons-material/Close";

const EmployeeDiversityApproval = (props) => {
  const classes = useStyles();
  const { emissionData, handleCallback, handleReviewCallback } = props;
  const role = getCookie("role");
  const readOnlyMode = getCookie("read_only_mode") === "disable_edit";
  const showActionButtons =
    window.location.pathname.includes("/pending-approval-details") &&
    (role === "sustainability_manager" ||
      role === "proxy_sustainability_manager");
  const showVerifyButtons =
    window.location.pathname.includes("/companies") && role === "auditor";
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleIconClick = (itemId) => {
    handleCallback(itemId);
  };

  const handleReviewClick = (itemId) => {
    handleReviewCallback(itemId);
  };

  const handlePdfOpen = () => {
    setIsModalOpen(true);
  };

  const handlePdfClose = () => {
    setIsModalOpen(false);
  };
  const handlePdfPopup = () => {
    return (
      <embed
        src={`${emissionData.blob_url}#toolbar=0`}
        type="application/pdf"
        width="100%"
        height="600px"
      />
    );
  };

  return (
    <Container className={classes.maincontainer}>
      {emissionData &&
        emissionData?.gender_distribution?.map((item, index) => (
          <Container className={classes.container}>
            <Grid container spacing={2}>
              {showVerifyButtons && (
                <Box className={classes.salaryRatioAction}>
                  <CeroInfoPair
                    title="Status:"
                    value={
                      item.status === "pending_approval"
                        ? "Pending Approval"
                        : item.status
                        ? item.status
                        : "NA"
                    }
                    classes={{
                      container: classes.statusContainer,
                      title: classes.statusTitle,
                      value:
                        item.status === "In Progress" ||
                        item.status === "pending_approval"
                          ? classes.Inprogress
                          : classes[item?.status],
                    }}
                  />
                  {showVerifyButtons && !readOnlyMode && (
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        {item.status === "approved" && (
                          <div
                            className={classes.actionButtons}
                            onClick={() => handleIconClick(item.id)}
                          >
                            <CheckCircleOutlineOutlinedIcon
                              className={classes.icon}
                            />
                            <CeroButton
                              variant="contained"
                              buttonText="Validate"
                              className={classes.approveButton}
                            />
                          </div>
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        {item.status === "approved" && (
                          <div
                            className={classes.actionButtons}
                            onClick={() => handleReviewClick(item.id)}
                          >
                            <ArrowCircleLeftOutlinedIcon
                              className={classes.icon}
                            />
                            <CeroButton
                              variant="contained"
                              buttonText="Review"
                              className={classes.approveButton}
                            />
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  )}
                </Box>
              )}
              <Grid item xs={6}>
                <Typography className={classes.heading}>
                  Employee Diversity by Gender
                </Typography>
                <>
                  <CeroItemPair
                    title="Percentage of employees identifying as Male:"
                    value={item.male ? item.male : "NA"}
                    classes={{
                      container: classes.infoContainer,
                      title: classes.diversityTitle,
                      value: classes.value,
                    }}
                  />
                  <CeroItemPair
                    title="Percentage of employees identifying as Female:"
                    value={item.female ? item.female : "NA"}
                    classes={{
                      container: classes.infoContainer,
                      title: classes.diversityTitle,
                      value: classes.value,
                    }}
                  />
                  <CeroItemPair
                    title="Percentage of employees identifying as Others:"
                    value={item.other ? item.other : "NA"}
                    classes={{
                      container: classes.infoContainer,
                      title: classes.diversityTitle,
                      value: classes.value,
                    }}
                  />
                </>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <>
                  <Typography className={classes.heading}>
                    Employee Diversity by Age Group
                  </Typography>
                  <CeroItemPair
                    title="Percentage of employees under 30 years:"
                    value={
                      emissionData.age_distribution?.[index]?.under_30
                        ? emissionData.age_distribution?.[index]?.under_30
                        : "NA"
                    }
                    classes={{
                      container: classes.infoContainer,
                      title: classes.diversityTitle,
                      value: classes.value,
                    }}
                  />
                  <CeroItemPair
                    title="Percentage of employees between 30-50 years of age:"
                    value={
                      emissionData.age_distribution?.[index]?.between_30_50
                        ? emissionData.age_distribution?.[index]?.between_30_50
                        : "NA"
                    }
                    classes={{
                      container: classes.infoContainer,
                      title: classes.diversityTitle,
                      value: classes.value,
                    }}
                  />
                  {emissionData.blob_url && (
                    <div
                      style={{
                        transform: "scale(0.6)",
                        transformOrigin: "center",
                        position: "absolute",
                        top: 100,
                        right: 100,
                      }}
                      onClick={handlePdfOpen}
                    >
                      <Download />
                    </div>
                  )}
                  <CeroItemPair
                    title="Percentage of employees over 50 years:"
                    value={
                      emissionData.age_distribution?.[index]?.over_50
                        ? emissionData.age_distribution?.[index]?.over_50
                        : "NA"
                    }
                    classes={{
                      container: classes.infoContainer,
                      title: classes.diversityTitle,
                      value: classes.value,
                    }}
                  />
                </>
              </Grid>
              <Grid item xs={6}>
                <>
                  <Typography className={classes.heading}>
                    Ratio of Basic Salary
                  </Typography>
                  <CeroItemPair
                    title="Basic Salary Ratio of Male employees:"
                    value={
                      emissionData.ratio_salary?.[index]
                        ?.basic_salary_ratio_for_male
                        ? emissionData.ratio_salary?.[index]
                            ?.basic_salary_ratio_for_male
                        : "NA"
                    }
                    classes={{
                      container: classes.infoContainer,
                      title: classes.diversityTitle,
                      value: classes.value,
                    }}
                  />
                  <CeroItemPair
                    title="Basic Salary Ratio of Female employees:"
                    value={
                      emissionData.ratio_salary?.[index]
                        ?.basic_salary_ratio_for_female
                        ? emissionData.ratio_salary?.[index]
                            ?.basic_salary_ratio_for_female
                        : "NA"
                    }
                    classes={{
                      container: classes.infoContainer,
                      title: classes.diversityTitle,
                      value: classes.value,
                    }}
                  />
                </>
              </Grid>
            </Grid>
          </Container>
        ))}
      <Modal open={isModalOpen} onClose={handlePdfClose}>
        <Box className={classes.modalContent}>
          <CloseIcon onClick={handlePdfClose} className={classes.closeButton} />
          {handlePdfPopup()}
        </Box>
      </Modal>
    </Container>
  );
};

export default EmployeeDiversityApproval;
