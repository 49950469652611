import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Container, Grid, Paper } from "@mui/material";
import useStyles from "./styles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CeroDropdown from "../../../components/CeroDropdown";
import { sampleYear } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { dashboardSupplierDataDetails } from "../../../redux/actions";
import CeroTable from "../../../components/CeroTable";

export const columns = [
  {
    columnKey: "industry_name",
    columnId: "industry_name",
    columnHeader: "Industry Type",
    width: "25%",
  },
  {
    columnKey: "company_name",
    columnId: "company_name",
    columnHeader: "Compnay Name",
    width: "20%",
  },
  {
    columnKey: "survey_year",
    columnId: "survey_year",
    columnHeader: "Year",
    width: "15%",
  },
  {
    columnKey: "total_score",
    columnId: "total_score",
    columnHeader: "Score",
    width: "15%",
  },
];

const SupplierIndustriesDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [year, setYear] = useState(null);
  const [industryName, setIndustryName] = useState(null);
  const filter = location.state.filter;

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  const handleIndustryChange = (event) => {
    const selectedIndustry = event.target.value;
    if (selectedIndustry === "") {
      setIndustryName(null);
    } else {
      setIndustryName(selectedIndustry);
    }
  };

  const sustainabilityData = useSelector(
    (state) => state.dashboard.dashboardSupplierDataDetails.data
  );

  const industryTypeOptions = [
    { key: "", value: "All Industries" },
    ...sustainabilityData.map((item) => ({
      key: item.industry_name,
      value: item.industry_name,
    })),
  ];

  useEffect(() => {
    dispatch(dashboardSupplierDataDetails(filter));
  }, [filter]);

  const getSustainabilityData = () => {
    return sustainabilityData
      .filter((item) => {
        const yearMatch = year ? item.survey_year === String(year) : true;
        const industryMatch = industryName
          ? item.industry_name === industryName
          : true;
        return yearMatch && industryMatch;
      })
      .map((item) => ({
        ...item,
        industry_name: item.industry_name,
        company_name: item.company_name,
        survey_year: item.survey_year,
        total_score: item.total_score,
      }));
  };

  return (
    <DashboardLayout>
      <Grid>
        <Container>
          <Box className={classes.header}>
            <Box className={classes.buttonContainer}>
              <div
                className={classes.backContainer}
                onClick={() => navigate(-1)}
              >
                <ArrowBackIosIcon />
                Back
              </div>
            </Box>
            <Grid item xs={2} className={classes.filterContainer} gap={2}>
              <CeroDropdown
                id="year"
                label="Year"
                fullWidth
                options={sampleYear(true)}
                onChange={handleYearChange}
                selectedValue={year}
              />
              <CeroDropdown
                id="industry_name"
                label="Industry Type"
                fullWidth
                options={industryTypeOptions}
                onChange={handleIndustryChange}
                selectedValue={industryName}
              />
            </Grid>
          </Box>
          <Paper className={classes.paper}>
            <CeroTable
              columns={columns}
              data={getSustainabilityData()}
              hasMore={false}
              loading={false}
            />
          </Paper>
        </Container>
      </Grid>
    </DashboardLayout>
  );
};

export default SupplierIndustriesDetails;
