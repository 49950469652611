import React from "react";
import { Container, Paper, Box, Typography } from "@mui/material";
import DashboardLayout from "../../layouts/DashboardLayout";
import useStyles from "./styles";
import { useNavigate } from "react-router-dom";
import CeroButton from "../../components/CeroButton";
import { getCookie } from "../../services/cookie";
import TradeBuy from "./TradeBuy";
import TradeSell from "./TradeSell";
import TradeDashboardInternal from "../TradeDashboardInternal";
import { getUserType } from "../../redux/actions/trade";
import { useDispatch } from "react-redux";

const TradeDashboard = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userType = getCookie("type_trade");

  return (
    <>
      {userType === "null" && (
        <DashboardLayout classes={{ childContainer: classes.childContainer }}>
          <Container className={classes.container}>
            <Paper className={classes.card}>
              <Typography style={{ fontWeight: 500 }}>
                Please select if you want to BUY or SELL Carbon credits
              </Typography>
              <Box>
                <CeroButton
                  className={classes.buttonPrimary}
                  onClick={() => {
                    navigate("/trade/buy");
                    dispatch(getUserType("buyer"))
                  }}
                  buttonText="Buy"
                />
                <CeroButton
                  className={classes.buttonPrimary}
                  onClick={() => {
                    navigate("/trade/sell");
                    dispatch(getUserType("seller"))
                  }}
                  buttonText="Sell"
                />
              </Box>
            </Paper>
          </Container>
        </DashboardLayout>
      )}
      {userType === "buyer" && <TradeBuy />}
      {userType === "seller" && <TradeSell />}
      {userType === "internal" && <TradeDashboardInternal />}
    </>
  );
};

export default TradeDashboard;
