import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import Login from "../pages/Login";
import Signup from "../pages/Signup";
import ForgotPassword from "../pages/ForgotPassword";
import Dashboard from "../pages/Dashboard";
import Goals from "../pages/Goals";
import Simulations from "../pages/Simulations";
import Emissions from "../pages/EmissionList";
import Settings from "../pages/Settings";
import ChangePassword from "../pages/ChangePassword";
import Benchmarking from "../pages/Benchmarking";
import Reports from "../pages/Reports";
import Help from "../pages/Help";
import Profile from "../pages/Profile";
import AddEmissions from "../pages/AddEmissions";
import EditEmissions from "../pages/EditEmissions";
import EmissionsDetails from "../pages/EmissionsDetails";
import Facilities from "../pages/Facilities";
import Users from "../pages/Users";
import AuditSummaryYearly from "../pages/AuditSummaryYearly";
import ApprovalStatus from "../pages/ApprovalStatus";
import MonthlyFacilityDetails from "../pages/ApprovalStatus/MonthlyFacilityDetails";
import CurrentYearApproval from "../pages/CurrentYearApproval";
import CompanyList from "../pages/CompanyList";
import CompanyDetails from "../pages/CompanyDetails";
import AuditorDashboard from "../pages/AuditorDashboard";
import AuditDetails from "../pages/CompanyDetails/AuditDetails";
import Tickets from "../pages/Tickets";
import TicketsDetails from "../pages/Tickets/TicketDetails";
import { rolesEnum, sideMenuItems } from "../layouts/DashboardLayout/pages";
import ReportDetails from "../pages/Reports/ReportDetails";
import UserInviteLogin from "../pages/UserInviteLogin";
import QualitativeData from "../pages/QualitativeData";
import AssessmentDetails from "../pages/AssessmentDetails";
import AssessmentYearlyDetails from "../pages/AssessmentDetails/AssessmentYearlyDetails";
import SignupResponse from "../pages/Signup/SignupResponse/index";
import EmissionEsg from "../pages/EmissionList/EmissionEsg";
import AuditorManagement from "../pages/AuditorManagement";
import UploadHistoryDetails from "../pages/UploadHistoryDetails";
import SuperAdminDashboard from "../pages/SuperAdminDashboard";
import SuperAdminUsers from "../pages/SuperAdminUsers";
import SuperAdminCompanies from "../pages/SuperAdminCompanies";
import CompanyDetail from "../pages/SuperAdminCompanies/CompanyDetail";
import CompanyFacilityDetailsTable from "../pages/SuperAdminCompanies/CountriesTable/CompanyFacilityDetailsTable";
import CompanyUserDetailsTable from "../pages/SuperAdminCompanies/UsersTable/CompanyUserDetailsTable";
import UserDetailsTable from "../pages/SuperAdminUsers/UsersDetailTable";
import ReadOnlyUsers from "../pages/ReadOnlyUsers";
import StakeholderCompany from "../pages/StakeholderCompany";
import { getCookie } from "../services/cookie";
import StakeholderReports from "../pages/StakeholderReports";
import StakeholderReportDetails from "../pages/StakeholderReports/ReportDetails";
import ReadOnlyUsersDetails from "../pages/ReadOnlyUsers/ReadOnlyUsersDetials";
import RequestApproval from "../pages/RequestApproval";
import ApprovalRequestDetails from "../pages/RequestApproval/ApprovalRequestDetails";
import ApprovalTable from "../pages/RequestApproval/ApprovalTable";
import ApprovalStatusTable from "../pages/ApprovalStatus/MonthlyFacilityDetails/ApprovalStatusTable";
import EditBoardDiversity from "../pages/EditEmissions/EditBoardDiversity";
import EditManagementDiversity from "../pages/EditEmissions/EditManagementDiversity";
import AuditTypeManagement from "../pages/AuditTypeManagement";
import ApprovalStatusDetails from "./../pages/ApprovalStatus/MonthlyFacilityDetails/ApprovalStatusDetails/index";
import PendingApprovalDetails from "../pages/RequestApproval/ApprovalRequestDetails/PendingApprovalDetails";
import TwoFaOtpValidation from "../pages/TwoFA";
import VisionAndMission from "../pages/Goals/VisionAndMission";
import ReportEmissionList from "../pages/Reports/ReportDetails/EmissionList";
import ReportEmissionDetails from "../pages/Reports/ReportDetails/ReportEmissionDetails";
import InitiativeTracking from "../pages/Goals/InitiativeTracking";
import InitiativeTrackingDetails from "../pages/Goals/InitiativeTracking/InitiativeTrackingDetails";
import Initiatives from "../pages/Goals/Initiatives";
import ViewInitiative from "../pages/Goals/Initiatives/ViewInitiative/index.";
import TargetVsActuals from "../pages/Goals/TargetVsActuals";
import { useSnackbar } from "notistack";
import { STATUS } from "../redux/constants";
import DrilledDashboard from "../pages/Dashboard/DrilledDashboard";
import Glossary from "../pages/Glossary";
import AdminTwoFaOtpValidation from "../pages/AdminTwoFA";
import SubscriptionDetails from "../pages/SubscriptionDetails";
import SubscriptionPlans from "../pages/SuperAdminCompanies/SubscriptionPlans";
import CarbonCreditPool from "../pages/CarbonCreditPool";
import ApprovalWorkFlow from "../pages/ApprovalFlow";
import TradeSignup from "../pages/TradeSignup";
import TradeSignupResponse from "../pages/TradeSignup/TradeSignupResponse";
import LoginTrade from "../pages/TradeLogin";
import TradePrivateRoute from "./TradePrivateRoute";
import TradeDashboard from "../pages/TradeDashboard";
import TradeSell from "../pages/TradeDashboard/TradeSell";
import CarbonCreditsTable from "../pages/TradeDashboard/CarbonCreditsTable";
import TradeBuy from "../pages/TradeDashboard/TradeBuy";
import CarbonCreditsProject from "../pages/TradeDashboard/CarbonCreditsTable/CarbonCreditsProject";
import TradeProfile from "../pages/TradeProfile";
import ProfileSettings from "../pages/TradeProfile/ProfileSettings";
import AddUser from "../pages/Users/AddUser";
import EditUser from "../pages/Users/EditUser";
import BuyDetails from "../pages/TradeDashboard/TradeBuy/BuyDetails";
import TradeDashboardInternal from "../pages/TradeDashboardInternal";
import Survey from "../pages/Materiality/Survey";
import History from "../pages/Materiality/History";
import TrackProgress from "../pages/Materiality/TrackProgress";
import SuperAdminReportDetails from "../pages/SuperAdminCompanies/CompanyDetail/SuperAdminReports/ReportDetails";
import CompanyEmissionList from "./../pages/CompanyDetails/AuditDetails/CompanyEmissionList/index";
import SuperAdminTickets from "../pages/SuperAdminTickets";
import TicketDetails from "../pages/SuperAdminTickets/TicketsTable/TicketDetails";
import SupportTickets from "../pages/SupportTickets";
import SupportTicketDetails from "../pages/SupportTickets/SupportTicketDetails";
import CarbonOffset from "../pages/CarbonOffset";
import CarbonOffsetDetails from "../pages/CarbonOffset/CarbonOffsetDetails";
import MaterialitySurvey from "../pages/MaterialitySurvey";
import SurveyResponse from "../pages/MaterialitySurvey/SurveyResponse";
import ProgressTable from "../pages/Materiality/TrackProgress/ProgressTable";
import ViewSurvey from "../pages/Materiality/TrackProgress/ProgressTable/ViewSurvey";
import SuperAdminNotifications from "../pages/SuperAdminNotifications";
import AddNotification from "../pages/SuperAdminNotifications/AddNotification";
import EditNotification from "../pages/SuperAdminNotifications/EditNotification";
import BlockchainList from "../pages/Blockchain";
import { Block } from "@mui/icons-material";
import BlockchainDetails from "../pages/Blockchain/BlockchainDetails";
import ActiveSessionsTable from "../pages/SuperAdminCompanies/ActiveSessionsTable";
import CompanyTotalUserDetails from "../pages/SuperAdminCompanies/ActiveSessionsTable/CompanyTotalUserDetails";
import SupplierScreeningSurvey from "../pages/SupplierScreening/SupplierScreeningSurvey";
import SupplierTrackProgress from "../pages/SupplierScreening/SupplierTrackProgress";
import SupplierScreeningResponse from "../pages/SupplierScreening/SupplierScreeningResponse";
import DashboardConfiguration from "../pages/DashboardConfiguration";
import SupplierHistory from "../pages/SupplierScreening/SupplierHistory";
import SupplierProgressTable from "../pages/SupplierScreening/SupplierTrackProgress/ProgressTable";
import SupplierViewSurvey from "../pages/SupplierScreening/SupplierTrackProgress/ProgressTable/ViewSurvey";
import HistoryDetails from "../pages/SupplierScreening/SupplierHistory/History";
import OffsetApprovalStatus from "../pages/OffsetApprovals/ApprovalStatus";
import MonthlyProgramDetails from "../pages/OffsetApprovals/ApprovalStatus/MonthlyProgramDetails";
import OffsetRequestApproval from "../pages/OffsetApprovals/RequestApproval";
import MonthlyProgramDetailsPending from "../pages/OffsetApprovals/RequestApproval/MonthlyProgramDetails";
import SurveyResult from "../pages/SupplierScreening/SurveyResult";
import CarbonOffsetCompanyDetails from "../pages/CompanyDetails/AuditDetails/CarbonOffsetCompanyDetails";
import FunctionalitySetup from "../pages/SuperAdminCompanies/CompanyDetail/FunctionalitySetup";
import FunctionalityView from "../pages/ApprovalFlow/FunctionalityView";
import ProductionDataList from "../pages/ProductionDataList";
import ProductionDataAdd from "../pages/AddProductionData";
import AuditHistory from "../pages/AuditTypeManagement/AuditHistory";
import SustainabilityRiskAssessmentSurvey from "../pages/SustainabilityRiskAssessment/SustainabilityRiskAssessmentSurvey";
import SustainabilityRiskAssessmentTrackProgress from "../pages/SustainabilityRiskAssessment/SustainabilityRiskAssessmentProgress";
import SustainabilityRiskAssessmentProgressTable from "../pages/SustainabilityRiskAssessment/SustainabilityRiskAssessmentProgress/ProgressTable";
import SustainabilityRiskAssessmentViewSurvey from "../pages/SustainabilityRiskAssessment/SustainabilityRiskAssessmentProgress/ProgressTable/ViewSurvey";
import SustainabilityRiskAssessmentHistory from "../pages/SustainabilityRiskAssessment/SustainabilityRiskAssessmentHistory";
import SustainabilityRiskAssessmentHistoryDetails from "../pages/SustainabilityRiskAssessment/SustainabilityRiskAssessmentHistory/History";
import SustainabilityRiskAssessmentSurveyResult from "../pages/SustainabilityRiskAssessment/SustainabilityRiskAssessmentResult";
import SustainabilityRiskAssessmentResponse from "../pages/SustainabilityRiskAssessment/SustainabilityRiskAssessmentResponse";
import MaterialityInsights from "../pages/Materiality/Insights";
import InsightsDetails from "../pages/Materiality/InsightsDetails";
import AdminNotifications from "../pages/AdminNotifications";
import AddAdminNotification from "../pages/AdminNotifications/AddNotification";
import EditAdminNotification from "../pages/AdminNotifications/EditNotification";
import ViewChangeLog from "../pages/AdminNotifications/ViewChangeLog";
import StandardsQuestionnaire from "../pages/EmissionList/StandardsQuestionnaire";
import StandardsQuestionnaireTable from "../pages/EmissionList/StandardsQuestionnaire/StandardsQuestionnaireTable";
import ViewQuestionnaire from "../pages/EmissionList/StandardsQuestionnaire/ViewQuestionnaire";
import EditQuestionnaire from "../pages/EmissionList/StandardsQuestionnaire/EditQuestionnaire";
import TradePublicRoute from "./TradePublicRoute";
import RiskAssessmentDetails from "../pages/Dashboard/RiskAssessmentDetails";
import SupplierIndustriesDetails from "../pages/Dashboard/SupplierIndustriesDetails";

const RootNavigation = () => {
  const role = useSelector((state) => state.auth.role);
  let isAuthSuperAdmin = useSelector(
    (state) => state.auth.userInfo.access_token
  );
  let auth = useSelector((state) => state.auth);
  // const redirectLink = sideMenuItems.find(item => item.roles.some(userRole => role.includes(userRole)))?.path;
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const cookieRole = getCookie("role");
  let redirectLink;
  if (cookieRole.includes("super_admin") && isAuthSuperAdmin) {
    redirectLink = "/dashboard";
  } else if (cookieRole.includes("super_admin")) {
    redirectLink = "twofa";
  } else if (cookieRole.includes("read_only_user")) {
    redirectLink = "/company";
  } else {
    redirectLink = sideMenuItems.find((item) =>
      item.roles.some((userRole) => role.includes(userRole))
    )?.path;
  }

  const allRoles = Object.keys(rolesEnum).map((item) => rolesEnum[item]);

  const isSuperAdmin = useSelector((state) =>
    state.auth.role.includes("super_admin")
  );
  const is2FAEnabled = useSelector(
    (state) => state.auth.userInfo.is_two_factor_auth_enabled
  );

  const proxySustainabilityManagerLoginStatus = useSelector(
    (state) => state.auth.proxySustainabilityManagerLogin
  );
  useEffect(() => {
    if (proxySustainabilityManagerLoginStatus === STATUS.ERROR) {
      enqueueSnackbar(
        auth.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
    }
  }, [proxySustainabilityManagerLoginStatus, dispatch, enqueueSnackbar]);
  return (
    <BrowserRouter>
      <Suspense fallback={<div>Loading</div>}>
        <Routes>
          <Route
            path="/"
            exact
            element={
              <PublicRoute redirectTo={redirectLink}>
                <Login />
              </PublicRoute>
            }
          />
          <Route
            path="/login/:userType/:userId/:userToken"
            element={
              <PublicRoute redirectTo={redirectLink}>
                <UserInviteLogin />
              </PublicRoute>
            }
          />
          <Route
            path="/login/:userType"
            element={
              <PublicRoute redirectTo={redirectLink}>
                <Login />
              </PublicRoute>
            }
          />
          <Route
            path="/signup/"
            element={
              <PublicRoute redirectTo="/signup-response">
                <Signup />
              </PublicRoute>
            }
          />
          <Route
            path="/materiality/survey/:answerId"
            element={
              <PublicRoute redirectTo={redirectLink}>
                <MaterialitySurvey />
              </PublicRoute>
            }
          />
          <Route
            path="/supplier-assessment/survey/:answerId"
            element={
              <PublicRoute redirectTo={redirectLink}>
                <SupplierScreeningResponse />
              </PublicRoute>
            }
          />
          <Route
            path="/supplier-assessment/survey-response"
            element={
              <PublicRoute redirectTo={redirectLink}>
                <SurveyResponse />
              </PublicRoute>
            }
          />
          <Route
            path="/materiality/survey-response"
            element={
              <PublicRoute redirectTo={redirectLink}>
                <SurveyResponse />
              </PublicRoute>
            }
          />
          <Route path="/signup-response" element={<SignupResponse />} />
          <Route
            path="/change-password"
            element={
              <PrivateRoute redirectTo="/" rolesAllowed={allRoles}>
                <ChangePassword />
              </PrivateRoute>
            }
          />

          {!role.includes(rolesEnum.AUDITOR) &&
            !role.includes(rolesEnum.SUPER_ADMIN) &&
            !role.includes(rolesEnum.STAKEHOLDER) && (
              <Route
                path="/dashboard"
                element={
                  <PrivateRoute
                    redirectTo="/"
                    rolesAllowed={[
                      rolesEnum.ADMIN,
                      rolesEnum.SUSTAINABILITY_MANAGER,
                      rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                      rolesEnum.FACILITY_MANAGER,
                      rolesEnum.BUSINESS_USER,
                      rolesEnum.APPROVER,
                    ]}
                  >
                    <Dashboard />
                  </PrivateRoute>
                }
              />
            )}
          <Route
            path="/dashboard/details/:type"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                  rolesEnum.STAKEHOLDER,
                ]}
              >
                <DrilledDashboard />
              </PrivateRoute>
            }
          />
             <Route
            path="/dashboard/details/risk-assessment"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                  rolesEnum.STAKEHOLDER,
                ]}
              >
                <RiskAssessmentDetails />
              </PrivateRoute>
            }
          />
                 <Route
            path="/dashboard/details/supplier-industries"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                  rolesEnum.STAKEHOLDER,
                ]}
              >
                <SupplierIndustriesDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/audit-status/"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                ]}
              >
                <AuditSummaryYearly />
              </PrivateRoute>
            }
          />
          <Route
            path="/auditor-management/"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                ]}
              >
                <AuditorManagement />
              </PrivateRoute>
            }
          />
          <Route
            path="/audit-type-management/"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                ]}
              >
                <AuditTypeManagement />
              </PrivateRoute>
            }
          />
          <Route
            path="/audit-type-management/history/"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                ]}
              >
                <AuditHistory />
              </PrivateRoute>
            }
          />
          <Route
            path="/audit-status/current-year-approval/:year"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                ]}
              >
                <CurrentYearApproval />
              </PrivateRoute>
            }
          />
          <Route
            path="/audit-status/audit-summary/"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                ]}
              >
                <CurrentYearApproval />
              </PrivateRoute>
            }
          />
          <Route
            path="/audit-status/qualitative-data/:id"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                ]}
              >
                <QualitativeData />
              </PrivateRoute>
            }
          />
          <Route
            path="/approval-status/:year"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.ADMIN,
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                  rolesEnum.FACILITY_MANAGER,
                  rolesEnum.BUSINESS_USER,
                  rolesEnum.APPROVER,
                ]}
              >
                <MonthlyFacilityDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/approval-status/"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.ADMIN,
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                  rolesEnum.FACILITY_MANAGER,
                  rolesEnum.BUSINESS_USER,
                  rolesEnum.APPROVER,
                ]}
              >
                <ApprovalStatus />
              </PrivateRoute>
            }
          />
          <Route
            path="/approval-status-table/:type"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.ADMIN,
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                  rolesEnum.FACILITY_MANAGER,
                  rolesEnum.BUSINESS_USER,
                  rolesEnum.APPROVER,
                ]}
              >
                <ApprovalStatusTable />
              </PrivateRoute>
            }
          />
          <Route
            path="/request-approvals/"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[rolesEnum.BUSINESS_USER]}
              >
                <RequestApproval />
              </PrivateRoute>
            }
          />
          <Route
            path="/pending-approvals/"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.ADMIN,
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                  rolesEnum.FACILITY_MANAGER,
                  rolesEnum.APPROVER,
                  rolesEnum.BUSINESS_USER,
                ]}
              >
                <RequestApproval />
              </PrivateRoute>
            }
          />
          <Route
            path="/request-approvals/:year"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[rolesEnum.BUSINESS_USER]}
              >
                <ApprovalRequestDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/pending-approvals/:year"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.ADMIN,
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                  rolesEnum.FACILITY_MANAGER,
                  rolesEnum.BUSINESS_USER,
                  rolesEnum.APPROVER,
                ]}
              >
                <ApprovalRequestDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/request-approvals/:id/:subType/:type"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[rolesEnum.BUSINESS_USER]}
              >
                <ApprovalTable />
              </PrivateRoute>
            }
          />
          <Route
            path="/pending-approvals/:id/:subType/:type"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.ADMIN,
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                  rolesEnum.FACILITY_MANAGER,
                  rolesEnum.BUSINESS_USER,
                  rolesEnum.APPROVER,
                ]}
              >
                <ApprovalTable />
              </PrivateRoute>
            }
          />
          <Route
            path="/pending-approval-details/:id/:subType/:type"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.ADMIN,
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                  rolesEnum.FACILITY_MANAGER,
                  rolesEnum.BUSINESS_USER,
                  rolesEnum.APPROVER,
                ]}
              >
                <PendingApprovalDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/approval-status-details/:id/:subType/:type"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.ADMIN,
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                  rolesEnum.FACILITY_MANAGER,
                  rolesEnum.BUSINESS_USER,
                  rolesEnum.APPROVER,
                ]}
              >
                <ApprovalStatusDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/emissions/:type"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.FACILITY_MANAGER,
                  rolesEnum.BUSINESS_USER,
                  rolesEnum.APPROVER,
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                  rolesEnum.ADMIN,
                  rolesEnum.AUDITOR,
                ]}
              >
                <Emissions />
              </PrivateRoute>
            }
          />
          <Route
            path="/emissions"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.FACILITY_MANAGER,
                  rolesEnum.BUSINESS_USER,
                  rolesEnum.APPROVER,
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                ]}
              >
                <Emissions />
              </PrivateRoute>
            }
          />
          <Route
            path="/emissions/emission-esg"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.FACILITY_MANAGER,
                  rolesEnum.BUSINESS_USER,
                  rolesEnum.APPROVER,
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                ]}
              >
                <EmissionEsg />
              </PrivateRoute>
            }
          />
          <Route
            path="/emissions/emission-esg/:type"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.FACILITY_MANAGER,
                  rolesEnum.BUSINESS_USER,
                  rolesEnum.APPROVER,
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                ]}
              >
                <EmissionEsg />
              </PrivateRoute>
            }
          />
          <Route
            path="/emissions/add/:type"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.FACILITY_MANAGER,
                  rolesEnum.BUSINESS_USER,
                  rolesEnum.APPROVER,
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                ]}
              >
                <AddEmissions />
              </PrivateRoute>
            }
          />
          <Route
            path="/emissions/edit/:type/:id"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.FACILITY_MANAGER,
                  rolesEnum.BUSINESS_USER,
                  rolesEnum.APPROVER,
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                ]}
              >
                <EditEmissions />
              </PrivateRoute>
            }
          />
          <Route
            path="/business/emissions/management_diversity/:id"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.FACILITY_MANAGER,
                  rolesEnum.BUSINESS_USER,
                  rolesEnum.APPROVER,
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                ]}
              >
                <EditManagementDiversity />
              </PrivateRoute>
            }
          />
          <Route
            path="/business/emissions/board_diversity/:id"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.FACILITY_MANAGER,
                  rolesEnum.BUSINESS_USER,
                  rolesEnum.APPROVER,
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                ]}
              >
                <EditBoardDiversity />
              </PrivateRoute>
            }
          />
          <Route
            path="/emissions/:type/details/:id"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.FACILITY_MANAGER,
                  rolesEnum.BUSINESS_USER,
                  rolesEnum.APPROVER,
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                  rolesEnum.ADMIN,
                  rolesEnum.AUDITOR,
                ]}
              >
                <EmissionsDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/emissions/:type/uploadhistory"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.FACILITY_MANAGER,
                  rolesEnum.BUSINESS_USER,
                  rolesEnum.APPROVER,
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                  rolesEnum.ADMIN,
                  rolesEnum.AUDITOR,
                ]}
              >
                <UploadHistoryDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/goals"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                  rolesEnum.FACILITY_MANAGER,
                ]}
              >
                <Goals />
              </PrivateRoute>
            }
          />
          <Route
            path="/vision-mission"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                  rolesEnum.FACILITY_MANAGER,
                ]}
              >
                <VisionAndMission />
              </PrivateRoute>
            }
          />
          <Route
            path="/initiatives"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                  rolesEnum.FACILITY_MANAGER,
                ]}
              >
                <Initiatives />
              </PrivateRoute>
            }
          />
          <Route
            path="/initiatives/:type"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                  rolesEnum.FACILITY_MANAGER,
                ]}
              >
                <Initiatives />
              </PrivateRoute>
            }
          />
          <Route
            path="/initiatives/viewinitiatives"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                  rolesEnum.FACILITY_MANAGER,
                ]}
              >
                <ViewInitiative />
              </PrivateRoute>
            }
          />
          <Route
            path="/initiative-tracking"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                  rolesEnum.FACILITY_MANAGER,
                ]}
              >
                <InitiativeTracking />
              </PrivateRoute>
            }
          />
          <Route
            path="/initiative-tracking/details/:id"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                  rolesEnum.FACILITY_MANAGER,
                ]}
              >
                <InitiativeTrackingDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/target-vs-actuals"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                  rolesEnum.FACILITY_MANAGER,
                ]}
              >
                <TargetVsActuals />
              </PrivateRoute>
            }
          />
          <Route
            path="/survey"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                ]}
              >
                <Survey />
              </PrivateRoute>
            }
          />
          <Route
            path="/track-progress"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                ]}
              >
                <TrackProgress />
              </PrivateRoute>
            }
          />
          <Route
            path="/track-progress/:id"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                ]}
              >
                <ProgressTable />
              </PrivateRoute>
            }
          />
          <Route
            path="/track-progress/:id/response/:answerId"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                ]}
              >
                <ViewSurvey />
              </PrivateRoute>
            }
          />
          <Route
            path="/history"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                ]}
              >
                <TrackProgress />
              </PrivateRoute>
            }
          />
          <Route
            path="/history/:id"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                ]}
              >
                <History />
              </PrivateRoute>
            }
          />
          <Route
            path="/materiality-insights"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                ]}
              >
                <MaterialityInsights />
              </PrivateRoute>
            }
          />
          <Route
            path="/insights-details/:id"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                ]}
              >
                <InsightsDetails />
              </PrivateRoute>
            }
          />

          <Route
            path="/supplier-screening/survey/"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                ]}
              >
                <SupplierScreeningSurvey />
              </PrivateRoute>
            }
          />
          <Route
            path="/supplier-screening/track-progress"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                ]}
              >
                <SupplierTrackProgress />
              </PrivateRoute>
            }
          />
          <Route
            path="/supplier-screening/track-progress/:id"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                ]}
              >
                <SupplierProgressTable />
              </PrivateRoute>
            }
          />
          <Route
            path="/supplier-screening/track-progress/:id/response/:answerId"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                ]}
              >
                <SupplierViewSurvey />
              </PrivateRoute>
            }
          />
          <Route
            path="/supplier-screening/view-survey/:id"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                ]}
              >
                <SupplierViewSurvey />
              </PrivateRoute>
            }
          />
          <Route
            path="/supplier-screening/history"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                ]}
              >
                <SupplierHistory />
              </PrivateRoute>
            }
          />
          <Route
            path="/supplier-screening/history/:id"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                ]}
              >
                <HistoryDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/simulations"
            element={
              <PrivateRoute redirectTo="/">
                <Simulations />
              </PrivateRoute>
            }
          />
          <Route
            path="/benchmarking"
            element={
              <PrivateRoute redirectTo="/">
                <Benchmarking />
              </PrivateRoute>
            }
          />
          <Route
            path="/reports"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                  rolesEnum.APPROVER,
                  rolesEnum.BUSINESS_USER,
                  rolesEnum.FACILITY_MANAGER,
                  // rolesEnum.STAKEHOLDER
                ]}
              >
                <Reports />
              </PrivateRoute>
            }
          />
          <Route
            path="/reports/details/:id"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                  rolesEnum.APPROVER,
                  rolesEnum.BUSINESS_USER,
                  rolesEnum.FACILITY_MANAGER,
                ]}
              >
                <ReportDetails />
              </PrivateRoute>
            }
          />

          <Route
            path="/reports/list-topic/:type"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.FACILITY_MANAGER,
                  rolesEnum.BUSINESS_USER,
                  rolesEnum.APPROVER,
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                  rolesEnum.ADMIN,
                  rolesEnum.AUDITOR,
                ]}
              >
                <ReportEmissionList />
              </PrivateRoute>
            }
          />
          <Route
            path="/reports/list-topic/:type/details/:id"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.FACILITY_MANAGER,
                  rolesEnum.BUSINESS_USER,
                  rolesEnum.APPROVER,
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                  rolesEnum.ADMIN,
                  rolesEnum.AUDITOR,
                ]}
              >
                <ReportEmissionDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/notifications"
            element={
              <PrivateRoute redirectTo="/" rolesAllowed={[rolesEnum.ADMIN]}>
                <AdminNotifications />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/notifications/add-notifications"
            element={
              <PrivateRoute redirectTo="/" rolesAllowed={[rolesEnum.ADMIN]}>
                <AddAdminNotification />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/notifications/edit/:id"
            element={
              <PrivateRoute redirectTo="/" rolesAllowed={[rolesEnum.ADMIN]}>
                <EditAdminNotification />
              </PrivateRoute>
            }
          />
          <Route
            path="/emissions/standards-questionnaire"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.FACILITY_MANAGER,
                  rolesEnum.BUSINESS_USER,
                  rolesEnum.APPROVER,
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                  rolesEnum.ADMIN,
                  rolesEnum.AUDITOR,
                ]}
              >
                <StandardsQuestionnaire />
              </PrivateRoute>
            }
          />
          <Route
            path="/emissions/standards-questionnaire/list"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.FACILITY_MANAGER,
                  rolesEnum.BUSINESS_USER,
                  rolesEnum.APPROVER,
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                  rolesEnum.ADMIN,
                  rolesEnum.AUDITOR,
                ]}
              >
                <StandardsQuestionnaireTable />
              </PrivateRoute>
            }
          />
          <Route
            path="/emissions/standards-questionnaire/answer/:id"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.FACILITY_MANAGER,
                  rolesEnum.BUSINESS_USER,
                  rolesEnum.APPROVER,
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                  rolesEnum.ADMIN,
                  rolesEnum.AUDITOR,
                ]}
              >
                <ViewQuestionnaire />
              </PrivateRoute>
            }
          />
          <Route
            path="/emissions/standards-questionnaire/:id"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.FACILITY_MANAGER,
                  rolesEnum.BUSINESS_USER,
                  rolesEnum.APPROVER,
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                  rolesEnum.ADMIN,
                  rolesEnum.AUDITOR,
                ]}
              >
                <EditQuestionnaire />
              </PrivateRoute>
            }
          />
          {/* <Route
                path="/admin/notifications/changelog/:id"
                element={
                  <PrivateRoute
                    redirectTo="/"
                    rolesAllowed={[rolesEnum.ADMIN]}
                  >
                    <ViewChangeLog />
                  </PrivateRoute>
                }
              /> */}

          {role.includes(rolesEnum.AUDITOR) && (
            <>
              <Route
                path="/dashboard"
                element={
                  <PrivateRoute
                    redirectTo="/"
                    rolesAllowed={[rolesEnum.AUDITOR]}
                  >
                    <AuditorDashboard />
                  </PrivateRoute>
                }
              />
              <Route
                path="/companies"
                element={
                  <PrivateRoute
                    redirectTo="/"
                    rolesAllowed={[rolesEnum.AUDITOR]}
                  >
                    <CompanyList />
                  </PrivateRoute>
                }
              />
              <Route
                path="/companies/:company/audit/:id"
                element={
                  <PrivateRoute
                    redirectTo="/"
                    rolesAllowed={[rolesEnum.AUDITOR]}
                  >
                    <AuditDetails />
                  </PrivateRoute>
                }
              />
              <Route
                path="/companies/:company/audit/:id/emissions/:type"
                element={
                  <PrivateRoute
                    redirectTo="/"
                    rolesAllowed={[rolesEnum.AUDITOR]}
                  >
                    <CompanyEmissionList />
                  </PrivateRoute>
                }
              />
              <Route
                path="/companies/:company/audit/:id/qualitative-data"
                element={
                  <PrivateRoute
                    redirectTo="/"
                    rolesAllowed={[rolesEnum.AUDITOR]}
                  >
                    <QualitativeData />
                  </PrivateRoute>
                }
              />
              <Route
                path="/companies/:company/year/:year"
                element={
                  <PrivateRoute
                    redirectTo="/"
                    rolesAllowed={[rolesEnum.AUDITOR]}
                  >
                    <AuditDetails />
                  </PrivateRoute>
                }
              />
              <Route
                path="/companies/:companyId"
                element={
                  <PrivateRoute
                    redirectTo="/"
                    rolesAllowed={[rolesEnum.AUDITOR]}
                  >
                    <CompanyDetails />
                  </PrivateRoute>
                }
              />
              <Route
                path="/companies/:companyId/program/:programId"
                element={
                  <PrivateRoute
                    redirectTo="/"
                    rolesAllowed={[rolesEnum.AUDITOR]}
                  >
                    <CarbonOffsetCompanyDetails />
                  </PrivateRoute>
                }
              />
            </>
          )}
          {role.includes(rolesEnum.SUPER_ADMIN) && (
            <>
              <Route
                path="/dashboard"
                element={
                  <PrivateRoute
                    redirectTo="/"
                    rolesAllowed={[rolesEnum.SUPER_ADMIN]}
                  >
                    <SuperAdminDashboard />
                  </PrivateRoute>
                }
              />
              <Route
                path="/companies"
                element={
                  <PrivateRoute
                    redirectTo="/"
                    rolesAllowed={[rolesEnum.SUPER_ADMIN]}
                  >
                    <SuperAdminCompanies />
                  </PrivateRoute>
                }
              />
              <Route
                path="/companies/:companyId/:tab"
                element={
                  <PrivateRoute
                    redirectTo="/"
                    rolesAllowed={[rolesEnum.SUPER_ADMIN]}
                  >
                    <CompanyDetail />
                  </PrivateRoute>
                }
              />
              <Route
                path="/companies/:companyId/:tab/functionality-setup"
                element={
                  <PrivateRoute
                    redirectTo="/"
                    rolesAllowed={[rolesEnum.SUPER_ADMIN]}
                  >
                    <FunctionalitySetup />
                  </PrivateRoute>
                }
              />
              <Route
                path="/blockchain"
                element={
                  <PrivateRoute
                    redirectTo="/"
                    rolesAllowed={[rolesEnum.SUPER_ADMIN]}
                  >
                    <BlockchainList />
                  </PrivateRoute>
                }
              />
              <Route
                path="/blockchain-details/:id"
                element={
                  <PrivateRoute
                    redirectTo="/"
                    rolesAllowed={[rolesEnum.SUPER_ADMIN]}
                  >
                    <BlockchainDetails />
                  </PrivateRoute>
                }
              />
              <Route
                path="/companies/:companyId/country/:countryName/facilities"
                element={
                  <PrivateRoute
                    redirectTo="/"
                    rolesAllowed={[rolesEnum.SUPER_ADMIN]}
                  >
                    <CompanyFacilityDetailsTable />
                  </PrivateRoute>
                }
              />
              <Route
                path="/companies/:companyId/country/:countryName/facilities/:id"
                element={
                  <PrivateRoute
                    redirectTo="/"
                    rolesAllowed={[rolesEnum.SUPER_ADMIN]}
                  >
                    <ActiveSessionsTable />
                  </PrivateRoute>
                }
              />
              <Route
                path="/companies/:companyId/country/:countryName/facilities/:id"
                element={
                  <PrivateRoute
                    redirectTo="/"
                    rolesAllowed={[rolesEnum.SUPER_ADMIN]}
                  >
                    <ActiveSessionsTable />
                  </PrivateRoute>
                }
              />
              <Route
                path="/companies/:companyId/country/:countryName/facilities/:id/:userType/:isActive"
                element={
                  <PrivateRoute
                    redirectTo="/"
                    rolesAllowed={[rolesEnum.SUPER_ADMIN]}
                  >
                    <CompanyTotalUserDetails />
                  </PrivateRoute>
                }
              />
              <Route
                path="/superadmin-users/:tab/:userType/user-details/:isActive"
                element={
                  <PrivateRoute
                    redirectTo="/"
                    rolesAllowed={[rolesEnum.SUPER_ADMIN]}
                  >
                    <UserDetailsTable />
                  </PrivateRoute>
                }
              />
              <Route
                path="/superadmin-users/:selectedTab"
                element={
                  <PrivateRoute
                    redirectTo="/"
                    rolesAllowed={[rolesEnum.SUPER_ADMIN]}
                  >
                    <SuperAdminUsers />
                  </PrivateRoute>
                }
              />
              <Route
                path="/super-admin-tickets"
                element={
                  <PrivateRoute
                    redirectTo="/"
                    rolesAllowed={[rolesEnum.SUPER_ADMIN]}
                  >
                    <SuperAdminTickets />
                  </PrivateRoute>
                }
              />
              <Route
                path="/super-admin-tickets/ticket-details/:id"
                element={
                  <PrivateRoute
                    redirectTo="/"
                    rolesAllowed={[rolesEnum.SUPER_ADMIN]}
                  >
                    <TicketDetails />
                  </PrivateRoute>
                }
              />
              <Route
                path="/notifications"
                element={
                  <PrivateRoute
                    redirectTo="/"
                    rolesAllowed={[rolesEnum.SUPER_ADMIN]}
                  >
                    <SuperAdminNotifications />
                  </PrivateRoute>
                }
              />
              <Route
                path="/add-notifications"
                element={
                  <PrivateRoute
                    redirectTo="/"
                    rolesAllowed={[rolesEnum.SUPER_ADMIN]}
                  >
                    <AddNotification />
                  </PrivateRoute>
                }
              />
              <Route
                path="/notifications/edit/:id"
                element={
                  <PrivateRoute
                    redirectTo="/"
                    rolesAllowed={[rolesEnum.SUPER_ADMIN]}
                  >
                    <EditNotification />
                  </PrivateRoute>
                }
              />
            </>
          )}
          {role.includes(rolesEnum.STAKEHOLDER) && (
            <>
              <Route
                path="/company"
                element={
                  <PrivateRoute
                    redirectTo="/"
                    rolesAllowed={[rolesEnum.STAKEHOLDER]}
                  >
                    <StakeholderCompany />
                  </PrivateRoute>
                }
              />
              <Route
                path="/dashboard"
                element={
                  <PrivateRoute
                    redirectTo="/"
                    rolesAllowed={[rolesEnum.STAKEHOLDER]}
                  >
                    {/* <StakeholderDashboard /> */}
                    <Dashboard />
                  </PrivateRoute>
                }
              />
              <Route
                path="/reports-stakeholder"
                element={
                  <PrivateRoute
                    redirectTo="/"
                    rolesAllowed={[rolesEnum.STAKEHOLDER]}
                  >
                    <StakeholderReports />
                  </PrivateRoute>
                }
              />
              <Route
                path="/reports-stakeholder/details/:id"
                element={
                  <PrivateRoute
                    redirectTo="/"
                    rolesAllowed={[rolesEnum.STAKEHOLDER]}
                  >
                    <StakeholderReportDetails />
                  </PrivateRoute>
                }
              />
            </>
          )}
          <Route
            path="/help"
            element={
              <PrivateRoute redirectTo="/" rolesAllowed={allRoles}>
                <Help />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <PrivateRoute redirectTo="/" rolesAllowed={allRoles}>
                <Profile />
              </PrivateRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <PrivateRoute redirectTo="/" rolesAllowed={allRoles}>
                <Settings />
              </PrivateRoute>
            }
          />
          <Route
            path="/subscription-details"
            element={
              <PrivateRoute redirectTo="/" rolesAllowed={allRoles}>
                <SubscriptionDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/subscription-plans"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[rolesEnum.SUPER_ADMIN]}
              >
                <SubscriptionPlans />
              </PrivateRoute>
            }
          />
          <Route
            path="/facilities"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.ADMIN,
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                ]}
              >
                <Facilities />
              </PrivateRoute>
            }
          />
          <Route
            path="/assesment-cycles"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.ADMIN,
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                ]}
              >
                <AssessmentDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/assesment-cycles/details/"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.ADMIN,
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                ]}
              >
                <AssessmentYearlyDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/users"
            element={
              <PrivateRoute redirectTo="/" rolesAllowed={[rolesEnum.ADMIN]}>
                <Users />
              </PrivateRoute>
            }
          />
          <Route
            path="/users/add-users"
            element={
              <PrivateRoute redirectTo="/" rolesAllowed={[rolesEnum.ADMIN]}>
                <AddUser />
              </PrivateRoute>
            }
          />
          <Route
            path="/users/edit-users/:id"
            element={
              <PrivateRoute redirectTo="/" rolesAllowed={[rolesEnum.ADMIN]}>
                <EditUser />
              </PrivateRoute>
            }
          />
          <Route
            path="/read-only-users"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                ]}
              >
                <ReadOnlyUsers />
              </PrivateRoute>
            }
          />
          <Route
            path="/read-only-users/:id"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                ]}
              >
                <ReadOnlyUsersDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/tickets"
            element={
              <PrivateRoute redirectTo="/" rolesAllowed={allRoles}>
                <Tickets />
              </PrivateRoute>
            }
          />
          <Route
            path="/tickets/:id"
            element={
              <PrivateRoute redirectTo="/" rolesAllowed={allRoles}>
                <TicketsDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/support-tickets"
            element={
              <PrivateRoute redirectTo="/" rolesAllowed={allRoles}>
                <SupportTickets />
              </PrivateRoute>
            }
          />
          <Route
            path="/support-tickets/:id"
            element={
              <PrivateRoute redirectTo="/" rolesAllowed={allRoles}>
                <SupportTicketDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/glossary"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.FACILITY_MANAGER,
                  rolesEnum.BUSINESS_USER,
                  rolesEnum.APPROVER,
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                  rolesEnum.ADMIN,
                  rolesEnum.AUDITOR,
                ]}
              >
                <Glossary />
              </PrivateRoute>
            }
          />
          <Route
            path="/carbon-credit-pool"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                ]}
              >
                <CarbonCreditPool />
              </PrivateRoute>
            }
          />
          <Route
            path="/carbon-offset"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.BUSINESS_USER,
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                ]}
              >
                <CarbonOffset />
              </PrivateRoute>
            }
          />
          <Route
            path="/carbon-offset/:id"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.ADMIN,
                  rolesEnum.APPROVER,
                  rolesEnum.FACILITY_MANAGER,
                  rolesEnum.BUSINESS_USER,
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                  rolesEnum.AUDITOR,
                ]}
              >
                <CarbonOffsetDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/offset-approval-management/"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.BUSINESS_USER,
                ]}
              >
                <OffsetApprovalStatus />
              </PrivateRoute>
            }
          />
          <Route
            path="/offset-approval-management/details/:id"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.BUSINESS_USER,
                ]}
              >
                <MonthlyProgramDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/offset-approval-pending/"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.BUSINESS_USER,
                ]}
              >
                <OffsetRequestApproval />
              </PrivateRoute>
            }
          />
          <Route
            path="/offset-approval-pending/details/:id"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.BUSINESS_USER,
                ]}
              >
                <MonthlyProgramDetailsPending />
              </PrivateRoute>
            }
          />
          <Route
            path="/twofa"
            element={
              <PublicRoute redirectTo={redirectLink}>
                {isSuperAdmin && is2FAEnabled ? (
                  <TwoFaOtpValidation />
                ) : (
                  <Navigate to="/" />
                )}
              </PublicRoute>
            }
          />
          <Route
            path="/admin-2FA"
            element={
              <PublicRoute redirectTo={redirectLink}>
                {/* {isSuperAdmin && is2FAEnabled ? ( */}
                <AdminTwoFaOtpValidation />
                {/* ) : ( */}
                {/* <Navigate to="/" /> */}
                {/* )} */}
              </PublicRoute>
            }
          />
          <Route
            path="/trade/login"
            element={
              <TradePublicRoute redirectTo={"/trade/dashboard"}>
                <LoginTrade />
              </TradePublicRoute>
            }
          />
          <Route
            path="/trade/signup"
            element={
              <TradePublicRoute redirectTo="/trade/signup">
                <TradeSignup />
              </TradePublicRoute>
            }
          />
          <Route
            path="/trade/signup-response"
            element={
              <TradePublicRoute redirectTo="/trade/signup">
                <TradeSignupResponse />
              </TradePublicRoute>
            }
          />
          {/* {role.includes(rolesEnum.TRADE_ADMIN) && ( */}
            <>
              <Route
                path="/trade/dashboard"
                element={
                  <TradePrivateRoute
                    redirectTo="/trade/login"
                    rolesAllowed={[rolesEnum.TRADE_ADMIN]}
                  >
                    <TradeDashboard />
                  </TradePrivateRoute>
                }
              />
              <Route
                path="/trade/dashboard-internal"
                element={
                  <TradePrivateRoute
                    redirectTo="/trade/login"
                    rolesAllowed={[rolesEnum.TRADE_ADMIN]}
                  >
                    <TradeDashboardInternal />
                  </TradePrivateRoute>
                }
              />
              <Route
                path="/trade/sell"
                element={
                  <TradePrivateRoute
                    redirectTo="/trade/login"
                    rolesAllowed={[rolesEnum.TRADE_ADMIN]}
                  >
                    <TradeSell />
                  </TradePrivateRoute>
                }
              />
              <Route
                path="/trade/carboncredits-added"
                element={
                  <TradePrivateRoute
                    redirectTo="/"
                    rolesAllowed={[rolesEnum.TRADE_ADMIN]}
                  >
                    <CarbonCreditsTable />
                  </TradePrivateRoute>
                }
              />
              <Route
                path="/trade/carboncredits-added/:id"
                element={
                  <TradePrivateRoute
                    redirectTo="/"
                    rolesAllowed={[rolesEnum.TRADE_ADMIN]}
                  >
                    <CarbonCreditsProject />
                  </TradePrivateRoute>
                }
              />
              <Route
                path="/trade/buy"
                element={
                  <TradePrivateRoute
                    redirectTo="/trade/login"
                    rolesAllowed={[rolesEnum.TRADE_ADMIN]}
                  >
                    <TradeBuy />
                  </TradePrivateRoute>
                }
              />
              <Route
                path="/trade/buy/:id"
                element={
                  <TradePrivateRoute
                    redirectTo="/trade/login"
                    rolesAllowed={[rolesEnum.TRADE_ADMIN]}
                  >
                    <BuyDetails />
                  </TradePrivateRoute>
                }
              />
              <Route
                path="/trade-profile"
                element={
                  <TradePrivateRoute
                    redirectTo="/trade/login"
                    rolesAllowed={[rolesEnum.TRADE_ADMIN]}
                  >
                    <TradeProfile />
                  </TradePrivateRoute>
                }
              />
              <Route
                path="/trade-profile/settings"
                element={
                  <TradePrivateRoute
                    redirectTo="/trade/login"
                    rolesAllowed={[rolesEnum.TRADE_ADMIN]}
                  >
                    <ProfileSettings />
                  </TradePrivateRoute>
                }
              />
            </>
          {/* )} */}
          <Route path="/forgot-password/" element={<ForgotPassword />} />
          <Route path="/" element={<Navigate to="/" />} />
          <Route
            path="/approval-flow/"
            element={
              <PrivateRoute redirectTo="/" rolesAllowed={[rolesEnum.ADMIN]}>
                <ApprovalWorkFlow />
              </PrivateRoute>
            }
          />
          <Route
            path="/approval-flow/functionality-view/"
            element={
              <PrivateRoute redirectTo="/" rolesAllowed={[rolesEnum.ADMIN]}>
                <FunctionalityView />
              </PrivateRoute>
            }
          />
          <Route
            path="/companies/:companyId/reports/details/:country_code/:facility_id/:year"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[rolesEnum.SUPER_ADMIN]}
              >
                <SuperAdminReportDetails />
              </PrivateRoute>
            }
          />

          <Route
            path="/dashboard-config/"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.ADMIN,
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                  rolesEnum.FACILITY_MANAGER,
                  rolesEnum.BUSINESS_USER,
                  rolesEnum.APPROVER,
                ]}
              >
                <DashboardConfiguration />
              </PrivateRoute>
            }
          />

          <Route
            path="/survey-results/"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                ]}
              >
                <SurveyResult />
              </PrivateRoute>
            }
          />
          <Route
            path="/survey-result-details/:answerId"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                ]}
              >
                <SupplierViewSurvey />
              </PrivateRoute>
            }
          />
          <Route
            path="/production-data-list"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                  rolesEnum.BUSINESS_USER,
                  rolesEnum.FACILITY_MANAGER,
                  rolesEnum.APPROVER,
                ]}
              >
                <ProductionDataList />
              </PrivateRoute>
            }
          />
          <Route
            path="/production-data-add"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                  rolesEnum.BUSINESS_USER,
                  rolesEnum.FACILITY_MANAGER,
                  rolesEnum.APPROVER,
                ]}
              >
                <ProductionDataAdd />
              </PrivateRoute>
            }
          />

          <Route
            path="/sustainability-risk/survey/"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                ]}
              >
                <SustainabilityRiskAssessmentSurvey />
              </PrivateRoute>
            }
          />
          <Route
            path="/sustainability-risk/track-progress"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                ]}
              >
                <SustainabilityRiskAssessmentTrackProgress />
              </PrivateRoute>
            }
          />
          <Route
            path="/sustainability-risk/track-progress/:id"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                ]}
              >
                <SustainabilityRiskAssessmentProgressTable />
              </PrivateRoute>
            }
          />
          <Route
            path="/sustainability-risk/track-progress/:id/response/:answerId"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                ]}
              >
                <SustainabilityRiskAssessmentViewSurvey />
              </PrivateRoute>
            }
          />
          <Route
            path="/sustainability-risk/view-survey/:id"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                ]}
              >
                <SustainabilityRiskAssessmentViewSurvey />
              </PrivateRoute>
            }
          />
          <Route
            path="/sustainability-risk/history"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                ]}
              >
                <SustainabilityRiskAssessmentHistory />
              </PrivateRoute>
            }
          />
          <Route
            path="/sustainability-risk/history/:id"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                ]}
              >
                <SustainabilityRiskAssessmentHistoryDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/sustainability-risk-survey-results/"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                ]}
              >
                <SustainabilityRiskAssessmentSurveyResult />
              </PrivateRoute>
            }
          />
          <Route
            path="/risk-survey-result-details/:answerId"
            element={
              <PrivateRoute
                redirectTo="/"
                rolesAllowed={[
                  rolesEnum.SUSTAINABILITY_MANAGER,
                  rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
                ]}
              >
                <SustainabilityRiskAssessmentViewSurvey />
              </PrivateRoute>
            }
          />
          <Route
            path="/sustainability-risk/survey/:answerId"
            element={
              <PublicRoute redirectTo={redirectLink}>
                <SustainabilityRiskAssessmentResponse />
              </PublicRoute>
            }
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default RootNavigation;
