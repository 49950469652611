import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Paper, Typography } from "@mui/material"
import { Chart as ChartJS, Tooltip, Legend, ArcElement, registerables as registerablesJS } from 'chart.js';
import { getSustainabilityRiskRating } from '../../../redux/actions';
import useStyles from './styles'
import EmissionTypeChart from './EmissionTypeChart';
import { useNavigate } from 'react-router-dom';

ChartJS.register(...registerablesJS)
ChartJS.register(ArcElement, Tooltip, Legend);

const SustainibilityRiskRating = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const emissionData = useSelector(state => state.dashboard.getSustainabilityRiskRating.data);
    const emissionDataStatus = useSelector(state => state.dashboard.getSustainabilityRiskRating.status);


    useEffect(() => {
          !props.refreshContent && dispatch(getSustainabilityRiskRating(props.filter));
    }, [dispatch, props.filter]);

    const handleClick = () => {
      navigate('/dashboard/details/supplier-industries', { state: { filter: props.filter} })
    }
    

    return (
      <Paper className={classes.container} onClick={handleClick}>
          <>
            <Typography className={classes.graphTitle}>
            Sustainability Risk Assessment Ratings
            </Typography>
            <div className={classes.emissionChart}>
              {Object.keys(emissionData)?.length > 0 ? (
                <EmissionTypeChart data={emissionData} />
              ) :emissionDataStatus === "running"? <div style={{ minHeight: 250 }}>Loading...</div>: (
                <div style={{ minHeight: 250 }}>No Data Available</div>
              )}
            </div>
          </>
      </Paper>
    );
}

export default SustainibilityRiskRating
