import React, { useState } from "react";
import useStyles from "./styles";
import { Box, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const MarketServedTable = ({ marketReservedTable, setmarketReservedTable }) => {
  const classes = useStyles();
  const handleInputChange = (categoryIndex, field, value) => {
    const updatedData = [...marketReservedTable];
    updatedData[categoryIndex][field] = value;
    setmarketReservedTable(updatedData);
  };
  const [canAddMore, setCanAddmore] = useState(true);
  const [canRemove, setCanRemove] = useState(marketReservedTable.length > 1);
  const handleAddMore = () => {
    setmarketReservedTable((prev) => [
      ...prev, // Spread the existing array to retain its elements
      {
        marketServed: "",
        graphicBreakDown: "",
        sectorsServed: "",
      }, // Add the new object to the array
    ]);
    setCanRemove(true);
  };
  const handleRemove = () => {
    setmarketReservedTable((prev) => {
      // Use slice to create a new array excluding the last element
      setCanRemove(prev.length > 2);
      return prev.slice(0, -1);
    });
  };
  return (
    <div style={{ padding: "20px" }}>
      <div
        style={{
          borderRadius: "6px",
          overflow: "hidden",
          // border: "1px solid #ddd",
        }}
      >
        <table border="2" style={{ borderCollapse: "collapse", width: "100%" }}>
          <thead>
            <tr style={{ backgroundColor: "#d0c5ca", textAlign: "center" }}>
              <th style={{ padding: "8px" }}>Market Served</th>
              <th style={{ padding: "8px" }}>Geographic Breakdown</th>
              <th style={{ padding: "8px" }}>Sectors Served</th>
            </tr>
          </thead>
          <tbody>
            {marketReservedTable?.map((item, categoryIndex) => (
              <React.Fragment key={categoryIndex}>
                <tr key={`${categoryIndex}`}>
                  {/* Only render the category cell for the first row of each category */}
                  <td className={classes.marketDataRow}>
                    <textarea
                      value={item.marketServed}
                      placeholder="Enter Markets Served"
                      onChange={(e) =>
                        handleInputChange(
                          categoryIndex,
                          "marketServed",
                          e.target.value
                        )
                      }
                      className={classes.marketDataItem}
                    />
                  </td>
                  <td className={classes.marketDataRow}>
                    <textarea
                      value={item.graphicBreakDown}
                      placeholder="Enter Geographic Breakdown"
                      onChange={(e) =>
                        handleInputChange(
                          categoryIndex,
                          "graphicBreakDown",
                          e.target.value
                        )
                      }
                      className={classes.marketDataItem}
                    />
                  </td>
                  <td className={classes.marketDataRow}>
                    <textarea
                      value={item.sectorsServed}
                      placeholder="Enter Sectors Served"
                      onChange={(e) =>
                        handleInputChange(
                          categoryIndex,
                          "sectorsServed",
                          e.target.value
                        )
                      }
                      className={classes.marketDataItem}
                    />
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
        <div className={classes.addMoreDiv}>
          <Box
            display="flex"
            paddingY={2}
            paddingLeft={2}
            gap={1}
            onClick={canRemove ? handleRemove : null}
            style={{
              cursor: canRemove ? "pointer" : "not-allowed",
              color: canRemove ? "gray" : "gray",
              border: "1px solid #ddd",
              padding: "2px 7px",
              margin: "5px 5px",
              borderRadius: "5px",
            }}
          >
            <RemoveIcon />
            <Typography>Remove</Typography>
          </Box>
          <Box
            display="flex"
            paddingY={2}
            paddingLeft={2}
            gap={1}
            onClick={canAddMore ? handleAddMore : null}
            style={{
              cursor: canAddMore ? "pointer" : "not-allowed",
              color: canAddMore ? "gray" : "gray",
              border: "1px solid #ddd",
              padding: "2px 7px",
              margin: "5px 5px",
              borderRadius: "5px",
            }}
          >
            <AddIcon />
            <Typography>Add More</Typography>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default MarketServedTable;
