import { Navigate, useNavigate } from "react-router-dom";
import { getCookie } from "../services/cookie";

const TradePublicRoute = ({ children, redirectTo = "/" }) => {
  let isAuthenticated = getCookie("auth_token_trade")
  // const userRole = useSelector((state) => state.auth.userInfoTrade.role);

  return !isAuthenticated ? (
    children
  ) : (
    <Navigate to={redirectTo} />
  );
};

export default TradePublicRoute;