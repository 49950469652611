import React, { useEffect, useState } from "react";
import { Container, Paper, Typography } from "@mui/material";
import useStyles from "./styles";
import {ScatterPlot} from "./ScatterGraph";
import { dashboardMaterialityData } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";

const DashboardMatrix = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [scatterData, setScatterData] = useState([]);

  const surveyDetails = useSelector(
    (state) => state.dashboard.dashboardMaterialityData.data
  );

  useEffect(() => {
    dispatch(dashboardMaterialityData(props.filter));
  }, [dispatch, props.filter]);

  useEffect(() => {
    if (surveyDetails) {
      const importance = surveyDetails["Importance to Stakeholders"];
      const impact = surveyDetails["Impact of Company"];

      if (importance && impact) {
        const formattedData = Object.keys(importance).map(category => ({
          category,
          x: impact[category], 
          y: importance[category] 
        }));
        setScatterData(formattedData);
      }
    }
  }, [surveyDetails]);

  return (
    <Paper className={classes.container}>
      {scatterData.length > 0 ? (
        <>
          <Typography>Materiality Matrix</Typography>
          <div className={classes.content}>
            <div className={classes.commonChart}>
              <ScatterPlot data={scatterData} />
            </div>
          </div>
        </>
      ) : (
        <Typography className={classes.headings} variant="h6">
          MATERIALITY MATRIX IS NOT AVAILABLE
        </Typography>
      )}
    </Paper>
  );
};

export default DashboardMatrix;
