import React, { useEffect } from "react";
import useStyles from "./styles";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";

import CeroInput from "../../../../components/CeroInput";

import { getCookie } from "../../../../services/cookie";
import AssetValueTable from "../RiskTable/AssetValue";
import MarketServedTable from "../RiskTable/marketServed";
import SupplyChainTable from "../RiskTable/SupplyChain";
import DownstreamTable from "../RiskTable/Downstream";
import LawRegulationTable from "../RiskTable/LawRegulations";
import CeroPhoneInput from "../../../../components/CeroPhoneInput";
import CeroDate from "../../../../components/CeroDate";
import MaterialTopics from "../RiskTable/MaterialTopics";

function GriQuestionnaire({
  handleCheckboxChange,
  handleSingleSelect,
  handleTextInputChange,
  textAnswers,
  selectedValues,
  assetValueTable,
  setAssetValueTable,
  marketReservedTable,
  setmarketReservedTable,
  supplyChainTable,
  setSupplyChainTable,
  downstreamTable,
  setDownstreamTable,
  lawRegulationTable,
  setLawRegulationTable,
  materialTopicsTable,
  setMaterialTopicsTable,
  handleDateInputChange,
  dateAnswers,
}) {
  const classes = useStyles();
  const role = getCookie("role");

  const materialityQuestions = useSelector((state) => {
    const data = state.emission.getStandardsQuestionnaire.data.questions_list;
    return Array.isArray(data) ? data : [];
  });

  const saveQuestionnaire = useSelector(
    (state) => state.assessment.saveStandardsQuestionnaire
  );

  const submitQuestionnaire = useSelector(
    (state) => state.assessment.submitStandardsQuestionnaire
  );

  const assessmentList = useSelector(
    (state) => state.assessment.listAssessmentCycleCountry.data
  );

  const specificQuestionId = "Date of publication";

  useEffect(() => {
    const existingAnswer = textAnswers[specificQuestionId];
    if (!existingAnswer || existingAnswer === "") {
      const today = new Date().toLocaleDateString("en-GB");
      handleTextInputChange(specificQuestionId, today);
    }
  }, [specificQuestionId]);

  const questionDict = {};
  const subQuestionDict = {};
  function toRoman(num) {
    const romanMap = {
      1: "I",
      2: "II",
      3: "III",
      4: "IV",
      5: "V",
      6: "VI",
      7: "VII",
      8: "VIII",
      9: "IX",
      10: "X",
      11: "XI",
      12: "XII",
      13: "XIII",
      14: "XIV",
      15: "XV",
    };

    return romanMap[num] || "Invalid number";
  }
  materialityQuestions.forEach(
    ({
      main_section,
      section,
      question,
      category,
      question_type,
      options,
      id,
      condition,
      question_id,
    }) => {
      // Ensure the section exists
      if (!questionDict[main_section]) {
        questionDict[main_section] = {};
      }

      // Ensure the sub_section exists
      if (!questionDict[main_section][section]) {
        questionDict[main_section][section] = [];
      }
      if (question_id !== "None") {
        if (!subQuestionDict[question_id]) {
          subQuestionDict[question_id] = [];
        }
        subQuestionDict[question_id].push({
          question,
          category,
          question_type,
          options,
          id,
          condition,
          question_id,
        });
      }
      // Add the question to the appropriate section
      questionDict[main_section][section].push({
        question,
        category,
        question_type,
        options,
        id,
        condition,
        question_id,
      });
    }
  );

  // const handleDataChange = () => {
  //   const data = {
  //     answer_list: [
  //     ],
  //   };
  //   onDataChange(data);
  // };

  // useEffect(() => {
  //   handleDataChange();
  // }, [
  //   assetValueTable,
  //   marketReservedTable,
  //   supplyChainTable,
  //   downstreamTable,
  //   lawRegulationTable,
  // ]);

  const generateAnswerList = () => {
    return materialityQuestions.map(
      ({
        question,
        category,
        question_type,
        options,
        id,
        condition,
        question_id,
      }) => {
        let answer = "";
        let answer_value = "";

        if (
          question_type === "text" ||
          question_type === "Text Field" ||
          question_type === "short_text"
        ) {
          answer = textAnswers[id] || "";
        } else if (
          question_type === "choose_from_option" ||
          question_type === "Choose from Options"
        ) {
          answer = selectedValues[id] || "";
          if (answer === "Other (please specify):") {
            answer_value = textAnswers?.[`other-${id}`] || "";
          }
        } else if (question_type === "multiselect_check_box") {
          const selectedOptions = Array.isArray(selectedValues?.[id])
            ? selectedValues?.[id]
            : [selectedValues?.[id]] || [];
          answer = selectedOptions?.join(", ");
          if (selectedOptions.includes("Other (please specify):")) {
            answer_value = textAnswers?.[`other-${id}`] || "";
          }
        } else if (question_type === "table") {
          if (condition === "table_gri_1" || id === "assetValueTableId") {
            answer = assetValueTable;
          } else if (
            condition === "table_gri_2" ||
            id === "marketReservedTableId"
          ) {
            answer = marketReservedTable;
          } else if (
            condition === "table_gri_3" ||
            id === "supplyChainTableId"
          ) {
            answer = supplyChainTable;
          } else if (
            condition === "table_gri_4" ||
            id === "downstreamTableId"
          ) {
            answer = downstreamTable;
          } else if (
            condition === "table_gri_5" ||
            id === "lawRegulationTableId"
          ) {
            answer = lawRegulationTable;
          } else if (
            condition === "table_gri_6" ||
            id === "MaterialTopicsTableId"
          ) {
            answer = materialTopicsTable;
          } else {
            answer = {};
          }
        }

        return {
          question,
          category,
          question_type,
          options,
          id,
          condition,
          question_id,
          answer,
        };
      }
    );
  };

  // useEffect(() => {
  //   const answerList = generateAnswerList();
  //   onDataChange({ answer_list: answerList });
  // }, [materialityQuestions, textAnswers, selectedValues]);

  const renderSubquestionDiv = (main_question, sub_questions) => {
    if (!main_question || !sub_questions) return null; // Handle null/undefined inputs

    return (
      <React.Fragment key={main_question?.id}>
        {main_question?.question && (
          <Grid
            className={
              main_question.question_type === "text" ||
              main_question.question_type === "Text Field"
                ? classes.inputGrid
                : classes.gridItem
            }
          >
            <Typography
              fontSize={18}
              fontWeight={500}
              variant="h3"
              paddingLeft={4}
              paddingBottom={2}
            >
              {main_question?.question}
            </Typography>
          </Grid>
        )}
        <Box display="flex" paddingLeft={6} flexWrap="wrap">
          {sub_questions?.map((qtn) => (
            <div style={{ width: "50%" }} key={qtn?.id}>
              <Typography
                fontSize={18}
                fontWeight={500}
                variant="h3"
                paddingLeft={4}
                paddingBottom={2}
              >
                {qtn?.question}
              </Typography>

              {(qtn.question_type === "short_text" ||
                qtn.question_type === "Text Field") && (
                <Box width={"100%"}>
                  <CeroInput
                    classes={{
                      container: classes.subTextAreaContainerShort,
                      input: classes.subTextAreaContainerInput,
                    }}
                    rows={3}
                    placeholder={"Please type your Answer"}
                    id={`answer-${qtn.id}`}
                    name={`answer-${qtn.id}`}
                    label="Please type your Answer"
                    multiline
                    value={textAnswers[qtn.id] || ""}
                    onChange={(e) =>
                      handleTextInputChange(qtn.id, e.target.value)
                    }
                  />
                </Box>
              )}
            </div>
          ))}
        </Box>
      </React.Fragment>
    );
  };

  return (
    <div>
      {materialityQuestions.length > 0 && (
        <Grid mb={20}>
          <div className={classes.questionList}>
            {Object.keys(questionDict).map((section, sectionIndex) => (
              <React.Fragment key={sectionIndex}>
                <div className={classes.scoreDiv}>
                  <Typography
                    fontSize={20}
                    fontWeight={550}
                    variant="h3"
                    paddingTop="10px"
                  >
                    {`${section}`.toLocaleUpperCase()}
                  </Typography>
                </div>
                {Object.keys(questionDict[section]).map(
                  (subSection, subIndex) => {
                    return (
                      <div>
                        <Typography
                          fontSize={19}
                          fontWeight={450}
                          variant="h4"
                          paddingTop="10px"
                        >
                          {`${toRoman(subIndex + 1)}. ${subSection}`}
                        </Typography>
                        {questionDict[section][subSection].map(
                          (question, qtnIdx) => {
                            const isIfYesQuestion = question?.question === "";
                            const previousQuestionId =
                              questionDict[section][subSection][qtnIdx - 1]?.id;
                            const isYesSelected =
                              selectedValues &&
                              selectedValues[previousQuestionId] === "Yes";

                            if (isIfYesQuestion && !isYesSelected) {
                              return <></>;
                            } else if (question.question_id !== "None") {
                              return <></>;
                            } else if (subQuestionDict[question.id]) {
                              return renderSubquestionDiv(
                                question,
                                subQuestionDict[question.id]
                              );
                            }
                            const displayIndex =
                              question?.question !== "" && qtnIdx + 1;
                            return (
                              <Grid
                                key={question?.id}
                                className={
                                  question.question_type === "text" ||
                                  question.question_type === "Text Field"
                                    ? classes.inputGrid
                                    : classes.gridItem
                                }
                              >
                                {/* {question?.question !== "" && (
                                  {question?.question}
                                  <Typography
                                    fontSize={18}
                                    fontWeight={500}
                                    variant="h3"
                                    paddingLeft={4}
                                    paddingBottom={2}
                                  >
                                    {displayIndex}.{" "}
                                    {question?.question
                                      ?.replace(/\(.*?\)/g, "")
                                      .trim()}{" "}
                                    <span style={{ fontWeight: 300 }}>
                                      {question?.question?.match(/\(.*?\)/g)}{" "}
                                    </span>
                                  </Typography>
                                )} */}
                                {question?.question !== "" && (
                                  <>
                                    {question.question.includes(
                                      "Provide examples of how stakeholder engagement has directly influenced"
                                    ) ? (
                                      <>
                                        <Typography
                                          fontSize={18}
                                          fontWeight={500}
                                          variant="h3"
                                          paddingLeft={4}
                                          paddingBottom={2}
                                        >
                                          {displayIndex}.{" "}
                                          {question?.question
                                            .split("(")
                                            .map((part, index) =>
                                              index === 0
                                                ? part.trim()
                                                : `(${part}`
                                            )
                                            .join(" ")}{" "}
                                        </Typography>
                                        <Typography
                                          fontSize={16}
                                          fontWeight={400}
                                          paddingLeft={6}
                                          paddingBottom={2}
                                          sx={{ whiteSpace: "pre-line" }}
                                        >
                                          i. The design or implementation of
                                          actions to address impacts.{"\n"}
                                          ii. The evaluation of the
                                          effectiveness of these actions. (E.g.,
                                          stakeholders recommending alternative
                                          solutions, highlighting additional
                                          areas of concern)
                                        </Typography>
                                      </>
                                    ) : (
                                      <Typography
                                        fontSize={18}
                                        fontWeight={500}
                                        variant="h3"
                                        paddingLeft={4}
                                        paddingBottom={2}
                                      >
                                        {displayIndex}.{" "}
                                        {question?.question
                                          .split("(")
                                          .map((part, index) =>
                                            index === 0
                                              ? part.trim()
                                              : `(${part}`
                                          )
                                          .join(" ")}{" "}
                                      </Typography>
                                    )}
                                  </>
                                )}
                                <Box
                                  display="flex"
                                  paddingLeft={6}
                                  flexWrap="wrap"
                                >
                                  {(question?.question_type ===
                                    "choose_from_option" ||
                                    question?.question_type ===
                                      "Choose from Options") && (
                                    <div
                                      style={{
                                        display: "grid",
                                        // gridTemplateColumns: "repeat(4, 1fr)",
                                        gridTemplateColumns: `repeat(${
                                          question.options.some(
                                            (option) =>
                                              option.split(" ").length > 5
                                          )
                                            ? 2
                                            : 4
                                        }, 1fr)`,
                                        gap: "16px",
                                        alignItems: "left",
                                        position: "relative",
                                        textAlign: "left",
                                      }}
                                    >
                                      {question?.options?.map((option, idx) => (
                                        <React.Fragment key={idx}>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                className={
                                                  classes.selectCheckbox
                                                }
                                                checked={
                                                  selectedValues &&
                                                  selectedValues[
                                                    question?.id
                                                  ] === option
                                                }
                                                onChange={() =>
                                                  handleSingleSelect(
                                                    question.id,
                                                    option
                                                  )
                                                }
                                                value={option}
                                                sx={{
                                                  color: "#5A404E !important",
                                                  borderWidth: "none",
                                                  "&.Mui-checked": {
                                                    color: "#8C2148 !important",
                                                  },
                                                }}
                                              />
                                            }
                                            label={option}
                                          />
                                          {selectedValues[question.id] ===
                                            "Other (Please specify):" &&
                                            option ===
                                              "Other (Please specify):" && (
                                              <TextField
                                                variant="outlined"
                                                placeholder="Please type your Answer"
                                                multiline
                                                rows={3}
                                                value={
                                                  textAnswers[
                                                    `other-${question.id}`
                                                  ] || ""
                                                }
                                                onChange={(e) =>
                                                  handleTextInputChange(
                                                    `other-${question.id}`,
                                                    e.target.value
                                                  )
                                                }
                                                className={classes.input}
                                              />
                                            )}
                                        </React.Fragment>
                                      ))}
                                    </div>
                                  )}
                                  {question.question_type ===
                                    "multiselect_check_box" && (
                                    <div
                                      style={{
                                        display: "grid",
                                        // gridTemplateColumns: "repeat(4, 1fr)",
                                        gridTemplateColumns: `repeat(${
                                          question.options.some(
                                            (option) =>
                                              option.split(" ").length > 6
                                          )
                                            ? 2
                                            : 4
                                        }, 1fr)`,
                                        gap: "16px",
                                        alignItems: "left",
                                        position: "relative",
                                        textAlign: "left",
                                      }}
                                    >
                                      {question.options.map((option, idx) => (
                                        <React.Fragment key={idx}>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                className={
                                                  classes.selectCheckbox
                                                }
                                                checked={
                                                  selectedValues[
                                                    question.id
                                                  ]?.includes(option) || false
                                                }
                                                onChange={(e) =>
                                                  handleCheckboxChange(
                                                    e,
                                                    question.id
                                                  )
                                                }
                                                value={option}
                                                sx={{
                                                  color: "#5A404E !important",
                                                  "&.Mui-checked": {
                                                    color: "#8C2148 !important",
                                                  },
                                                  marginRight: "8px",
                                                }}
                                              />
                                            }
                                            label={option}
                                            sx={{
                                              margin: "0 !important",
                                              width: "fit-content",
                                            }}
                                          />

                                          {selectedValues[
                                            question.id
                                          ]?.includes(
                                            "Other (Please specify):"
                                          ) &&
                                            option ===
                                              "Other (Please specify):" && (
                                              <div
                                                style={{
                                                  gridColumn: "1 / -1", // Span all columns
                                                  width: "100%",
                                                  marginTop: "16px",
                                                }}
                                              >
                                                <TextField
                                                  variant="outlined"
                                                  placeholder="Please type your Answer"
                                                  multiline
                                                  rows={3}
                                                  value={
                                                    textAnswers[
                                                      `other-${question.id}`
                                                    ] || ""
                                                  }
                                                  onChange={(e) =>
                                                    handleTextInputChange(
                                                      `other-${question.id}`,
                                                      e.target.value
                                                    )
                                                  }
                                                  sx={{
                                                    width: "100%",
                                                    "& .MuiOutlinedInput-root":
                                                      {
                                                        borderRadius: "8px",
                                                        borderColor: "#5A404E",
                                                      },
                                                  }}
                                                />
                                              </div>
                                            )}
                                        </React.Fragment>
                                      ))}
                                    </div>
                                  )}
                                  {(question.question_type === "text" ||
                                    question.question_type ===
                                      "Text Field") && (
                                    <Box minHeight={80} width={"100%"}>
                                      <CeroInput
                                        className={classes.textAreaContainer}
                                        rows={3}
                                        placeholder={"Please type your Answer"}
                                        id={`answer-${question.id}`}
                                        name={`answer-${question.id}`}
                                        label="Please type your Answer"
                                        multiline
                                        value={textAnswers[question.id] || ""}
                                        onChange={(e) =>
                                          handleTextInputChange(
                                            question.id,
                                            e.target.value
                                          )
                                        }
                                      />
                                    </Box>
                                  )}
                                  {question.question_type === "short_text" &&
                                    (question.question ===
                                    "Contact details of person for questions about the report." ? (
                                      <CeroPhoneInput
                                        required
                                        fullWidth
                                        label="Contact Number"
                                        name="contact number"
                                        value={textAnswers[question.id] || ""}
                                        onChange={(value, country) =>
                                          handleTextInputChange(
                                            question.id,
                                            value
                                          )
                                        }
                                        classes={{
                                          container: classes.phoneInput,
                                        }}
                                      />
                                    ) : (
                                      <Box width={"50%"}>
                                        <CeroInput
                                          classes={{
                                            container:
                                              classes.textAreaContainerShort,
                                            input:
                                              classes.textAreaContainerInput,
                                          }}
                                          rows={3}
                                          placeholder={
                                            "Please type your Answer"
                                          }
                                          id={`answer-${question.id}`}
                                          name={`answer-${question.id}`}
                                          label="Please type your Answer"
                                          multiline
                                          value={textAnswers[question.id] || ""}
                                          onChange={(e) =>
                                            handleTextInputChange(
                                              question.id,
                                              e.target.value
                                            )
                                          }
                                        />
                                      </Box>
                                    ))}
                                  {question.question_type === "table" && (
                                    <Grid width={"100%"}>
                                      {question.instructions &&
                                        question.instructions.map(
                                          (instruction, index) => (
                                            <Typography
                                              paddingLeft={6}
                                              paddingY={1}
                                              key={index}
                                            >
                                              {index + 1}. {instruction}
                                            </Typography>
                                          )
                                        )}
                                      {question.condition === "table_gri_1" ? (
                                        <AssetValueTable
                                          assetValueTable={assetValueTable}
                                          setAssetValueTable={
                                            setAssetValueTable
                                          }
                                        />
                                      ) : question.condition ===
                                        "table_gri_2" ? (
                                        <MarketServedTable
                                          marketReservedTable={
                                            marketReservedTable
                                          }
                                          setmarketReservedTable={
                                            setmarketReservedTable
                                          }
                                        />
                                      ) : question.condition ===
                                        "table_gri_3" ? (
                                        <SupplyChainTable
                                          supplyChainTable={supplyChainTable}
                                          setSupplyChainTable={
                                            setSupplyChainTable
                                          }
                                        />
                                      ) : question.condition ===
                                        "table_gri_4" ? (
                                        <DownstreamTable
                                          downstreamTable={downstreamTable}
                                          setDownstreamTable={
                                            setDownstreamTable
                                          }
                                        />
                                      ) : question.condition ===
                                        "table_gri_5" ? (
                                        <LawRegulationTable
                                          lawRegulationTable={
                                            lawRegulationTable
                                          }
                                          setLawRegulationTable={
                                            setLawRegulationTable
                                          }
                                        />
                                      ) : question.condition ===
                                        "table_gri_6" ? (
                                        <MaterialTopics
                                          materialTopicsTable={
                                            materialTopicsTable
                                          }
                                          setMaterialTopicsTable={
                                            setMaterialTopicsTable
                                          }
                                        />
                                      ) : (
                                        <></>
                                      )}
                                    </Grid>
                                  )}
                                  {question.question_type === "date" && (
                                    <CeroDate
                                      label="Date"
                                      value={dateAnswers[question.id] || ""}
                                      onChange={(date) =>
                                        handleDateInputChange(question.id, date)
                                      }
                                      year={2025}
                                    />
                                  )}
                                </Box>
                              </Grid>
                            );
                          }
                        )}
                      </div>
                    );
                  }
                )}
              </React.Fragment>
            ))}
          </div>
        </Grid>
      )}
    </div>
  );
}

export default GriQuestionnaire;
