import React from "react";
import { Scatter } from "react-chartjs-2";
import { Chart as ChartJS, Title, Tooltip, Legend, PointElement, LinearScale } from "chart.js";

ChartJS.register(LinearScale, PointElement, Legend, Tooltip);

const gradientBackgroundPlugin = {
  id: "customBackground",
  beforeDraw: (chart) => {
    const { ctx, scales } = chart;
    const xScale = scales.x;
    const yScale = scales.y;

    const boxColors = [
      ["#f7f7f7", "#e3e3e3", "#cfcfcf", "#bfbfbf"],
      ["#e3e3e3", "#e3e3e3", "#cfcfcf", "#bfbfbf"],
      ["#cfcfcf", "#cfcfcf", "#cfcfcf", "#bfbfbf"],
      ["#bfbfbf", "#bfbfbf", "#bfbfbf", "#bfbfbf"],
      ["#f7f7f7", "#e3e3e3", "#cfcfcf", "#bfbfbf"],
    ];

    const boxWidth = (xScale.max - xScale.min) / 4;
    const boxHeight = (yScale.max - yScale.min) / 4;

    boxColors.forEach((rowColors, rowIndex) => {
      rowColors.forEach((color, columnIndex) => {
        ctx.fillStyle = color;
        const xStart = xScale.getPixelForValue(xScale.min + boxWidth * columnIndex);
        const xEnd = xScale.getPixelForValue(xScale.min + boxWidth * (columnIndex + 1));
        const yStart = yScale.getPixelForValue(yScale.min + boxHeight * rowIndex);
        const yEnd = yScale.getPixelForValue(yScale.min + boxHeight * (rowIndex + 1));
        if (yEnd > 0)
          ctx.fillRect(xStart, yEnd, xEnd - xStart, yStart - yEnd);
      });
    });
  },
};

export const ScatterPlot = ({ data }) => {
  const randomColor = ["rgba(94, 128, 63, 1)", "rgba(58, 142, 235, 1)", "rgba(248, 99, 5, 1)", "rgba(42, 101, 101, 1)", "rgba(252, 103, 109, 1)"];

  const datasets = data.map((item, index) => ({
    label: item.category,
    data: [{ x: item.x, y: item.y }],
    backgroundColor: randomColor[index % randomColor.length],
    pointRadius: 10,
  }));

  const chartData = { datasets };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "right",
        labels: { usePointStyle: true, pointStyle: "circle", boxWidth: 10 },
      },
      tooltip: {
        callbacks: {
          label: (context) => `X: ${context.raw.x}, Y: ${context.raw.y}`,
        },
      },
    },
    scales: {
      x: {
        type: "linear",
        min: 1,
        max: 5,
        ticks: {
          callback: (value) => ({ 1.5: "Negligible", 2.5: "Moderate", 3.5: "Significant", 4.5: "Major" }[value] || ""),
        },
        title: { display: true, text: "Impact of Company", color: "Black" },
      },
      y: {
        type: "linear",
        min: 1,
        max: 5,
        ticks: {
          callback: (value) => ({ 1.5: "Negligible", 2.5: "Moderate", 3.5: "Significant", 4.5: "Major" }[value] || ""),
        },
        title: { display: true, text: "Importance to Stakeholders", color: "Black" },
      },
    },
  };

  return (
    <div style={{ width: "800px", height: "500px", margin: "0 auto" }}>
      <Scatter data={chartData} options={options} plugins={[gradientBackgroundPlugin]} />
    </div>
  );
};

