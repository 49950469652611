import { all, put, call, takeLatest } from "redux-saga/effects";

import { request } from "../../services/client";
import { ActionTypes } from "../constants/actions";
import { APIEndpoints } from "../constants";

export function* listAssessmentCycle() {
  try {
    const response = yield call(request, APIEndpoints.LIST_ASSESSMENT_CYCLE, {
      method: "GET",
    });
    yield put({
      type: ActionTypes.LIST_ASSESSMENT_CYCLE_SUCCESS,
      payload: response.company_assessment_cycle_list,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.LIST_ASSESSMENT_CYCLE_FAILURE,
      payload: err.message,
    });
  }
}

export function* getAssessmentDetails({ payload }) {
  try {
    const response = yield call(
      request,
      APIEndpoints.GET_ASSESSMENT_DETAILS(payload),
      {
        method: "GET",
      }
    );
    yield put({
      type: ActionTypes.GET_ASSESSMENT_DETAILS_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_ASSESSMENT_DETAILS_FAILURE,
      payload: err.message,
    });
  }
}

export function* createAssessmentCycle({ payload }) {
  try {
    const response = yield call(request, APIEndpoints.CREATE_ASSESSMENT_CYCLE, {
      method: "POST",
      payload,
    });
    yield put({
      type: ActionTypes.CREATE_ASSESSMENT_CYCLE_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.CREATE_ASSESSMENT_CYCLE_FAILURE,
      payload: err.message,
    });
  }
}

export function* updateAssessmentCycle({ payload }) {
  try {
    const response = yield call(
      request,
      APIEndpoints.UPDATE_ASSESSMENT_CYCLE(payload.id),
      {
        method: "PUT",
        payload,
      }
    );
    yield put({
      type: ActionTypes.UPDATE_ASSESSMENT_CYCLE_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_ASSESSMENT_CYCLE_FAILURE,
      payload: err.message,
    });
  }
}

export function* getAssessmentPeriod(action) {
  try {
    const { year, facility, emissionType, emissionId } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.GET_ASSESSMENT_PERIOD(
        year,
        facility,
        emissionType,
        emissionId
      ),
      {
        method: "GET",
      }
    );
    yield put({
      type: ActionTypes.GET_ASSESSMENT_PERIOD_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_ASSESSMENT_PERIOD_FAILURE,
      payload: err.message,
    });
  }
}

export function* saveStandardsQuestionnaire(action) {
  try {
    const { id, payload } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.SAVE_STANDARDS_QUESTIONNAIRE(id),
      {
        method: "POST",
        payload: payload,
      }
    );
    yield put({
      type: ActionTypes.SAVE_STANDARDS_QUESTIONNAIRE_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.SAVE_STANDARDS_QUESTIONNAIRE_FAILURE,
      payload: err.message,
    });
  }
}

export function* getStandardsQuestionnaire(action) {
  try {
    const { id, assessment_id } = action.payload; // Get id and assessment_id from action payload
    const apiEndpoint = `${APIEndpoints.GET_STANDARDS_QUESTIONNAIRE(
      id,
      assessment_id
    )}`; // Construct dynamic endpoint
    const response = yield call(request, apiEndpoint, {
      method: "GET",
    });

    yield put({
      type: ActionTypes.GET_STANDARDS_QUESTIONNAIRE_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    yield put({
      type: ActionTypes.GET_STANDARDS_QUESTIONNAIRE_FAILURE,
      payload: err.message,
    });
  }
}

export function* getStandardsQuestionnaireHistory() {
  try {
    const response = yield call(
      request,
      APIEndpoints.GET_STANDARDS_QUESTIONNAIRE_HISTORY(),
      {
        method: "GET",
      }
    );
    yield put({
      type: ActionTypes.GET_STANDARDS_QUESTIONNAIRE_HISTORY_SUCCESS,
      payload: response.answer_response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_STANDARDS_QUESTIONNAIRE_HISTORY_FAILURE,
      payload: err.message,
    });
  }
}

export function* viewStandardsQuestionnaireAnswer(action) {
  try {
    const { framework_id, questionnaire_id } = action.payload.payload;
    const response = yield call(
      request,
      APIEndpoints.VIEW_STANDARDS_QUESTIONNAIRE_ANSWER(
        framework_id,
        questionnaire_id
      ),
      {
        method: "GET",
      }
    );
    yield put({
      type: ActionTypes.VIEW_STANDARDS_QUESTIONNAIRE_ANSWER_SUCCESS,
      payload: response.answer_response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.VIEW_STANDARDS_QUESTIONNAIRE_ANSWER_FAILURE,
      payload: err.message,
    });
  }
}

export function* submitStandardsQuestionnaire(action) {
  try {
    const { id, payload } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.SUBMIT_STANDARDS_QUESTIONNAIRE(id),
      {
        method: "POST",
        payload: payload,
      }
    );
    yield put({
      type: ActionTypes.SUBMIT_STANDARDS_QUESTIONNAIRE_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.SUBMIT_STANDARDS_QUESTIONNAIRE_FAILURE,
      payload: err.message,
    });
  }
}

export function* getStandardsQuestionnaireAnswers(action) {
  try {
    const { framework_id, country, year, questionnaire_id } = action.payload;

    const response = yield call(
      request,
      APIEndpoints.GET_STANDARDS_QUESTIONNAIRE_ANSWERS(
        framework_id,
        country,
        year,
        questionnaire_id
      ),
      {
        method: "GET",
      }
    );
    yield put({
      type: ActionTypes.GET_STANDARDS_QUESTIONNAIRE_ANSWERS_SUCCESS,
      payload: response.answer_response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_STANDARDS_QUESTIONNAIRE_ANSWERS_FAILURE,
      payload: err.message,
    });
  }
}

export function* approveStandardsQuestionnaire(action) {
  try {
    console.log("action", action);
    const questionnaireId = action.payload;
    const response = yield call(
      request,
      APIEndpoints.APPROVE_STANDARDS_QUESTIONNAIRE(questionnaireId),
      {
        method: "POST",
        payload: {},
      }
    );
    yield put({
      type: ActionTypes.APPROVE_STANDARDS_QUESTIONNAIRE_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.APPROVE_STANDARDS_QUESTIONNAIRE_FAILURE,
      payload: err.message,
    });
  }
}

export function* listAssessmentCycleCountry(action) {
  const { country } = action.payload.country;
  try {
    const response = yield call(
      request,
      APIEndpoints.LIST_ASSESSMENT_CYCLE_COUNTRY(country),
      {
        method: "GET",
      }
    );
    yield put({
      type: ActionTypes.LIST_ASSESSMENT_CYCLE_COUNTRY_SUCCESS,
      payload: response.company_assessment_cycle_list,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.LIST_ASSESSMENT_CYCLE_COUNTRY_FAILURE,
      payload: err.message,
    });
  }
}
export default function* root() {
  yield all([
    takeLatest(ActionTypes.LIST_ASSESSMENT_CYCLE, listAssessmentCycle),
    takeLatest(ActionTypes.GET_ASSESSMENT_DETAILS, getAssessmentDetails),
    takeLatest(ActionTypes.CREATE_ASSESSMENT_CYCLE, createAssessmentCycle),
    takeLatest(ActionTypes.UPDATE_ASSESSMENT_CYCLE, updateAssessmentCycle),
    takeLatest(ActionTypes.GET_ASSESSMENT_PERIOD, getAssessmentPeriod),
    takeLatest(
      ActionTypes.SAVE_STANDARDS_QUESTIONNAIRE,
      saveStandardsQuestionnaire
    ),
    takeLatest(
      ActionTypes.GET_STANDARDS_QUESTIONNAIRE_HISTORY,
      getStandardsQuestionnaireHistory
    ),
    takeLatest(
      ActionTypes.VIEW_STANDARDS_QUESTIONNAIRE_ANSWER,
      viewStandardsQuestionnaireAnswer
    ),
    takeLatest(
      ActionTypes.SUBMIT_STANDARDS_QUESTIONNAIRE,
      submitStandardsQuestionnaire
    ),
    takeLatest(
      ActionTypes.GET_STANDARDS_QUESTIONNAIRE_ANSWERS,
      getStandardsQuestionnaireAnswers
    ),
    takeLatest(
      ActionTypes.APPROVE_STANDARDS_QUESTIONNAIRE,
      approveStandardsQuestionnaire
    ),
    takeLatest(
      ActionTypes.LIST_ASSESSMENT_CYCLE_COUNTRY,
      listAssessmentCycleCountry
    ),
  ]);
}
