import {
  all,
  put,
  call,
  takeLatest,
  fork,
  takeEvery,
} from "redux-saga/effects";

import { request } from "../../services/client";
import { ActionTypes } from "../constants/actions";
import { APIEndpoints } from "../constants";

export function* getEmissionYear(action) {
  try {
    const { filter, role, companyId } = action.payload;
    const apiEndpoint =
      role === "read_only_user"
        ? APIEndpoints.GET_STAKEHOLDER_EMISSION_BY_YEAR_GRAPH_DATA(companyId)
        : APIEndpoints.GET_EMISSION_YEAR;
    const response = yield call(request, apiEndpoint, {
      method: "POST",
      payload: filter,
    });
    yield put({
      type: ActionTypes.GET_EMISSION_YEAR_SUCCESS,
      payload: response.response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_EMISSION_YEAR_FAILURE,
      payload: err.message,
    });
  }
}

export function* getEmissionTypes(action) {
  try {
    const { filter, role, companyId } = action.payload;
    const apiEndpoint =
      role === "read_only_user"
        ? APIEndpoints.GET_STAKEHOLDER_EMISSION_BY_TYPE_GRAPH_DATA(companyId)
        : APIEndpoints.GET_EMISSION_TYPES;
    const response = yield call(request, apiEndpoint, {
      method: "POST",
      payload: filter,
    });
    yield put({
      type: ActionTypes.GET_EMISSION_TYPES_SUCCESS,
      payload: response.response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_EMISSION_TYPES_FAILURE,
      payload: err.message,
    });
  }
}

export function* getEmissionRegion(action) {
  try {
    const { filter } = action.payload;
    const response = yield call(request, APIEndpoints.GET_EMISSION_REGION, {
      method: "POST",
      payload: filter,
    });
    yield put({
      type: ActionTypes.GET_EMISSION_REGION_SUCCESS,
      payload: response.response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_EMISSION_REGION_FAILURE,
      payload: err.message,
    });
  }
}

export function* getEmissionsByMonth(action) {
  try {
    const { filter } = action.payload;
    const response = yield call(request, APIEndpoints.GET_EMISSIONS_BY_MONTH, {
      method: "POST",
      payload: filter,
    });
    yield put({
      type: ActionTypes.GET_EMISSIONS_BY_MONTH_SUCCESS,
      payload: response.response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_EMISSIONS_BY_MONTH_FAILURE,
      payload: err.message,
    });
  }
}
export function* getEmissionsByScope(action) {
  try {
    const { filter } = action.payload;
    const response = yield call(request, APIEndpoints.GET_EMISSIONS_BY_SCOPE, {
      method: "POST",
      payload: filter,
    });
    yield put({
      type: ActionTypes.GET_EMISSIONS_BY_SCOPE_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_EMISSIONS_BY_SCOPE_FAILURE,
      payload: err.message,
    });
  }
}
export function* getEmissionsByScopeByYear(action) {
  try {
    const { filter, role, companyId } = action.payload;
    const apiEndpoint =
      role === "read_only_user"
        ? APIEndpoints.GET_STAKEHOLDER_EMISSION_SCOPE_BY_GRAPH_DATA(companyId)
        : APIEndpoints.GET_EMISSIONS_BY_SCOPE_BY_YEAR;
    const response = yield call(request, apiEndpoint, {
      method: "POST",
      payload: filter,
    });
    yield put({
      type: ActionTypes.GET_EMISSIONS_BY_SCOPE_BY_YEAR_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_EMISSIONS_BY_SCOPE_BY_YEAR_FAILURE,
      payload: err.message,
    });
  }
}
export function* getEsgOverview(action) {
  try {
    const { filter } = action.payload;
    const response = yield call(request, APIEndpoints.GET_ESG_OVERVIEW, {
      method: "POST",
      payload: filter,
    });
    yield put({
      type: ActionTypes.GET_ESG_OVERVIEW_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_ESG_OVERVIEW_FAILURE,
      payload: err.message,
    });
  }
}

export function* getDashboardStatistics(action) {
  try {
    const { filter, role, companyId } = action.payload;
    const apiEndpoint =
      role === "read_only_user"
        ? APIEndpoints.GET_STAKEHOLDER_DASHBOARD_STATISTICS_DATA(companyId)
        : APIEndpoints.GET_DASHBOARD_STATISTICS;
    const response = yield call(request, apiEndpoint, {
      method: "POST",
      payload: filter,
    });
    yield put({
      type: ActionTypes.GET_DASHBOARD_STATISTICS_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_DASHBOARD_STATISTICS_FAILURE,
      payload: err.message,
    });
  }
}

export function* getFacilityTopicEmissionStatistics(action) {
  try {
    const { filter } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.GET_FACILITY_TOPIC_EMISSION,
      {
        method: "GET",
        payload: filter,
      }
    );
    yield put({
      type: ActionTypes.GET_FACILITY_TOPIC_EMISSION_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_FACILITY_TOPIC_EMISSION_FAILURE,
      payload: err.message,
    });
  }
}

export function* getFuelSourceEmission(action) {
  try {
    const { filter } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.GET_FUEL_SOURCE_EMISSION,
      {
        method: "POST",
        payload: filter,
      }
    );
    yield put({
      type: ActionTypes.GET_FUEL_SOURCE_EMISSION_SUCCESS,
      payload: response.response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_FUEL_SOURCE_EMISSION_FAILURE,
      payload: err.message,
    });
  }
}

export function* getAllNotifications({ payload }) {
  try {
    const { unread, isAuditor, rolePrefix } = payload;
    const apiEndpoint = isAuditor
      ? APIEndpoints.LIST_AUDITOR_NOTIFICATIONS(unread)
      : APIEndpoints.LIST_NOTIFICATIONS(unread, rolePrefix);
    const response = yield call(request, apiEndpoint, {
      method: "GET",
    });
    yield put({
      type: ActionTypes.LIST_NOTIFICATIONS_SUCCESS,
      payload: response.notification_details,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.LIST_NOTIFICATIONS_FAILURE,
      payload: err.message,
    });
  }
}

export function* markAllRead({ payload }) {
  try {
    const { isAuditor, unread } = payload;
    const apiEndpoint = isAuditor
      ? APIEndpoints.MARK_ALL_READ_AUDITOR_NOTIFICATIONS
      : APIEndpoints.MARK_ALL_READ;
    const response = yield call(request, apiEndpoint, {
      method: "POST",
      payload: {},
    });
    yield put({
      type: ActionTypes.LIST_NOTIFICATIONS,
      payload: { isAuditor, unread },
    });
    yield put({
      type: ActionTypes.MARK_ALL_READ_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.MARK_ALL_READ_FAILURE,
      payload: err.message,
    });
  }
}

export function* markAsRead({ payload }) {
  try {
    const { isAuditor, unread, id } = payload;
    const apiEndpoint = isAuditor
      ? APIEndpoints.MARK_AS_READ_AUDITOR_NOTIFICATIONS(id)
      : APIEndpoints.MARK_AS_READ(id);
    const response = yield call(request, apiEndpoint, {
      method: "POST",
      payload: {},
    });
    yield put({
      type: ActionTypes.LIST_NOTIFICATIONS,
      payload: { isAuditor, unread },
    });
    yield put({
      type: ActionTypes.MARK_AS_READ_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.MARK_AS_READ_FAILURE,
      payload: err.message,
    });
  }
}

export function* getSuperadminDashboard(action) {
  try {
    const { filter } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.GET_SUPERADMIN_DASHBOARD,
      {
        method: "GET",
        payload: filter,
      }
    );
    yield put({
      type: ActionTypes.GET_SUPERADMIN_DASHBOARD_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_SUPERADMIN_DASHBOARD_FAILURE,
      payload: err.message,
    });
  }
}

export function* stakeholderCompanyDetails(action) {
  try {
    const { companyId } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.STAKEHOLDER_COMPANY_DETAILS(action.payload.companyId),
      {
        method: "GET",
        payload: companyId,
      }
    );
    yield put({
      type: ActionTypes.STAKEHOLDER_COMPANY_DETAILS_SUCCESS,
      payload: response.company_response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.STAKEHOLDER_COMPANY_DETAILS_FAILURE,
      payload: err.message,
    });
  }
}

export function* stakeholderDashboard(action) {
  try {
    const { companyId } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.STAKEHOLDER_DASHBOARD(action.payload.companyId),
      {
        method: "GET",
        payload: companyId,
      }
    );
    yield put({
      type: ActionTypes.STAKEHOLDER_DASHBOARD_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.STAKEHOLDER_DASHBOARD_FAILURE,
      payload: err.message,
    });
  }
}

export function* stakeholderEsgOverview(action) {
  try {
    const { companyId } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.STAKEHOLDER_ESG_OVERVIEW(companyId),
      {
        method: "POST",
        payload: {},
      }
    );
    yield put({
      type: ActionTypes.STAKEHOLDER_ESG_OVERVIEW_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.STAKEHOLDER_ESG_OVERVIEW_FAILURE,
      payload: err.message,
    });
  }
}
export function* stakeholderEmissionByScopeByYear(action) {
  try {
    const { companyId } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.STAKEHOLDER_EMISSION_BY_SCOPE_BY_YEAR(companyId),
      {
        method: "POST",
        payload: {},
      }
    );
    yield put({
      type: ActionTypes.STAKEHOLDER_EMISSION_BY_SCOPE_BY_YEAR_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.STAKEHOLDER_EMISSION_BY_SCOPE_BY_YEAR_FAILURE,
      payload: err.message,
    });
  }
}

export function* stakeholderEmissionTypeGraph(action) {
  try {
    const { companyId } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.STAKEHOLDER_EMISSION_TYPE_GRAPH(companyId),
      {
        method: "POST",
        payload: {},
      }
    );
    yield put({
      type: ActionTypes.STAKEHOLDER_EMISSION_TYPE_GRAPH_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.STAKEHOLDER_EMISSION_TYPE_GRAPH_FAILURE,
      payload: err.message,
    });
  }
}

export function* getStakeholderEmissionYearData(action) {
  try {
    const { companyId } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.GET_STAKEHOLDER_EMISSION_YEAR_DATA(companyId),
      {
        method: "POST",
        payload: {},
      }
    );
    yield put({
      type: ActionTypes.GET_STAKEHOLDER_EMISSION_YEAR_DATA_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_STAKEHOLDER_EMISSION_YEAR_DATA_FAILURE,
      payload: err.message,
    });
  }
}

export function* getNews(action) {
  try {
    const { timeStamp } = action.payload;
    const response = yield call(request, APIEndpoints.GET_NEWS(timeStamp), {
      method: "GET",
    });
    yield put({
      type: ActionTypes.GET_NEWS_SUCCESS,
      payload: response.response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_NEWS_FAILURE,
      payload: err,
    });
  }
}

export function* getAudiorDashboard(action) {
  try {
    const { filter } = action.payload;
    const response = yield call(request, APIEndpoints.GET_AUDITOR_DASHBOARD, {
      method: "GET",
      payload: filter,
    });
    yield put({
      type: ActionTypes.GET_AUDITOR_DASHBOARD_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_AUDITOR_DASHBOARD_FAILURE,
      payload: err.message,
    });
  }
}

export function* getAuditStatusGraph(action) {
  const { role } = action.payload;
  try {
    const response = yield call(
      request,
      APIEndpoints.GET_AUDIT_STATUS_GRAPH(role),
      {
        method: "POST",
        payload: {},
      }
    );
    yield put({
      type: ActionTypes.GET_AUDIT_STATUS_GRAPH_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_AUDIT_STATUS_GRAPH_FAILURE,
      payload: err.message,
    });
  }
}
export function* getAuditTrendGraph(action) {
  try {
    const { filter, userRole } = action.payload.filter;
    const response = yield call(
      request,
      APIEndpoints.GET_AUDIT_TREND_GRAPH(userRole),
      {
        method: "POST",
        payload: filter,
      }
    );
    yield put({
      type: ActionTypes.GET_AUDIT_TREND_GRAPH_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_AUDIT_TREND_GRAPH_FAILURE,
      payload: err.message,
    });
  }
}
export function* getEsgOverviewGraph(action) {
  const { role } = action.payload;
  try {
    const response = yield call(
      request,
      APIEndpoints.GET_ESG_OVERVIEW_GRAPH(role),
      {
        method: "POST",
        payload: {},
      }
    );
    yield put({
      type: ActionTypes.GET_ESG_OVERVIEW_GRAPH_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_ESG_OVERVIEW_GRAPH_FAILURE,
      payload: err.message,
    });
  }
}

export function* getRecordStatus(action) {
  try {
    const { filter } = action.payload;
    const response = yield call(request, APIEndpoints.GET_RECORD_STATUS, {
      method: "POST",
      payload: filter,
    });
    yield put({
      type: ActionTypes.GET_RECORD_STATUS_SUCCESS,
      payload: response.response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_RECORD_STATUS_FAILURE,
      payload: err.message,
    });
  }
}

export function* getTicketStatus(action) {
  try {
    const { isAuditor, filter, role } = action.payload;
    const response = yield call(request, APIEndpoints.GET_TICKET_STATUS(role), {
      method: isAuditor ? "GET" : "POST",
      payload: isAuditor ? action.payload : filter,
    });
    yield put({
      type: ActionTypes.GET_TICKET_STATUS_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_TICKET_STATUS_FAILURE,
      payload: err.message,
    });
  }
}

export function* facilityDistribution(action) {
  try {
    const { filter } = action.payload;
    const response = yield call(request, APIEndpoints.FACILITY_DISTRIBUTION, {
      method: "POST",
      payload: filter,
    });
    yield put({
      type: ActionTypes.FACILITY_DISTRIBUTION_SUCCESS,
      payload: response.response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.FACILITY_DISTRIBUTION_FAILURE,
      payload: err.message,
    });
  }
}

export function* roleDistribution(action) {
  try {
    const response = yield call(request, APIEndpoints.ROLE_DISTRIBUTION, {
      method: "GET",
    });
    yield put({
      type: ActionTypes.ROLE_DISTRIBUTION_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ROLE_DISTRIBUTION_FAILURE,
      payload: err.message,
    });
  }
}

export function* getInitiativeTracking(action) {
  try {
    const { filter, role, companyId } = action.payload;
    const apiEndpoint =
      role === "read_only_user"
        ? APIEndpoints.GET_STAKEHOLDER_INITIATIVE_TRACKING_GRAPH_DATA(companyId)
        : APIEndpoints.GET_INITIATIVE_TRACKING;

    const response = yield call(request, apiEndpoint, {
      method: "POST",
      payload: filter,
    });
    yield put({
      type: ActionTypes.GET_INITIATIVE_TRACKING_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_INITIATIVE_TRACKING_FAILURE,
      payload: err.message,
    });
  }
}

export function* getEnergyMaterial(action) {
  try {
    const { filter, role, companyId } = action.payload;
    const apiEndpoint =
      role === "read_only_user"
        ? APIEndpoints.GET_STAKEHOLDER_ENERGY_CONSUMPTION_GRAPH_DATA(companyId)
        : APIEndpoints.GET_ENERGY_MATERIAL;
    const response = yield call(request, apiEndpoint, {
      method: "POST",
      payload: filter,
    });
    yield put({
      type: ActionTypes.GET_ENERGY_MATERIAL_SUCCESS,
      payload: response.response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_ENERGY_MATERIAL_FAILURE,
      payload: err.message,
    });
  }
}

export function* getFuelSourcesTarget(action) {
  try {
    const { filter, role, companyId } = action.payload;
    const apiEndpoint =
      role === "read_only_user"
        ? APIEndpoints.GET_STAKEHOLDER_SANKEY_GRAPH_DATA(companyId)
        : APIEndpoints.GET_FUEL_SOURCES_TARGET;
    const response = yield call(request, apiEndpoint, {
      method: "POST",
      payload: filter,
    });
    yield put({
      type: ActionTypes.GET_FUEL_SOURCES_TARGET_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_FUEL_SOURCES_TARGET_FAILURE,
      payload: err.message,
    });
  }
}

export function* getEmployeeDiversity(action) {
  try {
    const { filter, role, companyId } = action.payload;
    const apiEndpoint =
      role === "read_only_user"
        ? APIEndpoints.GET_STAKEHOLDER_EMPLOYEE_DIVERSITY_GRAPH_DATA(companyId)
        : APIEndpoints.GET_EMPLOYEE_DIVERSITY;
    const response = yield call(request, apiEndpoint, {
      method: "POST",
      payload: filter,
    });
    yield put({
      type: ActionTypes.GET_EMPLOYEE_DIVERSITY_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_EMPLOYEE_DIVERSITY_FAILURE,
      payload: err.message,
    });
  }
}

export function* getDevelopmentTraining(action) {
  try {
    const { filter, role, companyId } = action.payload;
    const apiEndpoint =
      role === "read_only_user"
        ? APIEndpoints.GET_STAKEHOLDER_DEVELOPMENT_TRAINING_GRAPH_DATA(
            companyId
          )
        : APIEndpoints.GET_DEVELOPMENT_TRAINING;
    const response = yield call(request, apiEndpoint, {
      method: "POST",
      payload: filter,
    });
    yield put({
      type: ActionTypes.GET_DEVELOPMENT_TRAINING_SUCCESS,
      payload: response.training_data,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_DEVELOPMENT_TRAINING_FAILURE,
      payload: err.message,
    });
  }
}

export function* getSocialEngagement(action) {
  try {
    const { filter, role, companyId } = action.payload;
    const apiEndpoint =
      role === "read_only_user"
        ? APIEndpoints.GET_STAKEHOLDER_SOCIAL_ENGAGEMENT_GRAPH_DATA(companyId)
        : APIEndpoints.GET_SOCIAL_ENGAGEMENT;
    const response = yield call(request, apiEndpoint, {
      method: "POST",
      payload: filter,
    });
    yield put({
      type: ActionTypes.GET_SOCIAL_ENGAGEMENT_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_SOCIAL_ENGAGEMENT_FAILURE,
      payload: err.message,
    });
  }
}

export function* getHireResignGraph(action) {
  try {
    const { filter, role, companyId } = action.payload;
    const apiEndpoint =
      role === "read_only_user"
        ? APIEndpoints.GET_STAKEHOLDER_EMPLOYEE_HIRE_RESIGN_TREND_GRAPH_DATA(
            companyId
          )
        : APIEndpoints.GET_HIRE_RESIGN_GRAPH;
    const response = yield call(request, apiEndpoint, {
      method: "POST",
      payload: filter,
    });
    yield put({
      type: ActionTypes.GET_HIRE_RESIGN_GRAPH_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_HIRE_RESIGN_GRAPH_FAILURE,
      payload: err.message,
    });
  }
}

export function* getBoardManagementGraph(action) {
  try {
    const { filter, role, companyId } = action.payload;
    const apiEndpoint =
      role === "read_only_user"
        ? APIEndpoints.GET_STAKEHOLDER_BOARD_MANAGEMENT_DIVERSITY_GRAPH_DATA(
            companyId
          )
        : APIEndpoints.GET_BOARD_MANAGEMENT_GRAPH;
    const response = yield call(request, apiEndpoint, {
      method: "POST",
      payload: filter,
    });
    yield put({
      type: ActionTypes.GET_BOARD_MANAGEMENT_GRAPH_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_BOARD_MANAGEMENT_GRAPH_FAILURE,
      payload: err.message,
    });
  }
}

export function* getEmployeeTurnoverGraph(action) {
  try {
    const { filter, role, companyId } = action.payload;
    const apiEndpoint =
      role === "read_only_user"
        ? APIEndpoints.GET_STAKEHOLDER_EMPLOYEE_TURNOVER_RATE_GRAPH_DATA(
            companyId
          )
        : APIEndpoints.GET_EMPLOYEE_TURNOVER_GRAPH;
    const response = yield call(request, apiEndpoint, {
      method: "POST",
      payload: filter,
    });
    yield put({
      type: ActionTypes.GET_EMPLOYEE_TURNOVER_GRAPH_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_EMPLOYEE_TURNOVER_GRAPH_FAILURE,
      payload: err.message,
    });
  }
}

export function* getEconomicGraph(action) {
  try {
    const { filter, role, companyId } = action.payload;
    const apiEndpoint =
      role === "read_only_user"
        ? APIEndpoints.GET_STAKEHOLDER_ECONOMIC_IMPACT_GRAPH_DATA(companyId)
        : APIEndpoints.GET_ECONOMIC_GRAPH;
    const response = yield call(request, apiEndpoint, {
      method: "POST",
      payload: filter,
    });
    yield put({
      type: ActionTypes.GET_ECONOMIC_GRAPH_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_ECONOMIC_GRAPH_FAILURE,
      payload: err.message,
    });
  }
}

export function* getEthicalBehaviourGraph(action) {
  try {
    const { filter, role, companyId } = action.payload;
    const apiEndpoint =
      role === "read_only_user"
        ? APIEndpoints.GET_STAKEHOLDER_ETHICAL_BEHAVIOUR_GRAPH_DATA(companyId)
        : APIEndpoints.GET_ETHICAL_BEHAVIOUR_GRAPH;
    const response = yield call(request, apiEndpoint, {
      method: "POST",
      payload: filter,
    });
    yield put({
      type: ActionTypes.GET_ETHICAL_BEHAVIOUR_GRAPH_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_ETHICAL_BEHAVIOUR_GRAPH_FAILURE,
      payload: err.message,
    });
  }
}

export function* getAntiCorruptionTraining(action) {
  try {
    const { filter, role, companyId } = action.payload;
    const apiEndpoint =
      role === "read_only_user"
        ? APIEndpoints.GET_STAKEHOLDER_ANTI_CORRUPTION_TRAINING_GRAPH_DATA(
            companyId
          )
        : APIEndpoints.GET_ANTI_CORRUPTION_TRAINING;
    const response = yield call(request, apiEndpoint, {
      method: "POST",
      payload: filter,
    });
    yield put({
      type: ActionTypes.GET_ANTI_CORRUPTION_TRAINING_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_ANTI_CORRUPTION_TRAINING_FAILURE,
      payload: err.message,
    });
  }
}
export function* getAntiCorruptionDisclosure(action) {
  try {
    const { filter, role, companyId } = action.payload;
    const apiEndpoint =
      role === "read_only_user"
        ? APIEndpoints.GET_STAKEHOLDER_ANTI_CORRUPTION_DISCLOSURE_GRAPH_DATA(
            companyId
          )
        : APIEndpoints.GET_ANTI_CORRUPTION_DISCLOSURE;
    const response = yield call(request, apiEndpoint, {
      method: "POST",
      payload: filter,
    });
    yield put({
      type: ActionTypes.GET_ANTI_CORRUPTION_DISCLOSURE_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_ANTI_CORRUPTION_DISCLOSURE_FAILURE,
      payload: err.message,
    });
  }
}

export function* getInitiativeTrackingDrillDown(action) {
  try {
    const { filter, role, companyId } = action.payload;
    const apiEndpoint =
      role === "read_only_user"
        ? APIEndpoints.GET_STAKEHOLDER_INITIATIVE_TRACKING_DRILLDOWN_GRAPH_DATA(
            companyId
          )
        : APIEndpoints.GET_INITIATIVE_TRACKING_DRILL_DOWN;
    const response = yield call(request, apiEndpoint, {
      method: "POST",
      payload: filter,
    });
    yield put({
      type: ActionTypes.GET_INITIATIVE_TRACKING_DRILL_DOWN_SUCCESS,
      payload: response.response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_INITIATIVE_TRACKING_DRILL_DOWN_FAILURE,
      payload: err.message,
    });
  }
}

export function* getEnergyMaterialsDrillDown(action) {
  try {
    const { filter, role, companyId } = action.payload;
    const apiEndpoint =
      role === "read_only_user"
        ? APIEndpoints.GET_STAKEHOLDER_ENERGY_MATERIAL_DRILLDOWN_GRAPH_DATA(
            companyId
          )
        : APIEndpoints.GET_ENERGY_MATERIALS_DRILL_DOWN;
    const response = yield call(request, apiEndpoint, {
      method: "POST",
      payload: filter,
    });
    yield put({
      type: ActionTypes.GET_ENERGY_MATERIALS_DRILL_DOWN_SUCCESS,
      payload: response.response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_ENERGY_MATERIALS_DRILL_DOWN_FAILURE,
      payload: err.message,
    });
  }
}

export function* getNotifications(action) {
  try {
    const { timeStamp } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.GET_NOTIFICATIONS(timeStamp),
      {
        method: "GET",
        payload: timeStamp,
      }
    );
    yield put({
      type: ActionTypes.GET_NOTIFICATIONS_SUCCESS,
      payload: response.announcement_dict,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_NOTIFICATIONS_FAILURE,
      payload: err.message,
    });
  }
}

export function* getUserGraphList(action) {
  try {
    const response = yield call(request, APIEndpoints.GET_USER_GRAPH_LIST(), {
      method: "GET",
    });
    yield put({
      type: ActionTypes.GET_USER_GRAPH_LIST_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_USER_GRAPH_LIST_FAILURE,
      payload: err.message,
    });
  }
}

export function* saveUserGraphConfig(action) {
  try {
    const requestData = action.payload?.payload;

    const response = yield call(
      request,
      APIEndpoints.SAVE_USER_GRAPH_CONFIG(),
      {
        method: "POST",
        payload: requestData,
      }
    );
    yield put({
      type: ActionTypes.SAVE_USER_GRAPH_CONFIG_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.SAVE_USER_GRAPH_CONFIG_FAILURE,
      payload: err.message,
    });
  }
}

export function* getUserGraphConfig(action) {
  try {
    const response = yield call(request, APIEndpoints.GET_USER_GRAPH_CONFIG(), {
      method: "GET",
    });
    yield put({
      type: ActionTypes.GET_USER_GRAPH_CONFIG_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_USER_GRAPH_CONFIG_SUCCESS,
      payload: err.message,
    });
  }
}

export function* getProductionEmissionGraphData(action) {
  try {
    const { filter, role, companyId } = action.payload;
    const apiEndpoint = APIEndpoints.GET_PRODUCTION_EMISSION_GRAPH;
    const response = yield call(request, apiEndpoint, {
      method: "POST",
      payload: filter,
    });
    yield put({
      type: ActionTypes.GET_PRODUCTION_EMISSION_GRAPH_DATA_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_PRODUCTION_EMISSION_GRAPH_DATA_FAILURE,
      payload: err.message,
    });
  }
}

export function* getProductionScopeGraphData(action) {
  const { filter, role, companyId, scope } = action.payload;
  const normalizedScope = scope?.trim().toLowerCase(); // Standardize scope
  console.log("niklh", normalizedScope); // Debug log for normalizedScope

  try {
    const apiEndpoint = APIEndpoints.GET_PRODUCTION_SCOPE_GRAPH;
    const response = yield call(request, apiEndpoint, {
      method: "POST",
      payload: { ...filter, scope },
    });
    const actionType =
      normalizedScope === "scope 1"
        ? ActionTypes.GET_PRODUCTION_SCOPE1_GRAPH_DATA_SUCCESS
        : normalizedScope === "scope 2"
        ? ActionTypes.GET_PRODUCTION_SCOPE2_GRAPH_DATA_SUCCESS
        : ActionTypes.GET_PRODUCTION_SCOPE3_GRAPH_DATA_SUCCESS;
    console.log("nikl actiontype", normalizedScope, actionType);

    yield put({
      type: actionType,
      payload: response,
    });
  } catch (err) {
    const actionType =
      normalizedScope === "scope 1"
        ? ActionTypes.GET_PRODUCTION_SCOPE1_GRAPH_DATA_FAILURE
        : normalizedScope === "scope 2"
        ? ActionTypes.GET_PRODUCTION_SCOPE2_GRAPH_DATA_FAILURE
        : ActionTypes.GET_PRODUCTION_SCOPE3_GRAPH_DATA_FAILURE;

    yield put({
      type: actionType,
      payload: err.message,
    });
  }
}

export function* getDashboardOffsetStatistics(action) {
  try {
    const { filter } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.GET_DASHBOARD_OFFSET_STATISTICS,
      {
        method: "POST",
        payload: filter,
      }
    );
    yield put({
      type: ActionTypes.GET_DASHBOARD_OFFSET_STATISTICS_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_DASHBOARD_OFFSET_STATISTICS_FAILURE,
      payload: err.message,
    });
  }
}
export function* getOffsetOverYears(action) {
  try {
    const { filter } = action.payload;
    const response = yield call(request, APIEndpoints.GET_OFFSET_OVER_YEARS, {
      method: "POST",
      payload: filter,
    });
    yield put({
      type: ActionTypes.GET_OFFSET_OVER_YEARS_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_OFFSET_OVER_YEARS_FAILURE,
      payload: err.message,
    });
  }
}
export function* getEmissionVsOffset(action) {
  try {
    const { filter } = action.payload;
    const response = yield call(request, APIEndpoints.GET_EMISSION_VS_OFFSET, {
      method: "POST",
      payload: filter,
    });
    yield put({
      type: ActionTypes.GET_EMISSION_VS_OFFSET_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_EMISSION_VS_OFFSET_FAILURE,
      payload: err.message,
    });
  }
}
export function* averageScoreSupplierIndustry(action) {
  try {
    const { filter } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.AVERAGE_SCORE_SUPPLIER_INDUSTRY,
      {
        method: "POST",
        payload: filter,
      }
    );
    yield put({
      type: ActionTypes.AVERAGE_SCORE_SUPPLIER_INDUSTRY_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.AVERAGE_SCORE_SUPPLIER_INDUSTRY_FAILURE,
      payload: err.message,
    });
  }
}
export function* getSustainabilityRiskRating(action) {
  try {
    const { filter } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.GET_SUSTAINABILITY_RISK_RATING,
      {
        method: "POST",
        payload: filter,
      }
    );
    yield put({
      type: ActionTypes.GET_SUSTAINABILITY_RISK_RATING_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_SUSTAINABILITY_RISK_RATING_FAILURE,
      payload: err.message,
    });
  }
}
export function* dashboardMaterialityData(action) {
  try {
    const { filter } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.DASHBOARD_MATERIALITY_DATA,
      {
        method: "POST",
        payload: filter,
      }
    );
    yield put({
      type: ActionTypes.DASHBOARD_MATERIALITY_DATA_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.DASHBOARD_MATERIALITY_DATA_FAILURE,
      payload: err.message,
    });
  }
}

export function* dashboardSustainabilityDataDetails(action) {
  try {
    const { filter } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.DASHBOARD_SUSTAINABILITY_DATA_DETAILS,
      {
        method: "POST",
        payload: filter,
      }
    );
    yield put({
      type: ActionTypes.DASHBOARD_SUSTAINABILITY_DATA_DETAILS_SUCCESS,
      payload: response?.survey_list,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.DASHBOARD_SUSTAINABILITY_DATA_DETAILS_FAILURE,
      payload: err.message,
    });
  }
}

export function* dashboardSupplierDataDetails(action) {
  try {
    const { filter } = action.payload;
    const response = yield call(
      request,
      APIEndpoints.DASHBOARD_SUPPLIER_DATA_DETAILS,
      {
        method: "POST",
        payload: filter,
      }
    );
    yield put({
      type: ActionTypes.DASHBOARD_SUPPLIER_DATA_DETAILS_SUCCESS,
      payload: response?.survey_list,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.DASHBOARD_SUPPLIER_DATA_DETAILS_FAILURE,
      payload: err.message,
    });
  }
}

// Watcher for Scope 1
function* watchScope1() {
  yield takeLatest(
    ActionTypes.GET_PRODUCTION_SCOPE1_GRAPH_DATA,
    getProductionScopeGraphData
  );
}

// Watcher for Scope 2
function* watchScope2() {
  yield takeLatest(
    ActionTypes.GET_PRODUCTION_SCOPE2_GRAPH_DATA,
    getProductionScopeGraphData
  );
}

// Watcher for Scope 3
function* watchScope3() {
  yield takeLatest(
    ActionTypes.GET_PRODUCTION_SCOPE3_GRAPH_DATA,
    getProductionScopeGraphData
  );
}

export default function* root() {
  yield all([
    takeLatest(ActionTypes.GET_EMISSION_YEAR, getEmissionYear),
    takeLatest(ActionTypes.GET_EMISSION_TYPES, getEmissionTypes),
    takeLatest(ActionTypes.GET_EMISSION_REGION, getEmissionRegion),
    takeLatest(ActionTypes.GET_EMISSIONS_BY_MONTH, getEmissionsByMonth),
    takeLatest(ActionTypes.GET_EMISSIONS_BY_SCOPE, getEmissionsByScope),
    takeLatest(
      ActionTypes.GET_EMISSIONS_BY_SCOPE_BY_YEAR,
      getEmissionsByScopeByYear
    ),
    takeLatest(ActionTypes.GET_ESG_OVERVIEW, getEsgOverview),
    takeLatest(ActionTypes.GET_DASHBOARD_STATISTICS, getDashboardStatistics),
    takeLatest(
      ActionTypes.GET_FACILITY_TOPIC_EMISSION,
      getFacilityTopicEmissionStatistics
    ),
    takeLatest(ActionTypes.GET_FUEL_SOURCE_EMISSION, getFuelSourceEmission),
    takeLatest(ActionTypes.LIST_NOTIFICATIONS, getAllNotifications),
    takeLatest(ActionTypes.MARK_ALL_READ, markAllRead),
    takeLatest(ActionTypes.MARK_AS_READ, markAsRead),
    takeLatest(ActionTypes.GET_SUPERADMIN_DASHBOARD, getSuperadminDashboard),
    takeLatest(
      ActionTypes.STAKEHOLDER_COMPANY_DETAILS,
      stakeholderCompanyDetails
    ),
    takeLatest(ActionTypes.STAKEHOLDER_DASHBOARD, stakeholderDashboard),
    takeLatest(ActionTypes.STAKEHOLDER_ESG_OVERVIEW, stakeholderEsgOverview),
    takeLatest(
      ActionTypes.STAKEHOLDER_EMISSION_BY_SCOPE_BY_YEAR,
      stakeholderEmissionByScopeByYear
    ),
    takeLatest(
      ActionTypes.STAKEHOLDER_EMISSION_TYPE_GRAPH,
      stakeholderEmissionTypeGraph
    ),
    takeLatest(
      ActionTypes.GET_STAKEHOLDER_EMISSION_YEAR_DATA,
      getStakeholderEmissionYearData
    ),
    takeLatest(ActionTypes.GET_NEWS, getNews),
    takeLatest(ActionTypes.GET_AUDITOR_DASHBOARD, getAudiorDashboard),
    takeLatest(ActionTypes.GET_AUDIT_STATUS_GRAPH, getAuditStatusGraph),
    takeLatest(ActionTypes.GET_AUDIT_TREND_GRAPH, getAuditTrendGraph),
    takeLatest(ActionTypes.GET_ESG_OVERVIEW_GRAPH, getEsgOverviewGraph),
    takeLatest(ActionTypes.GET_RECORD_STATUS, getRecordStatus),
    takeLatest(ActionTypes.GET_TICKET_STATUS, getTicketStatus),
    takeLatest(ActionTypes.FACILITY_DISTRIBUTION, facilityDistribution),
    takeLatest(ActionTypes.ROLE_DISTRIBUTION, roleDistribution),
    takeLatest(ActionTypes.GET_INITIATIVE_TRACKING, getInitiativeTracking),
    takeLatest(ActionTypes.GET_ENERGY_MATERIAL, getEnergyMaterial),
    takeLatest(ActionTypes.GET_FUEL_SOURCES_TARGET, getFuelSourcesTarget),
    takeLatest(ActionTypes.GET_EMPLOYEE_DIVERSITY, getEmployeeDiversity),
    takeLatest(ActionTypes.GET_DEVELOPMENT_TRAINING, getDevelopmentTraining),
    takeLatest(ActionTypes.GET_SOCIAL_ENGAGEMENT, getSocialEngagement),
    takeLatest(ActionTypes.GET_HIRE_RESIGN_GRAPH, getHireResignGraph),
    takeLatest(ActionTypes.GET_BOARD_MANAGEMENT_GRAPH, getBoardManagementGraph),
    takeLatest(
      ActionTypes.GET_EMPLOYEE_TURNOVER_GRAPH,
      getEmployeeTurnoverGraph
    ),
    takeLatest(ActionTypes.GET_ECONOMIC_GRAPH, getEconomicGraph),
    takeLatest(
      ActionTypes.GET_ETHICAL_BEHAVIOUR_GRAPH,
      getEthicalBehaviourGraph
    ),
    takeLatest(
      ActionTypes.GET_ANTI_CORRUPTION_TRAINING,
      getAntiCorruptionTraining
    ),
    takeLatest(
      ActionTypes.GET_ANTI_CORRUPTION_DISCLOSURE,
      getAntiCorruptionDisclosure
    ),
    takeLatest(
      ActionTypes.GET_INITIATIVE_TRACKING_DRILL_DOWN,
      getInitiativeTrackingDrillDown
    ),
    takeLatest(
      ActionTypes.GET_ENERGY_MATERIALS_DRILL_DOWN,
      getEnergyMaterialsDrillDown
    ),
    takeLatest(ActionTypes.GET_NOTIFICATIONS, getNotifications),
    takeLatest(ActionTypes.GET_USER_GRAPH_LIST, getUserGraphList),
    takeLatest(ActionTypes.SAVE_USER_GRAPH_CONFIG, saveUserGraphConfig),
    takeLatest(ActionTypes.GET_USER_GRAPH_CONFIG, getUserGraphConfig),
    takeLatest(
      ActionTypes.GET_PRODUCTION_EMISSION_GRAPH_DATA,
      getProductionEmissionGraphData
    ),
    takeLatest(
      ActionTypes.GET_DASHBOARD_OFFSET_STATISTICS,
      getDashboardOffsetStatistics
    ),
    takeLatest(ActionTypes.GET_OFFSET_OVER_YEARS, getOffsetOverYears),
    takeLatest(ActionTypes.GET_EMISSION_VS_OFFSET, getEmissionVsOffset),
    takeLatest(
      ActionTypes.AVERAGE_SCORE_SUPPLIER_INDUSTRY,
      averageScoreSupplierIndustry
    ),
    takeLatest(
      ActionTypes.GET_SUSTAINABILITY_RISK_RATING,
      getSustainabilityRiskRating
    ),
    takeLatest(
      ActionTypes.DASHBOARD_MATERIALITY_DATA,
      dashboardMaterialityData
    ),
    takeLatest(
      ActionTypes.DASHBOARD_SUSTAINABILITY_DATA_DETAILS,
      dashboardSustainabilityDataDetails
    ),
    takeLatest(
      ActionTypes.DASHBOARD_SUPPLIER_DATA_DETAILS,
      dashboardSupplierDataDetails
    ),
    takeEvery(
      ActionTypes.GET_PRODUCTION_SCOPE_GRAPH_DATA,
      getProductionScopeGraphData
    ),
  ]);
}
