// @ts-nocheck
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  // container: {
  //   padding: theme.spacing(0, 2),
  //   height: "100%",
  //   "@media (min-width: 1200px)": {
  //     maxWidth: "none",
  //   },
  // },
  // tableContainer: {
  //   maxHeight: "calc(100vh - 120px)",
  //   marginTop: theme.spacing(5),
  // },
  // loader: {
  //   width: "100%",
  //   height: 100,
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "center",
  // },
  filterContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: 300,
    marginRight: 10,
    "& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl": {
      backgroundColor: theme.palette.background.white,
    },
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: 15,
    width: "98%",
  },
  submitbutton: {
    background: theme.palette.Primary.background,
    height: 37,
    margin: theme.spacing(0, 2),
    textTransform: "capitalize",
    fontWeight: 400,
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
    },
  },
  savebutton: {
    color: theme.palette.Primary.background,
    borderColor: theme.palette.Primary.background,
    textTransform: "capitalize",
    "&:hover": {
      borderColor: theme.palette.Primary.background,
      backgroundColor: theme.palette.Primary.contrastText,
    },
    margin: theme.spacing(0, 4),
  },
  backContainer: {
    color: theme.palette.Primary.background,
    textTransform: "capitalize",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  // questions: {
  //   margin: theme.spacing(5, 0),
  //   maxWidth: '80vw',
  //   // minHeight: '50vh',
  //   position: 'relative',
  //   display: "flex",
  //   flexWrap: 'wrap'
  // },
  questions: {
    margin: theme.spacing(5, 0),
    maxWidth: "100%",
    width: "100%",
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    overflowX: "hidden" /* Prevents horizontal overflow */,
    boxSizing: "border-box" /* Ensures padding is included in width */,
    minHeight: "60vh",
  },
  bottomContainer: {
    position: "absolute",
    bottom: 10,
    marginTop: 30,
  },
  sectionTitle: {
    fontSize: 30,
    fontWeight: 450,
    color: theme.palette.Primary.background,
    padding: theme.spacing(4, 0),
  },

  subItemTitle: {
    fontSize: 20,
    fontWeight: 450,
    padding: theme.spacing(4, 0),
  },

  subItemValue: {
    padding: theme.spacing(2, 4, 3, 0),
    fontSize: 20,
    // whiteSpace: "pre-line",
    margin: 0,
  },
  consentText: {
    padding: theme.spacing(2, 0, 4, 0),
    margin: 0,
    fontWeight: 500,
    fontSize: 20,
  },
  letterContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(4),
  },
  available: {
    color: "#0088C7",
  },
  // checkbox: {
  //   color: "#8C2148 !important",
  //   borderWidth: 0.1,
  // },
  dialog: {
    width: "40% !important",
    textAlign: "center",
    height: "auto",
    "& .MuiPaper-root.MuiDialog-paper": {
      maxWidth: "none",
      backgroundColor: "white",
    },
  },
  popUp: {
    margin: "auto",
  },
  // selectCheckbox: {
  //   padding: 0,
  //   color: "#8C2148",
  //   borderRadius: "20%",
  //   margin: theme.spacing( 2 , 2 , 2, 0),
  //   "& .MuiFormControlLabel-root.MuiFormControlLabel-label.Mui-disabled":{
  //     color: "black"
  //   }
  // },
  label: {
    "& .MuiFormControlLabel-label.Mui-disabled": {
      color: "black !important",
    },
  },
  // textAreaContainer: {
  //   margin: theme.spacing(8, 0),
  //   height: "auto",
  //   "& .css-1ps9hi4-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled":{
  //     WebkitTextFillColor: "black",
  //   }
  // },
  popUpRedirect: {
    margin: "auto",
    minHeight: 200,
    maxWidth: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonContainerReview: {
    display: "flex",
    justifyContent: "center",
    gap: 5,
    paddingTop: 20,
    paddingBottom: 15,
    width: "50%",
    margin: "auto",
  },
  // buttonSecondary: {
  //   color: theme.palette.Primary.background,
  //   borderColor: theme.palette.Primary.background,
  //   textTransform: "capitalize",
  //   "&:hover": {
  //     borderColor: theme.palette.Primary.background,
  //     backgroundColor: theme.palette.Primary.contrastText,
  //   },
  // },
  // buttonPrimary: {
  //   background: theme.palette.Primary.background,
  //   textTransform: "capitalize",
  //   height: 35,
  //   borderColor: theme.palette.Primary.background,
  //   marginRight: 15,
  //   fontWeight: 400,
  //   color: theme.palette.background.white,
  //   "&:hover": {
  //     backgroundColor: theme.palette.Primary.hoverButton,
  //     borderColor: theme.palette.Primary.hoverButton,
  //     color: theme.palette.Primary.light,
  //   },
  // },
  // headerCell: {
  //   border: "0.5px solid #5A404E",
  // },
  // tableBodyCell: {
  //   border: "0.5px solid #5A404E",
  //   fontWeight: 550,
  // },
  // tableTitle: {
  //   textAlign: "center",
  // },
  // tableContainer: {
  //   marginTop: theme.spacing(5),
  //   border: "1px solid black",
  //   borderRadius: 8,
  //   paddingBottom: "0px !important",
  //   minHeight: "none",
  //   maxHeight: "none",
  //   "& .MuiTable-root":{
  //     paddingBottom: "0px !important",
  //   }
  // },
  gridItem: {
    margin: theme.spacing(5, 0),
  },
  // inputGrid:{
  //   margin: theme.spacing(5, 0),
  //   paddingBottom: theme.spacing(8),
  // },
  textAreaContainer: {
    margin: theme.spacing(8, 0),
    width: "100%",
    height: "auto",
    "& .css-1ps9hi4-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled": {
      WebkitTextFillColor: "black",
    },
  },
  // tableContainer: {
  //   marginTop: theme.spacing(5),
  //   width: '100%', /* Ensures the table doesn’t overflow */
  //   overflowX: 'auto', /* Allows horizontal scrolling for wide tables */
  //   border: "1px solid black",
  //   borderRadius: 8,
  // },
  container: {
    padding: theme.spacing(2),
    width: "100%",
    height: "auto",
    border: "none",
  },
  tableContainer: {
    marginTop: theme.spacing(5),
    border: "1px solid black",
    borderRadius: 8,
    paddingBottom: 0,
    minHeight: "none",
    maxHeight: "none",
    "& .MuiTable-root": {
      paddingBottom: "0px !important",
    },
  },
  title: {
    textAlign: "center",
    fontWeight: 600,
    padding: theme.spacing(4, 0),
    textTransform: "uppercase",
  },
  sectionTitle: {
    fontSize: 30,
    fontWeight: 450,
    color: theme.palette.Primary.background,
    padding: theme.spacing(4, 0),
  },

  subItemTitle: {
    fontSize: 20,
    fontWeight: 450,
    padding: theme.spacing(4, 0),
  },

  subItemValue: {
    padding: theme.spacing(2, 4, 3, 0),
    fontSize: 20,
    // whiteSpace: "pre-line",
    margin: 0,
  },
  consentText: {
    padding: theme.spacing(2, 0, 4, 0),
    margin: 0,
    fontWeight: 500,
    fontSize: 20,
  },
  letterContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(4),
  },
  available: {
    color: "#0088C7",
  },
  checkbox: {
    color: "#8C2148 !important",
    borderWidth: 0.1,
  },
  dialog: {
    width: "40% !important",
    textAlign: "center",
    height: "auto",
    backgroundColor: "white",
    "& .MuiPaper-root.MuiDialog-paper": {
      maxWidth: "none",
      backgroundColor: "white",
    },
    "& .MuiDialogTitle-root": {
      display: "none",
    },
    "& .MuiDialogContent-root": {
      padding: 0,
    },
  },
  popUp: {
    margin: "auto",
  },
  selectCheckbox: {
    padding: 0,
    color: "#8C2148",
    borderRadius: "20%",
    margin: theme.spacing(2, 2, 2, 6),
    "& .MuiFormControlLabel-root.MuiFormControlLabel-label.Mui-disabled": {
      color: "black",
    },
  },
  // textAreaContainer: {
  //   margin: theme.spacing(6, 0),
  //   height: "auto",
  // },
  gridItem: {
    margin: theme.spacing(5, 0),
  },
  buttonContainerReview: {
    display: "flex",
    justifyContent: "center",
    gap: 5,
    paddingTop: 20,
    paddingBottom: 15,
    width: "50%",
    margin: "auto",
  },
  buttonSecondary: {
    color: theme.palette.Primary.background,
    borderColor: theme.palette.Primary.background,
    textTransform: "capitalize",
    "&:hover": {
      borderColor: theme.palette.Primary.background,
      backgroundColor: theme.palette.Primary.contrastText,
    },
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    height: 40,
    borderColor: theme.palette.Primary.background,
    fontWeight: 400,
    color: theme.palette.background.white,
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      borderColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
  },
  // buttonContainer: {
  //   margin: "auto",
  //   width: "100%",
  //   display: "flex",
  //   justifyContent: "center",
  //   padding: theme.spacing(8, 0),
  // },
  questionList: {
    padding: theme.spacing(0, 5),
  },
  textField: {
    padding: theme.spacing(5, 0, 5, 5),
    width: "25%",
    "& .css-14mxzot-MuiInputBase-input-MuiOutlinedInput-input": {
      textAlign: "center",
    },
  },
  input: {
    width: "100%",
    "& label.Mui-focused": {
      color: theme.palette.Primary.main,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.palette.Primary.main,
    },
    "& .MuiInput-root:hover::before": {
      borderWidth: 1,
      borderBottomColor: theme.palette.Primary.main,
    },
    "& .MuiFilledInput-root:hover::before": {
      borderBottomColor: theme.palette.Primary.main,
    },
    "& .MuiFilledInput-underline:after": {
      borderColor: theme.palette.Primary.main,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.Primary.main,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.Primary.main,
      },
      "&.Mui-focused fieldset": {
        borderWidth: 2,
        borderColor: theme.palette.Primary.main,
      },
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& .css-czrb7a-MuiFormLabel-root-MuiInputLabel-root,.css-kyi7ki-MuiFormLabel-root-MuiInputLabel-root.Mui-focused":
      {
        color: theme.palette.Primary.main,
      },
  },
  editBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  headerCell: {
    border: "0.5px solid #5A404E",
  },
  tableBodyCell: {
    border: "0.5px solid #5A404E",
    fontWeight: 550,
  },
  tableTitle: {
    textAlign: "center",
  },
  popupTitle: {
    backgroundColor: theme.palette.Primary.hoverButton,
    color: "white",
    padding: theme.spacing(5),
    marginBottom: theme.spacing(8),
  },
  icon: {
    color: theme.palette.Primary.hoverButton,
    fontSize: "xxx-large",
    marginBottom: theme.spacing(8),
  },
  message: {
    marginBottom: theme.spacing(8),
  },
  loader: {
    width: "100%",
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputGrid: {
    margin: theme.spacing(5, 0),
    paddingBottom: theme.spacing(8),
  },
  textAreaContainerShort: {
    margin: theme.spacing(5, 0),
    width: "100%",
    height: "30px",
    "& .css-1ps9hi4-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled": {
      WebkitTextFillColor: "black",
    },
  },
  textAreaContainerInput: {
    height: "30px",
    "& .MuiInputBase-root": {
      height: "50px",
    },
    "& .MuiInputBase-root > .MuiInputBase-input": {
      height: "30px",
    },
    "& textarea": {
      height: "30px !important", // Set the desired height for the <textarea>
    },
  },

  subTextAreaContainerShort: {
    margin: "10px 0px 30px 0px",
    paddingRight: "25px",
    width: "100%",
    height: "30px",
    "& .css-1ps9hi4-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled": {
      WebkitTextFillColor: "black",
    },
  },
  subTextAreaContainerInput: {
    height: "30px",
    "& .MuiInputBase-root": {
      height: "50px",
    },
    "& .MuiInputBase-root > .MuiInputBase-input": {
      height: "30px",
    },
    "& textarea": {
      height: "30px !important", // Set the desired height for the <textarea>
    },
  },
  phoneInput:{
    margin : "12px 5px",
    width: "20rem",
    "& .MuiFormControl-root > .MuiInputBase-root > input":{
      padding:"11px 10px"
    }
  }
}));

export default useStyles;
