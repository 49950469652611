import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paper } from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  registerables as registerablesJS,
} from "chart.js";
import { Line } from "react-chartjs-2";
import _ from "lodash";

import {
  getEmissionYear,
  getProductionEmissionGraphData,
} from "../../../redux/actions";

import useStyles from "./styles";
import { STATUS } from "../../../redux/constants";
import { getCookie } from "../../../services/cookie";

ChartJS.register(...registerablesJS);
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const EmissionPerUnitLine = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const role = getCookie("role");
  const companyId = getCookie("companyId");

  const EmissionProductionData = useSelector(
    (state) => state.dashboard.getProductionEmissionGraphData
  );

  useEffect(() => {
    !props.refreshContent &&
      dispatch(getProductionEmissionGraphData(props.filter, role, companyId));
  }, [dispatch, props.filter]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        labels: {
          fontColor: "rgba(0,0,0,.4)",
        },
        align: "end",
        position: "bottom",
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.raw;
            return label;
          },
        },
      },
      title: {
        align: "start",
        display: true,
        text: `Total Emission Per Unit of Produce (${
          EmissionProductionData?.data?.unit
            ? EmissionProductionData.data?.unit
            : "Tonnes"
        })`,
        color: "black",
        padding: {
          bottom: 20,
        },
        font: {
          size: 18,
          family: "Inter",
          weight: "400",
        },
      },
    },
    scales: {
      y: {
        title: {
          display: true,
          text: "Emission per unit produced",
          color: "#4F4F4F",
          font: {
            size: 12,
            family: "Inter",
            weight: "400",
          },
        },
        ticks: {
          color: "black",
          // callback: formatAxisValue,z
        },
        grid: {
          display: false,
        },
      },
      x: {
        ticks: {
          color: "black",
        },
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: "Duration",
          color: "#4F4F4F",
          font: {
            size: 12,
            family: "Inter",
            weight: "400",
          },
        },
      },
    },
  };

  const data = {
    labels: _.map(EmissionProductionData.data.data, (item) => item.year),
    datasets: [
      {
        label: `Emission Per Unit of Produce (${
          EmissionProductionData?.data?.unit
            ? EmissionProductionData.data?.unit
            : "Tonnes"
        })`,
        data: _.map(EmissionProductionData.data.data, (item) => item.value),
        color: "black",
        borderColor: "#846BB5",
        backgroundColor: "#6FBFBA",
      },
    ],
  };

  return (
    <Paper className={classes.container}>
      {EmissionProductionData.status === STATUS.SUCCESS ? (
        EmissionProductionData?.data?.data?.length > 0 ? (
          <Line options={options} data={data} height={360} width={560} />
        ) : (
          "Graph view only supports filtering data based on the year"
        )
      ) : (
        "Data loading..."
      )}
    </Paper>
  );
};

export default EmissionPerUnitLine;
