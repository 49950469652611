import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { getDashboardOffsetStatistics } from "../../../redux/actions";
import useStyles from "./styles";

const CarbonOffsetCard = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const statisticsData = useSelector(
    (state) => state.dashboard.getDashboardOffsetStatistics.data
  );

  useEffect(() => {
    !props.refreshContent &&
      dispatch(getDashboardOffsetStatistics(props.filter));
  }, [dispatch, props.filter]);

  return (
    <Paper className={classes.container}>
      <Typography className={classes.graphTitle}>Carbon Offset</Typography>
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1" className={classes.subTitle}>
              Total Carbon Offset
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.largeBox}>
              <Typography variant="h5" className={classes.value}>
                {Number(statisticsData?.total?.toFixed(2))}
              </Typography>
              <Typography variant="body2" className={classes.unit}>
                metric tons
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {statisticsData?.Afforestation && (
            <Grid
              item
              xs={12}
              xm={6}
              xl={4}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Typography variant="body1" className={classes.subTitleNew}>
                Afforestation
              </Typography>
              <Box className={classes.scopeBox}>
                <Typography variant="h5" className={classes.value}>
                  {statisticsData?.Afforestation}
                </Typography>
                <Typography variant="body2" className={classes.unit}>
                  metric tons
                </Typography>
              </Box>
            </Grid>
          )}
          {statisticsData["Hydro Energy Program"] && (
            <Grid
              item
              xs={12}
              xm={6}
              xl={4}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Typography variant="body1" className={classes.subTitle}>
                Hydro Energy{" "}
              </Typography>
              <Box className={classes.scopeBox}>
                <Typography variant="h5" className={classes.value}>
                  {statisticsData["Hydro Energy Program"]}
                </Typography>
                <Typography variant="body2" className={classes.unit}>
                  metric tons
                </Typography>
              </Box>
            </Grid>
          )}
          {statisticsData?.Reforestation ? (
            <Grid
              item
              xs={12}
              xm={6}
              xl={4}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Typography variant="body1" className={classes.subTitleNew}>
                Reforestation
              </Typography>
              <Box className={classes.scopeBox}>
                <Typography variant="h5" className={classes.value}>
                  {statisticsData?.Reforestation}
                </Typography>
                <Typography variant="body2" className={classes.unit}>
                  metric tons
                </Typography>
              </Box>
            </Grid>
          ) : (
            ""
          )}
          {statisticsData["Solar Energy Program"] ? (
            <Grid
              item
              xs={12}
              xm={6}
              xl={4}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Typography variant="body1" className={classes.subTitle}>
                Solar Energy
              </Typography>
              <Box className={classes.scopeBox}>
                <Typography variant="h5" className={classes.value}>
                  {Number(statisticsData["Solar Energy Program"]?.toFixed(2))}
                </Typography>
                <Typography variant="body2" className={classes.unit}>
                  metric tons
                </Typography>
              </Box>
            </Grid>
          ) : (
            ""
          )}
          {statisticsData["Wave Energy Converter"] ? (
            <Grid
              item
              xs={12}
              xm={6}
              xl={4}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Typography variant="body1" className={classes.subTitle}>
                Wave Energy Converter
              </Typography>
              <Box className={classes.scopeBox}>
                <Typography variant="h5" className={classes.value}>
                  {statisticsData["Wave Energy Converter"]}
                </Typography>
                <Typography variant="body2" className={classes.unit}>
                  metric tons
                </Typography>
              </Box>
            </Grid>
          ) : (
            ""
          )}
          {statisticsData["Wind Energy Program"] ? (
            <Grid
              item
              xs={12}
              xm={6}
              xl={4}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Typography variant="body1" className={classes.subTitle}>
                Wind Energy
              </Typography>
              <Box className={classes.scopeBox}>
                <Typography variant="h5" className={classes.value}>
                  {statisticsData["Wind Energy Program"]}
                </Typography>
                <Typography variant="body2" className={classes.unit}>
                  metric tons
                </Typography>
              </Box>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </div>
    </Paper>
  );
};

export default CarbonOffsetCard;
