import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paper, Typography } from "@mui/material";
import {
  getOffsetOverYears,
} from "../../../redux/actions";
import useStyles from "./styles";
import StackedColumnChart from "./StackedColumnChart";


const OffsetOverYears = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const scopeByYear = useSelector(
    (state) => state.dashboard.getOffsetOverYears.data
  );

  useEffect(() => {
    !props.refreshContent && dispatch(getOffsetOverYears(props.filter));
  }, [dispatch, props.filter]);

  return (
    <Paper className={classes.container}>
      <>
        <Typography className={classes.graphTitle}>Offset over time</Typography>
        {Object.keys(scopeByYear)?.length > 0 ? (
          <div style={{minHeight: "400px"}}>
            <StackedColumnChart
              apiResponse={scopeByYear}
              filter={props.filter}
            />
          </div>
        ) : (
          <div style={{ minHeight: 250 }}>No Data Available</div>
        )}
      </>
    </Paper>
  );
};

export default OffsetOverYears;
