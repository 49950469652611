import React, { useEffect, useState } from "react";
import { Card, Container, Grid, Typography } from "@mui/material";

import DashboardLayout from "../../layouts/DashboardLayout";
import DashboardHeader from "./DashboardHeader";
import TotalEmissionChart from "./TotalEmissionChart";
import EmissionChart from "./EmissionChart";
import GeographicalChart from "./GeographicalChart";
import Statistics from "./Statistics";
import useStyles from "./styles";
import FuelSourceGraph from "./FuelSourceGraph";
import AuditTrends from "../AuditorDashboard/AuditTrends";
import { getCookie } from "../../services/cookie";
import RoleDistributionGraph from "./RoleDistributionGraph";
import InitiativeTrackingGraph from "./InitiativeTrackingGraph";
import EnergyMaterialConsumption from "./EnergyMaterialConsumption";
import FuelSourceTargetGraph from "./FuelSourcesSankeyGraph";
import EmissionsCard from "./EmissionsCard";
import DiversityGraph from "./DiversityGraph";
import DevelopmentTrainingGraph from "./DevelopmentTrainingGraph";
import SocialEngagementGraph from "./SocialEngagementGraph";
import EmployeeHireResignGraph from "./EmployeeHireResignGraph";
import BoardDiversityGraph from "./BoardDiversityGraph";
import ManagementDiversityGraph from "./ManagementDiversityGraph";
import EmployeeTurnoverGraph from "./EmployeeTurnoverGraph";
import EconomicGraph from "./EconomicGraph";
import EthicalBehaviourGraph from "./EthicalBehaviourGraph";
import AntiCorruptionDisclosureGraph from "./AntiCorruptionDisclosureGraph";
import AntiCorruptionTrainingGraph from "./AntiCorruptionTrainingGraph";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import {
  getNotifications,
  stakeholderCompanyDetails,
} from "../../redux/actions";
import DashboardNotification from "./DashboardNotification";
import { GridLayout } from "../DashboardConfiguration/layoutArrangement";
import { getGraphs } from "../DashboardConfiguration";
import { getUserGraphConfig } from "../../redux/actions/dashboard";
import ProductionEmissionGraph from "./ProductionEmissionGraph";
import ProductionScopeGraph from "./ProductionScopeGraph";
import CarbonOffsetCard from "./CarbonOffsetCard";
import OffsetOverYears from "./OffsetOverYears";
import OffsetProgramType from "./OffsetProgramType";
import EmissionvsOffset from "./EmissionvsOffset";
import SustainibilityRiskRating from "./SustainibilityRiskRating";
import AverageScoreSupplierIndustry from "./AverageScoreSupplierIndustry";
import DashboardMatrix from "./DashboardMatrix";
import EmissionPerUnitLine from "./EmissionPerUnitLine";

const Dashboard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const role = getCookie("role");
  const [filter, setFilter] = useState({ duration: "last_five_years" });
  const [value, setValue] = useState(0);
  const company = useSelector((state) => state.dashboard.companyDetails.data);
  const notificationData = useSelector(
    (state) => state.dashboard.getNotifications.data
  );
  const userInfo = useSelector((state) => state.auth.userInfo);
  const userConfig = useSelector(
    (state) => state.dashboard.getUserGraphConfig.data
  );

  const onApplyFilter = (filter) => {
    setFilter(filter);
  };

  const companyId = Cookies.get("companyId");

  useEffect(() => {
    if (role === "read_only_user")
      dispatch(stakeholderCompanyDetails(companyId));
  }, [dispatch, role, companyId]);

  const timeStamp = Date.now();

  useEffect(() => {
    if (
      role !== "read_only_user" &&
      role !== "auditor" &&
      role !== "proxy_sustainability_manager"
    ) {
      dispatch(getNotifications(timeStamp));
    }
  }, [dispatch, role]);
  useEffect(() => {
    dispatch(getUserGraphConfig());
  }, [dispatch]);
  const renderChartsBasedOnRole = () => {
    switch (role) {
      case "facility_manager":
        return (
          <Grid
            container
            rowSpacing={-2}
            columnSpacing={5}
            className={classes.gridContainer}
          >
            <Grid xs={4.8} item>
              <GeographicalChart filter={filter} />
            </Grid>
            <Grid xs={7.2} item>
              <FuelSourceGraph filter={filter} />
            </Grid>
            <Grid xs={4.8} item>
              <EmissionChart filter={filter} />
            </Grid>
            <Grid xs={7.2} item>
              <AuditTrends filter={filter} size={true} />
            </Grid>
          </Grid>
        );
      case "sustainability_manager":
        if (value === 0) {
          return (
            <Grid
              container
              rowSpacing={2}
              columnSpacing={5}
              className={classes.gridContainer}
            >
              <Grid xs={4.8} item>
                <EmissionsCard filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <EmissionPerUnitLine filter={filter} />
              </Grid>
              <Grid xs={4.8} item>
                <EmissionChart filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <FuelSourceGraph filter={filter} />
              </Grid>
              <Grid xs={4.8} item>
                <ProductionScopeGraph filter={filter} scope={1} />
              </Grid>
              <Grid xs={7.2} item>
                <TotalEmissionChart filter={filter} />
              </Grid>
              <Grid xs={12} item>
                <FuelSourceTargetGraph filter={filter} />
              </Grid>

              <Grid xs={4.8} item>
                <CarbonOffsetCard filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <OffsetOverYears filter={filter} />
              </Grid>
              <Grid xs={4.8} item>
                <OffsetProgramType filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <EmissionvsOffset filter={filter} />
              </Grid>

              {/* <Grid xs={4.8} item>
                <ProductionScopeGraph filter={filter} scope={2} />
              </Grid>
              <Grid xs={7.2} item>
                <ProductionScopeGraph filter={filter} scope={3} />
              </Grid> */}
              <Grid xs={12} marginTop={-2} mb={-2} item>
                <Typography variant="h6">Drill Down Charts</Typography>
              </Grid>
              <Grid xs={4.8} item>
                <SustainibilityRiskRating filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <AverageScoreSupplierIndustry filter={filter} />
              </Grid>
              <Grid xs={4.8} item>
                <InitiativeTrackingGraph filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <EnergyMaterialConsumption filter={filter} />
              </Grid>
              <Grid xs={12} marginTop={-2} mb={-2} item>
                <DashboardMatrix filter={filter} scope={2} />
              </Grid>
            </Grid>
          );
        }
        if (value === 1) {
          return (
            <Grid
              container
              rowSpacing={2}
              columnSpacing={5}
              className={classes.gridContainer}
            >
              <Grid xs={4.8} item>
                <DiversityGraph filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <EmployeeHireResignGraph filter={filter} />
              </Grid>
              <Grid xs={4.8} item>
                <DevelopmentTrainingGraph filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <SocialEngagementGraph filter={filter} />
              </Grid>
              <Grid xs={12} item>
                <EmployeeTurnoverGraph filter={filter} />
              </Grid>
              <Grid xs={4.8} item>
                <BoardDiversityGraph filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <AntiCorruptionDisclosureGraph filter={filter} />
              </Grid>
              <Grid xs={4.8} item>
                <ManagementDiversityGraph filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <AntiCorruptionTrainingGraph filter={filter} />
              </Grid>
              <Grid xs={4.8} item>
                <EconomicGraph filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <EthicalBehaviourGraph filter={filter} resize={true} />
              </Grid>
            </Grid>
          );
        }
        break;
      case "proxy_sustainability_manager":
        if (value === 0) {
          return (
            <Grid
              container
              rowSpacing={2}
              columnSpacing={5}
              className={classes.gridContainer}
            >
              <Grid xs={4.8} item>
                <EmissionsCard filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <FuelSourceGraph filter={filter} />
              </Grid>
              <Grid xs={12} item>
                <FuelSourceTargetGraph filter={filter} />
              </Grid>
              <Grid xs={4.8} item>
                <EmissionChart filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <TotalEmissionChart filter={filter} />
              </Grid>
              <Grid xs={4.8} item>
                <CarbonOffsetCard filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <OffsetOverYears filter={filter} />
              </Grid>
              <Grid xs={4.8} item>
                <OffsetProgramType filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <EmissionvsOffset filter={filter} />
              </Grid>
              <Grid xs={12} marginTop={-2} mb={-2} item>
                <Typography variant="h6">Drill Down Charts</Typography>
              </Grid>
              <Grid xs={4.8} item>
                <InitiativeTrackingGraph filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <EnergyMaterialConsumption filter={filter} />
              </Grid>
            </Grid>
          );
        }
        if (value === 1) {
          return (
            <Grid
              container
              rowSpacing={2}
              columnSpacing={5}
              className={classes.gridContainer}
            >
              <Grid xs={4.8} item>
                <DiversityGraph filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <EmployeeHireResignGraph filter={filter} />
              </Grid>
              <Grid xs={4.8} item>
                <DevelopmentTrainingGraph filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <SocialEngagementGraph filter={filter} />
              </Grid>
              <Grid xs={12} item>
                <EmployeeTurnoverGraph filter={filter} />
              </Grid>
              <Grid xs={4.8} item>
                <BoardDiversityGraph filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <AntiCorruptionDisclosureGraph filter={filter} />
              </Grid>
              <Grid xs={4.8} item>
                <ManagementDiversityGraph filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <AntiCorruptionTrainingGraph filter={filter} />
              </Grid>
              <Grid xs={4.8} item>
                <EconomicGraph filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <EthicalBehaviourGraph filter={filter} resize={true} />
              </Grid>
            </Grid>
          );
        }
        break;
      case "read_only_user":
        if (value === 0) {
          return (
            <Grid
              container
              rowSpacing={2}
              columnSpacing={5}
              className={classes.gridContainer}
            >
              <Grid xs={4.8} item>
                <EmissionsCard filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <FuelSourceGraph filter={filter} />
              </Grid>
              <Grid xs={12} item>
                <FuelSourceTargetGraph filter={filter} />
              </Grid>
              <Grid xs={4.8} item>
                <EmissionChart filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <TotalEmissionChart filter={filter} />
              </Grid>
              <Grid xs={4.8} item>
                <CarbonOffsetCard filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <OffsetOverYears filter={filter} />
              </Grid>
              <Grid xs={4.8} item>
                <OffsetProgramType filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <EmissionvsOffset filter={filter} />
              </Grid>
              <Grid xs={12} marginTop={-2} mb={-2} item>
                <Typography variant="h6">Drill Down Charts</Typography>
              </Grid>
              <Grid xs={4.8} item>
                <InitiativeTrackingGraph filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <EnergyMaterialConsumption filter={filter} />
              </Grid>
            </Grid>
          );
        }
        if (value === 1) {
          return (
            <Grid
              container
              rowSpacing={2}
              columnSpacing={5}
              className={classes.gridContainer}
            >
              <Grid xs={4.8} item>
                <DiversityGraph filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <EmployeeHireResignGraph filter={filter} />
              </Grid>
              <Grid xs={4.8} item>
                <DevelopmentTrainingGraph filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <SocialEngagementGraph filter={filter} />
              </Grid>
              <Grid xs={12} item>
                <EmployeeTurnoverGraph filter={filter} />
              </Grid>
              <Grid xs={4.8} item>
                <BoardDiversityGraph filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <AntiCorruptionDisclosureGraph filter={filter} />
              </Grid>
              <Grid xs={4.8} item>
                <ManagementDiversityGraph filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <AntiCorruptionTrainingGraph filter={filter} />
              </Grid>
              <Grid xs={4.8} item>
                <EconomicGraph filter={filter} />
              </Grid>
              <Grid xs={7.2} item>
                <EthicalBehaviourGraph filter={filter} resize={true} />
              </Grid>
            </Grid>
          );
        }
        break;
      case "admin":
        return (
          <Grid
            container
            rowSpacing={-2}
            columnSpacing={5}
            className={classes.gridContainer}
          >
            <Grid xs={4.8} item>
              <RoleDistributionGraph filter={filter} size={true} />
            </Grid>
            <Grid xs={7.2} item>
              <FuelSourceGraph filter={filter} />
            </Grid>
            <Grid xs={4.8} item>
              <GeographicalChart filter={filter} />
            </Grid>
            <Grid xs={7.2} item>
              <AuditTrends filter={filter} size={true} />
            </Grid>
          </Grid>
        );
      default:
        return (
          <Grid
            container
            rowSpacing={-2}
            columnSpacing={5}
            className={classes.gridContainer}
          >
            <Grid xs={4.8} item>
              <EmissionChart filter={filter} />
            </Grid>
            <Grid xs={7.2} item>
              <FuelSourceGraph filter={filter} />
            </Grid>
            <Grid xs={4.8} item>
              <GeographicalChart filter={filter} />
            </Grid>
            <Grid xs={7.2} item>
              <AuditTrends filter={filter} size={true} />
            </Grid>
          </Grid>
        );
    }
  };
  const GRAPHS = getGraphs(onApplyFilter, filter, value, setValue, true);
  const dashConfig = userConfig?.configuration || userInfo?.configuration;

  if (dashConfig) {
    if (dashConfig?.layout)
      var layoutType = parseInt(dashConfig?.layout.split("type_")[1]);
    if (dashConfig?.background_color)
      var backgroundColor = dashConfig?.background_color;
    if (dashConfig?.widgets) {
      // var selectNames = dashConfig?.widgets.map(item=> GRAPHS[item]);
      var selectNames = Object.keys(GRAPHS)
        .filter(
          (item) =>
            dashConfig?.widgets.includes(item) &&
            (dashConfig.widgets.some((widget) => widget === "Filters")
              ? value === GRAPHS[item].tabIndex || GRAPHS[item].tabIndex == null
              : true)
        )
        .map((item) => GRAPHS[item]);
    }
  }
  return (
    <DashboardLayout>
      <Container
        className={classes.container}
        style={{ background: backgroundColor || "#F7F8F9" }}
      >
        {role !== "read_only_user" &&
          role !== "auditor" &&
          role !== "proxy_sustainability_manager" && (
            <DashboardNotification notificationData={notificationData} />
          )}
        {role === "read_only_user" && (
          <Card className={classes.companyCard}>
            <Typography variant="h4" className={classes.companyName}>
              {company.name}
            </Typography>
          </Card>
        )}
        {dashConfig ? (
          <GridLayout type={layoutType} components={selectNames} tab={-1} />
        ) : (
          <>
            <DashboardHeader
              onApplyFilter={onApplyFilter}
              value={value}
              setValue={setValue}
              isShowConfigButton={true}
            />
            <Statistics filter={filter} />
            {renderChartsBasedOnRole()}
          </>
        )}
      </Container>
    </DashboardLayout>
  );
};

export default Dashboard;
