import { Typography, Box, Container, Grid, Modal } from "@mui/material";
import React, { useState } from "react";
import CeroInfoPair from "../../../components/CeroInfoPair";
import useStyles from "../styles";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import CeroItemPair from "../../../components/CeroItemPair";
import { getCookie } from "../../../services/cookie";
import CeroButton from "../../../components/CeroButton";
import Download from "../../../assets/images/Download";
import CloseIcon from "@mui/icons-material/Close";

const EmployeeHealthApproval = (props) => {
  const classes = useStyles();
  const { emissionData, handleCallback, handleReviewCallback } = props;
  const role = getCookie("role");
  const readOnlyMode = getCookie("read_only_mode") === "disable_edit";
  const showActionButtons =
    window.location.pathname.includes("/pending-approval-details") &&
    (role === "sustainability_manager" ||
      role === "proxy_sustainability_manager");
  const showVerifyButtons =
    window.location.pathname.includes("/companies") && role === "auditor";
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleIconClick = (itemId) => {
    handleCallback(itemId);
  };
  const handleReviewClick = (itemId) => {
    handleReviewCallback(itemId);
  };

  const handlePdfOpen = () => {
    setIsModalOpen(true);
  };

  const handlePdfClose = () => {
    setIsModalOpen(false);
  };
  const handlePdfPopup = () => {
    return (
      <embed
        src={`${emissionData.blob_url}#toolbar=0`}
        type="application/pdf"
        width="100%"
        height="600px"
      />
    );
  };

  return (
    <Container className={classes.mainContainer}>
      {emissionData &&
        Array.isArray(emissionData) &&
        emissionData?.map((item, index) => (
          <Container key={index} className={classes.container}>
            <Box className={classes.actionBox}>
              <CeroInfoPair
                title="Status:"
                value={
                  item.status === "pending_approval"
                    ? "Pending Approval"
                    : item.status
                    ? item.status
                    : "NA"
                }
                classes={{
                  container: classes.statusContainer,
                  title: classes.statusTitle,
                  value:
                    item.status === "In Progress" ||
                    item.status === "pending_approval"
                      ? classes.Inprogress
                      : classes[item?.status],
                }}
              />
              {showActionButtons &&
                emissionData.length > 1 &&
                !readOnlyMode && (
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      {(item.status === "pending_approval" ||
                        item.status === "submitted") && (
                        <div
                          className={classes.actionButtons}
                          onClick={() => handleIconClick(item.id)}
                        >
                          <CheckCircleOutlineOutlinedIcon
                            className={classes.icon}
                          />
                          <CeroButton
                            variant="contained"
                            buttonText="Approve"
                            className={classes.approveButton}
                          />
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      {item.is_added_by_sustainability_manager === false &&
                        item.status === "pending_approval" && (
                          <div
                            className={classes.actionButtons}
                            onClick={() => handleReviewClick(item.id)}
                          >
                            <ArrowCircleLeftOutlinedIcon
                              className={classes.icon}
                            />
                            <CeroButton
                              variant="contained"
                              buttonText="Review"
                              className={classes.approveButton}
                            />
                          </div>
                        )}
                    </Grid>
                  </Grid>
                )}
              {showVerifyButtons &&
                emissionData.length > 1 &&
                !readOnlyMode && (
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      {item.status === "approved" && (
                        <div
                          className={classes.actionButtons}
                          onClick={() => handleIconClick(item.id)}
                        >
                          <CheckCircleOutlineOutlinedIcon
                            className={classes.icon}
                          />
                          <CeroButton
                            variant="contained"
                            buttonText="Validate"
                            className={classes.approveButton}
                          />
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      {item.status === "approved" && (
                        <div
                          className={classes.actionButtons}
                          onClick={() => handleReviewClick(item.id)}
                        >
                          <ArrowCircleLeftOutlinedIcon
                            className={classes.icon}
                          />
                          <CeroButton
                            variant="contained"
                            buttonText="Review"
                            className={classes.approveButton}
                          />
                        </div>
                      )}
                    </Grid>
                  </Grid>
                )}
            </Box>
            <Typography variant="subtitle1" className={classes.heading}>
              Incident {index + 1}
            </Typography>
            <CeroItemPair
              title="Type of Incident:"
              value={item.type_of_incident ? item.type_of_incident : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.title,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Number of affected:"
              value={item.affected ? item.affected : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.title,
                value: classes.value,
              }}
            />
            {emissionData.blob_url && (
              <div
                style={{
                  transform: "scale(0.6)",
                  transformOrigin: "center",
                  position: "absolute",
                  top: 100,
                  right: 100,
                }}
                onClick={handlePdfOpen}
              >
                <Download />
              </div>
            )}
            <CeroItemPair
              title="Severity of Incident:"
              value={
                item.severity_of_incident ? item.severity_of_incident : "NA"
              }
              classes={{
                container: classes.infoContainer,
                title: classes.title,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Impact on Work:"
              value={item.impact_on_work ? item.impact_on_work : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.title,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Medical Treatment Provided:"
              value={
                item.medical_treatment_provided
                  ? item.medical_treatment_provided
                  : "NA"
              }
              classes={{
                container: classes.infoContainer,
                title: classes.title,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Details:"
              value={item.details ? item.details : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.title,
                value: classes.value,
              }}
            />
            <CeroItemPair
              title="Corrective Actions:"
              value={item.corrective_action ? item.corrective_action : "NA"}
              classes={{
                container: classes.infoContainer,
                title: classes.title,
                value: classes.value,
              }}
            />
          </Container>
        ))}
      <Modal open={isModalOpen} onClose={handlePdfClose}>
        <Box className={classes.modalContent}>
          <CloseIcon onClick={handlePdfClose} className={classes.closeButton} />
          {handlePdfPopup()}
        </Box>
      </Modal>
    </Container>
  );
};

export default EmployeeHealthApproval;
