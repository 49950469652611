import React, { useState } from "react";
import useStyles from "./styles";
import { Box, Select, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CeroDropdown from "../../../../components/CeroDropdown";
import RemoveIcon from "@mui/icons-material/Remove";

const SupplyChainTable = ({ supplyChainTable, setSupplyChainTable }) => {
  const classes = useStyles();
  const handleInputChange = (categoryIndex, field, value) => {
    const updatedData = [...supplyChainTable];
    updatedData[categoryIndex][field] = value;
    setSupplyChainTable(updatedData);
  };
  const [canAddMore, setCanAddmore] = useState(true);
  const [canRemove, setCanRemove] = useState(supplyChainTable.length > 1);

  const handleAddMore = () => {
    setSupplyChainTable((prev) => [
      ...prev, // Spread the existing array to retain its elements
      {
        supplierName: "",
        supplierType: "",
        activitiesType: "",
        businessType: "",
        paymentType: "",
        locationType: "",
      }, // Add the new object to the array
    ]);
    setCanRemove(true);
  };
  const handleRemove = () => {
    setSupplyChainTable((prev) => {
      // Use slice to create a new array excluding the last element
      setCanRemove(prev.length > 2);
      return prev.slice(0, -1);
    });
  };
  return (
    <div style={{ padding: "20px" }}>
      <div
        style={{
          borderRadius: "6px",
          overflow: "hidden",
          // border: "1px solid #ddd",
        }}
      >
        <table border="2" style={{ borderCollapse: "collapse", width: "100%" }}>
          <thead>
            <tr style={{ backgroundColor: "#d0c5ca", textAlign: "center" }}>
              <th style={{ padding: "8px" }}>Name of the Supplier</th>
              <th style={{ padding: "8px" }}>Type of the supplier</th>
              <th style={{ padding: "8px" }}>Type of activities</th>
              <th style={{ padding: "8px" }}>
                Nature of business relationship
              </th>
              <th style={{ padding: "8px" }}>
                Total monetary value of payments made (currency)
              </th>
              <th style={{ padding: "8px" }}>
                Geographic location (Region, Country)
              </th>
            </tr>
          </thead>
          <tbody>
            {supplyChainTable?.map((item, categoryIndex) => (
              <React.Fragment key={categoryIndex}>
                <tr key={`${categoryIndex}`}>
                  {/* Only render the category cell for the first row of each category */}
                  <td className={classes.marketDataRow}>
                    <textarea
                      value={item.supplierName}
                      placeholder="Enter Supplier Name"
                      onChange={(e) =>
                        handleInputChange(
                          categoryIndex,
                          "supplierName",
                          e.target.value
                        )
                      }
                      className={classes.marketDataItem}
                    />
                  </td>
                  <td className={classes.marketDataRow}>
                    <CeroDropdown
                      value={item.supplierType}
                      placeholder="Select Type of Supplier"
                      options={[
                        { key: "Broker", value: "Broker" },
                        { key: "Contractor", value: "Contractor" },
                        { key: "Wholesaler", value: "Wholesaler" },
                        { key: "Other", value: "Other" },
                      ]}
                      onChange={(e) =>
                        handleInputChange(
                          categoryIndex,
                          "supplierType",
                          e.target.value
                        )
                      }
                      selectedValue={item.supplierType}
                      className={classes.marketDataItem}
                    />
                  </td>
                  <td className={classes.marketDataRow}>
                    <CeroDropdown
                      value={item.activitiesType}
                      placeholder="Select Type of Activities"
                      options={[
                        { key: "Manufacturing", value: "Manufacturing" },
                        {
                          key: "Raw Material Extraction and Processing",
                          value: "Raw Material Extraction and Processing",
                        },
                        {
                          key: "consulting services",
                          value: "consulting services",
                        },
                        {
                          key: "Maintenance services",
                          value: "Maintenance services",
                        },
                        { key: "Packaging", value: "Packaging" },
                        { key: "Logistics", value: "Logistics" },
                        { key: "Other", value: "Other" },
                      ]}
                      onChange={(e) =>
                        handleInputChange(
                          categoryIndex,
                          "activitiesType",
                          e.target.value
                        )
                      }
                      selectedValue={item.activitiesType}
                      className={classes.marketDataItem}
                    />
                  </td>
                  <td className={classes.marketDataRow}>
                    <CeroDropdown
                      value={item.businessType}
                      placeholder="Select Nature of business relationship"
                      selectedValue={item.businessType}
                      options={[
                        { key: "Long-term ", value: "Long-term " },
                        { key: "Short-term", value: "Short-term" },
                        { key: "Contractual", value: "Contractual" },
                        { key: "Non-contractual", value: "Non-contractual" },
                        { key: "Project-based", value: "Project-based" },
                        { key: "Event-based", value: "Event-based" },
                      ]}
                      onChange={(e) =>
                        handleInputChange(
                          categoryIndex,
                          "businessType",
                          e.target.value
                        )
                      }
                      className={classes.marketDataItem}
                    />
                  </td>
                  <td className={classes.marketDataRow}>
                    <textarea
                      value={item.paymentType}
                      placeholder="Enter Payment made"
                      onChange={(e) =>
                        handleInputChange(
                          categoryIndex,
                          "paymentType",
                          e.target.value
                        )
                      }
                      className={classes.marketDataItem}
                    />
                  </td>
                  <td className={classes.marketDataRow}>
                    <textarea
                      value={item.locationType}
                      placeholder="Enter Geographic location"
                      onChange={(e) =>
                        handleInputChange(
                          categoryIndex,
                          "locationType",
                          e.target.value
                        )
                      }
                      className={classes.marketDataItem}
                    />
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
        <div className={classes.addMoreDiv}>
          <Box
            display="flex"
            paddingY={2}
            paddingLeft={2}
            gap={1}
            onClick={canRemove ? handleRemove : null}
            style={{
              cursor: canRemove ? "pointer" : "not-allowed",
              color: canRemove ? "gray" : "gray",
              border: "1px solid #ddd",
              padding: "2px 7px",
              margin: "5px 5px",
              borderRadius: "5px",
            }}
          >
            <RemoveIcon />
            <Typography>Remove</Typography>
          </Box>
          <Box
            display="flex"
            paddingY={2}
            paddingLeft={2}
            gap={1}
            onClick={canAddMore ? handleAddMore : null}
            style={{
              cursor: canAddMore ? "pointer" : "not-allowed",
              color: canAddMore ? "gray" : "gray",
              border: "1px solid #ddd",
              padding: "2px 7px",
              margin: "5px 5px",
              borderRadius: "5px",
            }}
          >
            <AddIcon />
            <Typography>Add More</Typography>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default SupplyChainTable;
