import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Paper, Typography } from "@mui/material"
import { Chart as ChartJS, Tooltip, Legend, ArcElement, registerables as registerablesJS } from 'chart.js';
import { getEmissionTypes, getRecordStatus } from '../../../redux/actions';
import useStyles from './styles'
import EmissionTypeChart from './EmissionTypeChart';
import { getCookie } from '../../../services/cookie';

ChartJS.register(...registerablesJS)
ChartJS.register(ArcElement, Tooltip, Legend);

const OffsetProgramType = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch();
    const role = getCookie('role');
    const companyId = getCookie("companyId");
    const emissionData = useSelector(state => state.dashboard.getDashboardOffsetStatistics.data);
    const emissionDataStatus = useSelector(state => state.dashboard.getDashboardOffsetStatistics.status);

    useEffect(() => {
        if (
          role === "sustainability_manager" || role === "proxy_sustainability_manager" ||
          role === "read_only_user"
        ) {
          !props.refreshContent && dispatch(getEmissionTypes(props.filter,role,companyId));
        } else {
          !props.refreshContent && dispatch(getRecordStatus(props.filter));
        }
    }, [dispatch, props.filter]);

    

    return (
      <Paper className={classes.container}>
          <>
            <Typography className={classes.graphTitle}>
              Offset Program Type
            </Typography>
            <div className={classes.emissionChart}>
              {Object.keys(emissionData)?.length > 0 ? (
                <EmissionTypeChart data={emissionData} />
              ) :emissionDataStatus === "running"? <div style={{ minHeight: 250 }}>Loading...</div>: (
                <div style={{ minHeight: 250 }}>No Data Available</div>
              )}
            </div>
          </>
      </Paper>
    );
}

export default OffsetProgramType
