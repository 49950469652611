import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(4),
        width: '100%',
        height: '95%',
        borderRadius: 8,
        cursor: "pointer",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), -4px 0px 4px rgba(0, 0, 0, 0.05)",
    },
    graphTitle: {
        fontSize: 18,
        fontWeight: 400,
        margin: theme.spacing(2, 0),
        paddingBottom: theme.spacing(2)
    },
    chart:{
        height: "300px",
        width: "100%"
    },
    emissionChart:{
        height: "300px"
    }
}));

export default useStyles;
