// import React, { useEffect, useRef } from "react";
// import Chart from "chart.js/auto";
// import ChartDataLabels from "chartjs-plugin-datalabels";

// const EmissionTypeChart = ({ data }) => {

//   const categories = Object.keys(data).filter((key) => key !== "total");
//   const values = categories.map((key) => data[key]);

//   const allCategories = categories;
//   const allValues = values;

//   const capitalizeFirstLetter = (string) =>
//     string.charAt(0).toUpperCase() + string.slice(1);

//   const chartRef = useRef(null);
//   const chartInstance = useRef(null);

//   useEffect(() => {
//     if (chartInstance.current) {
//       chartInstance.current.destroy();
//     }

//     const myChartRef = chartRef.current.getContext("2d");

//     const colorMap = {
//       "Solar Energy Program": { legend: "Solar Energy", color: "#6fbfba" },
//       Reforestation: { legend: "Reforestation", color: "#4CAF50" },
//       "Wind Energy Program": { legend: "Wind Energy", color: "#846bb5" },
//       "Hydro Energy Program": { legend: "Hydro Energy", color: "#3F51B5" },
//       "Wave Energy Converter": { legend: "Wave Energy", color: "#FF9800" },
//       Afforestation: { legend: "Afforestation", color: "#8BC34A" },
//     };

//     const backgroundColor = allCategories.map(
//       (category) => colorMap[category]?.color || "#D3D3D3"
//     );

//     chartInstance.current = new Chart(myChartRef, {
//       type: "pie",
//       data: {
//         labels: allCategories.map((type) =>
//           capitalizeFirstLetter(type.replace(/_/g, " "))
//         ),
//         datasets: [
//           {
//             data: allValues,
//             backgroundColor: backgroundColor,
//           },
//         ],
//       },
//       options: {
//         plugins: {
//           legend: {
//             display: true,
//             position: "bottom",
//             labels: {
//               usePointStyle: true,
//               color: "black",
//               padding: 20,
//               generateLabels: (chart) => {
//                 return allCategories.map((label, i) => ({
//                   text: capitalizeFirstLetter(label.replace(/_/g, " ")),
//                   fillStyle: chart.data.datasets[0].backgroundColor[i],
//                   hidden: false,
//                   lineWidth: 0,
//                 }));
//               },
//             },
//           },
//           tooltip: {
//             callbacks: {
//               label: (context) => `${context.raw.toFixed(2)}`,
//             },
//           },
//           datalabels: {
//             display: (context) => context.dataset.data[context.dataIndex] > 0,
//             color: "white",
//             font: {
//               weight: "bold",
//               size: 12,
//             },
//             formatter: (value) => value.toFixed(0),
//           },
//         },
//         maintainAspectRatio: false,
//         responsive: true,
//         aspectRatio: 2,
//         layout: {
//           padding: {
//             bottom: 40,
//           },
//         },
//       },
//       plugins: [ChartDataLabels],
//     });

//     return () => {
//       if (chartInstance.current) {
//         chartInstance.current.destroy();
//       }
//     };
//   }, [data]);

//   return <canvas ref={chartRef} />;
// };

// export default EmissionTypeChart;

import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const EmissionTypeChart = ({ data }) => {
  const categories = ['Excellent', 'Very Good', 'Good', 'Average', 'Poor'];
  const values = categories.map((key) => data[key] || 0); // Ensure values are at least 0

  const capitalizeFirstLetter = (string) =>
    string.charAt(0).toUpperCase() + string.slice(1);

  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    const myChartRef = chartRef.current.getContext('2d');

    const colorMap = {
      Excellent: '#fec757',
      'Very Good': '#6fbfba',
      Good: '#846bb5',
      Average: '#FF9800',
      Poor: '#a19f9f',
    };

    const backgroundColor = categories.map(
      (category) => colorMap[category] || '#D3D3D3'
    );

    chartInstance.current = new Chart(myChartRef, {
      type: 'pie',
      data: {
        labels: categories.map((type) => capitalizeFirstLetter(type)),
        datasets: [
          {
            data: values,
            backgroundColor: backgroundColor,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              usePointStyle: true,
              color: 'black',
              padding: 20,
            },
          },
          tooltip: {
            callbacks: {
              label: (context) => `${context.raw.toFixed(2)}`,
            },
          },
          datalabels: {
            display: (context) => context.dataset.data[context.dataIndex] > 0,
            color: 'white',
            font: {
              weight: 'bold',
              size: 12,
            },
            formatter: (value) => value.toFixed(0),
          },
        },
        maintainAspectRatio: false,
        responsive: true,
        aspectRatio: 2,
        layout: {
          padding: {
            bottom: 40,
          },
        },
      },
      plugins: [ChartDataLabels],
    });

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [data]);

  return <canvas ref={chartRef} />;
};

export default EmissionTypeChart;
