import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import useStyles from "./styles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CeroDropdown from "../../../components/CeroDropdown";
import { sampleYear } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { dashboardSustainabilityDataDetails } from "../../../redux/actions";
import CeroTable from "../../../components/CeroTable";

export const columns = [
  {
    columnKey: "name",
    columnId: "name",
    columnHeader: "Name",
    width: "20%",
  },
  {
    columnKey: "role",
    columnId: "role",
    columnHeader: "Role",
    width: "20%",
  },
  {
    columnKey: "year",
    columnId: "year",
    columnHeader: "Year",
    width: "20%",
  },
  {
    columnKey: "score",
    columnId: "score",
    columnHeader: "Score",
    width: "20%",
  },
];

const SupplierIndustriesDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [year, setYear] = useState(null);
  const filter = location.state.filter;

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  const sustainabilityData = useSelector(
    (state) => state.dashboard.dashboardSustainabilityDataDetails.data
  );

  useEffect(() => {
    dispatch(dashboardSustainabilityDataDetails(filter));
  }, [filter]);

  const getSustainabilityData = () => {
    const filteredData = year
      ? sustainabilityData.filter((item) => item.survey_year === String(year))
      : sustainabilityData;

    return filteredData.map((item) => ({
      ...item,
      name: item.name,
      role: item.role,
      year: item.survey_year,
      score: item.total_score,
      // assessment_rating: item.assessment_rating,
    }));
  };

  return (
    <DashboardLayout>
      <Grid>
        <Container>
          <Box className={classes.header}>
            <Box className={classes.buttonContainer}>
              <div
                className={classes.backContainer}
                onClick={() => navigate(-1)}
              >
                <ArrowBackIosIcon />
                Back
              </div>
            </Box>
            <Grid item xs={2} className={classes.filterContainer}>
              <CeroDropdown
                id="year"
                label="Year"
                fullWidth
                options={sampleYear(true)}
                onChange={handleYearChange}
                selectedValue={year}
              />
            </Grid>
          </Box>
          <Paper className={classes.paper}>
            <CeroTable
              columns={columns}
              data={getSustainabilityData()}
              hasMore={false}
              loading={false}
              // onSelectRow={onSelectAuditSummaryData}
            />
          </Paper>
        </Container>
      </Grid>
    </DashboardLayout>
  );
};

export default SupplierIndustriesDetails;
