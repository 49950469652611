import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";

const StackedColumnChart = ({ apiResponse }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (!apiResponse || Object.keys(apiResponse).length === 0) return;

    const categories = Object.keys(apiResponse);
    const values = Object.values(apiResponse);

    const categoryInfo = {
      "Casinos & Gaming": { legend: "Casinos & Gaming", color: "#846bb5" },
      "Food Retailers & Distributors": {
        legend: "Food Retailers",
        color: "#846bb5",
      },
      "Health Care Delivery": { legend: "Health Care", color: "#846bb5" },
    };

    const datasets = [
      {
        label: "Average Score",
        data: values,
        backgroundColor: categories.map(
          (category) => categoryInfo[category]?.color || "#846bb5"
        ),
        barThickness: 30,
      },
    ];

    function getRandomColor() {
      const letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    }

    const data = {
      labels: categories,
      datasets,
    };

    const options = {
      maintainAspectRatio: false,
      scales: {
        x: {
          title: {
            display: true,
            text: "Industry",
          },
          grid: { display: false },
          ticks: { color: "black" },
        },
        y: {
          title: {
            display: true,
            text: "Average Score",
            color: "#4F4F4F",
            font: { weight: "500" },
          },
          grid: { display: false },
          ticks: {
            color: "black",
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: (context) => context.raw,
          },
        },
        datalabels: {
          color: "#4F4F4F",
          formatter: (value) => (value !== 0 ? value.toFixed(0) : ""),
          anchor: "end",
          align: "top",
          rotation: "270",
          font: { size: 14 },
        },
      },
      layout: {
        padding: { bottom: 10, top: 50 },
      },
    };

    const ctx = chartRef.current.getContext("2d");

    if (chartRef.current.chart) {
      chartRef.current.chart.destroy();
    }

    chartRef.current.chart = new Chart(ctx, {
      type: "bar",
      data: data,
      options: options,
      plugins: [ChartDataLabels],
    });
  }, [apiResponse]);

  // return <canvas ref={chartRef} height={286} />;
  return (
    <div style={{ position: "relative" }}>
      <div>
        <canvas ref={chartRef} height={286} />
      </div>
      <div
        style={{
          position: "absolute",
          bottom: -5,
          right: 10,
          textAlign: "right",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", marginBottom: 4 }}>
          <div
            style={{
              width: 30,
              height: 15,
              backgroundColor: "#846bb5",
              marginRight: 5,
            }}
          ></div>
          <span style={{ fontSize: 11 }}>Average Score</span>
        </div>
      </div>
    </div>
  );
};

export default StackedColumnChart;
