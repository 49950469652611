import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    height: "calc(100% - 20px)",
    width: "100%",
  },
  header: {
    display: "flex",
    // alignItems: 'end',
    flexDirection: "column",
    // justifyContent: 'space-between'
    paddingRight: "10px",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: 15,
    width: "100%",
  },
  backContainer: {
    color: theme.palette.Primary.background,
    textTransform: "capitalize",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  filterContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: 600,
    marginRight: 10,
    "& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl": {
      backgroundColor: theme.palette.background.white,
    },
  },
  tableContainer: {
    maxHeight: "calc(100vh - 120px)",
    marginTop: theme.spacing(5),
  },
  paper: {
    marginTop: 20,
  },
  excellent: {
    color: "#024FE4",
  },
  good: {
    color: "#48742C",
  },
  moderate: {
    color: "#F5C142",
  },
  low: {
    color: "#7B7B7B",
  },
}));

export default useStyles;
